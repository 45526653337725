import React, { useState, useEffect } from 'react';

interface ValidatedFieldProps {
    label: string;
    id: string;
    name: string;
    type: string;
    validate?: {
      required?: { value: boolean; message: string };
      pattern?: { value: RegExp; message: string };
      emailPattern?: { value: string; message: string };
      max?: { value: number; message: string };
      min?: { value: number; message: string };
      maxLength?: { value: number; message: string };
      minLength?: { value: number; message: string };
    };
    value: string;
    onChange?: (value: string) => void;
    onError?: (name: string, error: boolean) => void;
    labelWidth?: number;
    isReadOnly?: boolean;
  }
  

const FieldFormWithValidation: React.FC<ValidatedFieldProps> = ({
  label,
  id,
  name,
  type,
  validate,
  value,
  onChange,
  onError,
  labelWidth,
  isReadOnly
}) => {
  const [error, setError] = useState('');
  const [labelCols, setLabelCols] = useState(() => {
    return (labelWidth !== undefined && labelWidth >= 1 && labelWidth <= 8) ? labelWidth : 3;
  });


  useEffect(() => {
    if (onError !== undefined) {
      const hasError = validateInput(value);
      onError(name, hasError);
    }
  }, [value]);

  const validateInput = (inputValue: string): boolean => {
    let errorMessage = '';
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (validate.required?.value && !inputValue) {
      setError(validate.required.message);
      errorMessage = validate.required.message;
    } else if (validate.pattern?.value && inputValue?.length > 0 && !validate.pattern.value.test(inputValue)) {
      setError(validate.pattern.message);
      errorMessage = validate.pattern.message;
    } else if (validate.emailPattern?.value && !emailRegex.test(inputValue)) {
      setError(validate.emailPattern.message);
      errorMessage = validate.emailPattern.message;
    } else if (validate.maxLength?.value !== undefined && inputValue.length > validate.maxLength.value) {
      setError(validate.maxLength.message);
      errorMessage = validate.maxLength.message;
    } else if (validate.minLength?.value !== undefined && inputValue.length < validate.minLength.value) {
      setError(validate.minLength.message);
      errorMessage = validate.minLength.message;
    }

    if (type !== 'number') {
      const numericValue = parseFloat(inputValue);
      if (validate.max?.value !== undefined && numericValue > validate.max.value) {
        setError(validate.max.message);
        errorMessage = validate.max.message;
      }

      if (validate.min?.value !== undefined && numericValue < validate.min.value) {
        setError(validate.min.message);
        errorMessage = validate.min.message;
      }
    }
    
    setError(errorMessage);
    return !!errorMessage;
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange !== undefined) {
      onChange(e.target.value);
    }    
  };

  return (
    <div className="form-group row align-items-center mb-3">
      <label htmlFor={id} className={`col-sm-${labelCols} col-form-label`}>{label}</label>
      <div className={`col-sm-${12-labelCols} pad-left-0`}>
        <input
          name={name}
          id={id}
          type={type}
          className={`form-control ${error ? 'is-invalid' : ''} ${isReadOnly ? 'read-only' : ''}`}
          value={value}
          onChange={handleChange}
          aria-invalid={!!error}
        />
      </div>
      {error && (
        <div className="invalid-feedback">{error}</div>
      )}
    </div>
  );
};

export default FieldFormWithValidation;