import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import { ListboxItem } from '../util/ListboxItem';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from 'react-jhipster';

interface DualListboxProps {
  items: ListboxItem[];               // All possible items
  selectedItems: ListboxItem[];       // Selected items
  label: string;
  name: string;
  id: string;
  onSelectionChange?: (selectedItems: ListboxItem[]) => void;  // Callback to expose selected items
}

const DualListbox: React.FC<DualListboxProps> = ({ items, selectedItems: initialSelectedItems, label, name, id, onSelectionChange }) => {
  const [availableItems, setAvailableItems] = useState<ListboxItem[]>(items.filter(item => !initialSelectedItems.find(si => si.id === item.id)));
  const [selectedItems, setSelectedItems] = useState<ListboxItem[]>(items.filter(item => initialSelectedItems.find(si => si.id === item.id)));

  useEffect(() => {
    const aItems = items.filter(item => !initialSelectedItems.find(si => si.id === item.id));
    const sItems = items.filter(item => initialSelectedItems.find(si => si.id === item.id));
    setSelectedItems(sItems);
    setAvailableItems(aItems);
  }, [initialSelectedItems]);

  // Updates the status and notifies the consumer of the component when the selection changes
  const updateSelections = (newSelectedItems: ListboxItem[], newAvailableItems: ListboxItem[]) => {
    setSelectedItems(newSelectedItems);
    setAvailableItems(newAvailableItems);
    if (onSelectionChange) {
      onSelectionChange(newSelectedItems);
    }
  };


  const handleRemoveItem = (item: ListboxItem) => {
    const newSelectedItems = selectedItems.filter(i => i !== item);
    const newAvailableItems = [...availableItems, item];
    updateSelections(newSelectedItems, newAvailableItems);
  };

  const handleSelectItem = (item: ListboxItem) => {
    const newSelectedItems = [...selectedItems, item];
    const newAvailableItems = availableItems.filter(i => i !== item);
    updateSelections(newSelectedItems, newAvailableItems);
  };

  return (
    <Card className='b-radius-1 mt-15 mb-2rem'>
      <CardHeader>
        <label className="col-3 col-form-label f-weight-500">
          {label}
        </label>
      </CardHeader>
      <CardBody style={{height: 205}}>
        <div className="justify-content-center align-items-center">
          <Row>
            <Col className='dual-listbox-column'>
              <label> <Translate contentKey='global.dualListBox.available' /> </label>
              <div className="options-container options-available">
                {availableItems.map(item => (
                  <div key={item.id} className='option-item option-available-item'>
                    <div className="item-text">{item.name}</div>
                    <div className="action-area" onClick={() => handleSelectItem(item)}>
                      <FontAwesomeIcon icon={faPlus} className="action-icon" />
                    </div>
                  </div> 
                ))}
              </div>
            </Col>

            <Col className='dual-listbox-column'>
              <label><Translate contentKey='global.dualListBox.selected' /></label>
              <div className="options-container options-selected">
                {selectedItems.map(item => (
                  <div key={item.id} className='option-item option-selected-item'>
                    <div className="item-text">{item.name}</div>
                    <div className="action-area" onClick={() => handleRemoveItem(item)}>
                      <FontAwesomeIcon icon={faTimes} className="action-icon" />
                    </div>
                  </div>
                ))}
              </div>
            </Col>
          </Row>

        </div>
      </CardBody>
    </Card>

  );
}

export default DualListbox;
