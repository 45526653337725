import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { ASC } from 'app/shared/util/pagination.constants';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import {ISurveyToDo, defaultValue} from 'app/shared/model/survey-to-do.model';

import { IRootState } from 'app/config/store';

interface ExtendedEntityState<T> extends EntityState<T> {
  institutionId?: string | number;
  toDoStates?: ReadonlyArray<any>;
  newLocatorId: number | null;
  openEntities?: ReadonlyArray<ISurveyToDo>;
}

const initialState: ExtendedEntityState<ISurveyToDo> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  institutionId: null,
  toDoStates: [],
  newLocatorId: null,
  openEntities: []
};

const apiUrl = 'api/survey-to-dos';

export const setInstitutionId = createAsyncThunk(
  'surveyToDo/set_institution_id',
  (id: string | number) => {
    return id;
  },
  { serializeError: serializeAxiosError },
);


type ExtendedQueryParams = IQueryParams & {
  institutionId?: string | number | null;
};

export const getEntitiesByInstitution = createAsyncThunk(
  'surveyToDo/fetch_entity_list',
  async ({ institutionId }: ExtendedQueryParams, thunkAPI) => {
    if (institutionId === undefined || institutionId === null) {
      const state = thunkAPI.getState() as IRootState;
      institutionId = state.surveyToDo.institutionId;
    }
    const requestUrl = `${apiUrl}/all-by-institution/${institutionId}`;
    return axios.get<ISurveyToDo[]>(requestUrl);
  }
);

export const getOpenByInstitutionAndType = createAsyncThunk(
  'surveyToDo/fetch_open_entities_by_institution_and_type',
  async ({ institutionId, surveySection, type: surveyType } : { institutionId: string | number, surveySection: number, type:string }, thunkAPI) => {

    if (institutionId === undefined || institutionId === null) {
      const state = thunkAPI.getState() as IRootState;
      institutionId = state.surveyToDo.institutionId;
    }
    const requestUrl = `${apiUrl}/open-by-institution/${institutionId}/${surveyType}?surveySection=${surveySection}`;
    return axios.get<ISurveyToDo[]>(requestUrl);
  }
);

export const getEntitiesByInstitutionWithFilters = createAsyncThunk(
  'surveyToDo/fetch_entity_list_by_institution_with_filters',
  async ({ institutionId, status, survey, year, locatorId } :
    { institutionId: string | number; status?: string; survey?: string; year?: string; locatorId?: string }) => {
    let requestUrl = `${apiUrl}/all-by-institution/${institutionId}`;

    const queryParams: string[] = [];
    if (status) queryParams.push(`status=${status}`);
    if (survey) queryParams.push(`surveyId=${survey}`);
    if (year) queryParams.push(`year=${year}`);
    if (locatorId) queryParams.push(`locatorId=${locatorId}`);

    if (queryParams.length) {
      requestUrl += `?${queryParams.join('&')}`;
    }

    return axios.get<ISurveyToDo[]>(requestUrl);
  }
);

export const getAllStates = createAsyncThunk(
  'surveyToDo/fetch_all_states',
  async () => {
    const requestUrl = `${apiUrl}/all-states`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getNewLocatorId = createAsyncThunk(
  'surveyToDo/generate_new_locator_id',
  async () => {
    const requestUrl = `${apiUrl}/generate-new-locator-id`;
    return axios.get<number>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getEntity = createAsyncThunk(
  'surveyToDo/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ISurveyToDo>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'surveyToDo/create_entity',
  async (entity: ISurveyToDo, thunkAPI) => {

    const result = await axios.post<ISurveyToDo>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesByInstitution({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'surveyToDo/update_entity',
  async (entity: ISurveyToDo, thunkAPI) => {
    const result = await axios.put<ISurveyToDo>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesByInstitution({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'surveyToDo/partial_update_entity',
  async (entity: ISurveyToDo, thunkAPI) => {
    const result = await axios.patch<ISurveyToDo>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesByInstitution({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'surveyToDo/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ISurveyToDo>(requestUrl);
    thunkAPI.dispatch(getEntitiesByInstitution({}));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const SurveyToDoSlice = createEntitySlice({
  name: 'surveyToDo',
  initialState,
  extraReducers(builder) {
    builder
    .addCase(setInstitutionId.fulfilled, (state, action) => {
      state.institutionId = action.payload;
      state.loading = false;
    })
    .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
    })
    .addCase(getAllStates.fulfilled, (state, action) => {
        state.loading = false;
        state.toDoStates = action.payload.data;
    })
    .addCase(getNewLocatorId.pending, (state, action) => {
      state.loading = true;
    })
    .addCase(getNewLocatorId.fulfilled, (state, action) => {
        state.loading = false;
        state.newLocatorId = action.payload.data;
    })
    .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
    })
    .addMatcher(isFulfilled(getEntitiesByInstitution, getEntitiesByInstitutionWithFilters), (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        loading: false,
        entities: data
      };
    })
    .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
      state.updating = false;
      state.loading = false;
      state.updateSuccess = true;
      state.entity = action.payload.data;
    })
    .addMatcher(isPending(getEntitiesByInstitution, getEntitiesByInstitutionWithFilters, getEntity, getOpenByInstitutionAndType), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.loading = true;
    })
    .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
      state.errorMessage = null;
      state.updateSuccess = false;
      state.updating = true;
    })
    .addMatcher(isFulfilled(getOpenByInstitutionAndType), (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        loading: false,
        openEntities: data
      };
    });
  },
});

export const { reset } = SurveyToDoSlice.actions;

// Reducer
export default SurveyToDoSlice.reducer;
