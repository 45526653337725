import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './institution.reducer';

export const InstitutionDetail = ({isIrexAdmin = false}) => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const institutionEntity = useAppSelector(state => state.institution.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="institutionDetailsHeading">
          <Translate contentKey="irexForsahApp.institution.detail.title">Institution</Translate>
        </h2>
        <dl className="jh-entity-details">
          {/* <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.id}</dd> */}

          <dt>
            <span id="logo">
              <Translate contentKey="irexForsahApp.institution.logo">logo</Translate>
            </span>
          </dt>
          <dd>
            <div className='image-container' style={{maxWidth: 200}}>
              {institutionEntity.logo ? (
                    <img src={`data:image/png;base64,${institutionEntity.logo}`} alt={translate('irexForsahApp.institution.logo')}/>
                ) : (
                    <img src='/content/images/NoImage.webp' alt={translate('irexForsahApp.institution.noImage')}/>
                )}                       
            </div>
          </dd>

          <dt>
            <span id="name">
              <Translate contentKey="irexForsahApp.institution.name">Name</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.name}</dd>

          <dt>
            <span id="acronym">
              <Translate contentKey="irexForsahApp.institution.acronym">Name</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.acronym}</dd>

          <dt>
            <span id="address">
              <Translate contentKey="irexForsahApp.institution.address">Address</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.address}</dd>

          <dt>
            <span id="region">
              <Translate contentKey="irexForsahApp.institution.region">Region</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.region?.name}</dd>

          <dt>
            <span id="governorate">
              <Translate contentKey="irexForsahApp.institution.governorate">Governorate</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.governorate?.name}</dd>

          <dt>
            <span id="otherLocations">
              <Translate contentKey="irexForsahApp.institution.otherLocations">otherLocations</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.otherLocations}</dd>

          <dt>
            <span id="focalPointName">
              <Translate contentKey="irexForsahApp.institution.focalPointName">focalPointName</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.focalPointName}</dd>

          <dt>
            <span id="email">
              <Translate contentKey="irexForsahApp.institution.email">email</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.email}</dd>

          <dt>
            <span id="mobilePhone">
              <Translate contentKey="irexForsahApp.institution.mobilePhone">mobilePhone</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.mobilePhone}</dd>

          <dt>
            <span id="telephone">
              <Translate contentKey="irexForsahApp.institution.telephone">telephone</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.telephone}</dd>

          <dt>
            <span id="yearEstablishment">
              <Translate contentKey="irexForsahApp.institution.yearOfEstablishment">yearEstablishment</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.yearEstablishment}</dd>

          <dt>
            <span id="totalSurfaceArea">
              <Translate contentKey="irexForsahApp.institution.totalSurfaceArea">totalSurfaceArea</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.totalSurfaceArea}</dd>

          <dt>
            <Translate contentKey="irexForsahApp.institution.availableFacilities">User</Translate>
          </dt>
          <dd>
            {institutionEntity.availableFacilities
              ? institutionEntity.availableFacilities.map((val, i) => (
                <span key={val.id}>
                    {val.name}
                  {institutionEntity.availableFacilities && i === institutionEntity.availableFacilities.length - 1 ? '' : ', '}
                  </span>
              ))
              : null}
          </dd>

          <dt>
            <span id="accreditationBody">
              <Translate contentKey="irexForsahApp.institution.accreditationBody">accreditationBody</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.accreditationBody?.name}</dd>

          <dt>
            <span id="programLevel">
              <Translate contentKey="irexForsahApp.institution.programLevel">programLevel</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.programLevel?.name}</dd>

          <dt>
            <span id="numberPrograms">
              <Translate contentKey="irexForsahApp.institution.numberPrograms">numberPrograms</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.numberPrograms}</dd>

          <dt>
            <Translate
              contentKey="irexForsahApp.institution.trainingPrograms">trainingProgramEconomicSectors</Translate>
          </dt>
          <dd>
            {institutionEntity.trainingProgramEconomicSectors
              ? institutionEntity.trainingProgramEconomicSectors.map((val, i) => (
                <span key={val.id}>
                    {val.name}
                  {institutionEntity.trainingProgramEconomicSectors && i === institutionEntity.trainingProgramEconomicSectors.length - 1 ? '' : ', '}
                  </span>
              ))
              : null}
          </dd>


          <dt>
            <span id="numberFullTimeStudents">
              <Translate
                contentKey="irexForsahApp.institution.numberFullTimeStudents">numberFullTimeStudents</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.numberFullTimeStudents}</dd>

          <dt>
            <span id="numberPartTimeStudents">
              <Translate
                contentKey="irexForsahApp.institution.numberPartTimeStudents">numberPartTimeStudents</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.numberPartTimeStudents}</dd>

          <dt>
            <span id="ageGroups">
              <Translate contentKey="irexForsahApp.institution.ageGroupOfStudents">ageGroups</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.ageGroups}</dd>

          <dt>
            <span id="comment">
              <Translate contentKey="irexForsahApp.institution.comment">Comment</Translate>
            </span>
          </dt>
          <dd>{institutionEntity.comment}</dd>

          {
            isIrexAdmin &&
            <>
              <dt>
                <Translate contentKey="irexForsahApp.institution.user">User</Translate>
              </dt>
              <dd>
                {institutionEntity.users
                  ? institutionEntity.users.map((val, i) => (
                    <span key={val.id}>
                        <a>{val.firstName} {val.lastName}</a>
                      {institutionEntity.users && i === institutionEntity.users.length - 1 ? '' : ', '}
                      </span>
                  ))
                  : null}
              </dd>
            </>
          }

          <dt>

          </dt>
        </dl>
        <Button tag={Link} to="/usersHome/institution" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left"/>{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/institution/${institutionEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
        &nbsp;
        <Button onClick={() => (window.location.href = `usersHome/institution/${institutionEntity.id}/survey-to-dos`)}
          color="secondary" data-cy="entitySurveyToDosButton">
          <FontAwesomeIcon icon={faCheckSquare} /> {' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="irexForsahApp.institution.buttonSurveyToDo">Survey ToDos</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default InstitutionDetail;
