import React, { useState, useEffect } from 'react';
import { Button, Col, Row } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm, isEmail } from 'react-jhipster';
import { toast } from 'react-toastify';

import { locales, languages } from 'app/config/translation';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';
import { saveAccountSettings, reset as settingsReset } from './settings.reducer';

import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { savePassword, reset as passwordReset } from '../password/password.reducer';

export const SettingsPage = () => {

  const dispatch = useAppDispatch();
  const account = useAppSelector(state => state.authentication.account);
  const successMessage = useAppSelector(state => state.settings.successMessage);
  const errorMessage = useAppSelector(state => state.settings.errorMessage);

  const [langKey, setLangKey] = useState(account.langKey);
  const [firstName, setFirstName] = useState(account.firstName);
  const [lastName, setLastName] = useState(account.lastName);
  const [email, setEmail] = useState(account.email);

  const passErrorMessage = useAppSelector(state => state.password.errorMessage);
  const passSuccessMessage = useAppSelector(state => state.password.successMessage);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');




  useEffect(() => {
    dispatch(settingsReset());
    dispatch(getSession());
    return () => {
      dispatch(settingsReset());
    };
  }, []);

  useEffect(() => {
    if (successMessage) {
      toast.success(translate(successMessage));
    } else if (errorMessage) {
      toast.error(translate(errorMessage));
    }
    dispatch(passwordReset());
  }, [successMessage, errorMessage]);

  const handleValidSubmit = values => {
    dispatch(
      saveAccountSettings({
        ...account,
        langKey,
        firstName,
        lastName,
        email
      }),
    );
  };

  const handleValidPasswordSubmit = () => {
    if (currentPassword === '' || newPassword === '') {
      toast.error(translate('global.messages.error.empty'));
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error(translate('global.messages.error.dontmatch'));
      return;
    }
    dispatch(savePassword({ currentPassword, newPassword }));
  };

  useEffect(() => {
    if (passSuccessMessage) {
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      toast.success(translate(passSuccessMessage));
    } else if (passErrorMessage) {
      toast.error(translate(passErrorMessage));
    }
    dispatch(passwordReset());
  }, [passSuccessMessage, passErrorMessage]);

  return (
    <div>
      <Row className="justify-content-center admin-wrapper">
        <Col md="10">
          <h1 className="bordered" id="settings-title">
            <Translate contentKey="settings.title" interpolate={{ username: account.login }}>
              Available settings for {account.login}
            </Translate>
          </h1>
          <div className="spacer20"/>

          <ValidatedForm id="settings-form" onSubmit={handleValidSubmit} defaultValues={account}>
            <h3 className="arrow"><Translate contentKey="settings.form.languageSelect">
              Select your default language</Translate>
            </h3>
            <div className="spacer10"/>
            <Row>
              <Col md="3">
                <ValidatedField type="select" id="langKey" className="lang-select" name="langKey" label={translate('settings.form.language')} data-cy="langKey"
                value={langKey} onChange={(event) => setLangKey(event.target.value)}>
                  {locales.map(locale => (
                    <option value={locale} key={locale}>
                      {languages[locale].name}
                    </option>
                  ))}
                </ValidatedField>
              </Col>
            </Row>

            <div className="divider with-space"/>

            <h3 className="arrow">
              <Translate contentKey="settings.form.userDetails" />
            </h3>
            <Row>
              <Col md="3">
                <ValidatedField
                  name="firstName"
                  label={translate('settings.form.firstname')}
                  id="firstName"
                  placeholder={translate('settings.form.firstname.placeholder')}
                  validate={{
                    required: { value: true, message: translate('settings.messages.validate.firstname.required') },
                    minLength: { value: 1, message: translate('settings.messages.validate.firstname.minlength') },
                    maxLength: { value: 50, message: translate('settings.messages.validate.firstname.maxlength') },
                  }}
                  data-cy="firstname"
                  value={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                />
              </Col>

              <Col md="3">
                <ValidatedField
                  name="lastName"
                  label={translate('settings.form.lastname')}
                  id="lastName"
                  placeholder={translate('settings.form.lastname.placeholder')}
                  validate={{
                    required: { value: true, message: translate('settings.messages.validate.lastname.required') },
                    minLength: { value: 1, message: translate('settings.messages.validate.lastname.minlength') },
                    maxLength: { value: 50, message: translate('settings.messages.validate.lastname.maxlength') },
                  }}
                  data-cy="lastname"
                  value={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                />
              </Col>

              <Col md="3">
                <ValidatedField
                  name="email"
                  label={translate('global.form.email.label')}
                  placeholder={translate('global.form.email.placeholder')}
                  type="email"
                  validate={{
                    required: { value: true, message: translate('global.messages.validate.email.required') },
                    minLength: { value: 5, message: translate('global.messages.validate.email.minlength') },
                    maxLength: { value: 254, message: translate('global.messages.validate.email.maxlength') },
                    validate: v => isEmail(v) || translate('global.messages.validate.email.invalid'),
                  }}
                  data-cy="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </Col>

            </Row>

            <Button color="primary" type="submit" data-cy="submit">
              <Translate contentKey="settings.form.button">Save</Translate>
            </Button>
          </ValidatedForm>
        </Col>
      </Row>

      <div className="divider with-space"/>

      {/* Password reset section */}
      <Row className="justify-content-center admin-wrapper">
        <Col md="10">
          <div className="spacer30"/>
          <h3 className="arrow">
            <Translate contentKey="password.changeTitle">Change your password</Translate>
          </h3>
          <p><Translate contentKey="password.changeDescription">Enter a new password to change your current password</Translate></p>

          <ValidatedForm id="password-form" onSubmit={handleValidPasswordSubmit}>
          <Row>
            <Col md="3">
              <ValidatedField
                name="currentPassword"
                label={translate('global.form.currentpassword.label')}
                placeholder={translate('global.form.currentpassword.placeholder')}
                type="password"
                validate={{
                  required: { value: true, message: translate('global.messages.validate.newpassword.required') },
                }}
                data-cy="currentPassword"
                value={currentPassword}
                onChange={(event) => setCurrentPassword(event.target.value)}
              />
            </Col>
            <Col md="3">
              <ValidatedField
                name="newPassword"
                label={translate('global.form.newpassword.label')}
                placeholder={translate('global.form.newpassword.placeholder')}
                type="password"
                validate={{
                  required: { value: true, message: translate('global.messages.validate.newpassword.required') },
                  minLength: { value: 4, message: translate('global.messages.validate.newpassword.minlength') },
                  maxLength: { value: 50, message: translate('global.messages.validate.newpassword.maxlength') },
                }}
                data-cy="newPassword"
                value={newPassword}
                onChange={(event) => setNewPassword(event.target.value)}
              />
            </Col>

            <Col md="3">
                <ValidatedField
                  name="confirmPassword"
                  label={translate('global.form.confirmpassword.label')}
                  placeholder={translate('global.form.confirmpassword.placeholder')}
                  type="password"
                  validate={{
                    required: { value: true, message: translate('global.messages.validate.confirmpassword.required') },
                    minLength: { value: 4, message: translate('global.messages.validate.confirmpassword.minlength') },
                    maxLength: { value: 50, message: translate('global.messages.validate.confirmpassword.maxlength') },
                    validate: v => v === newPassword || translate('global.messages.error.dontmatch'),
                  }}
                  data-cy="confirmPassword"
                  value={confirmPassword}
                  onChange={(event) => setConfirmPassword(event.target.value)}
                />
              </Col>

            <Row>
              <Col md={{ offset: 3, size: 3 }}>
                <PasswordStrengthBar password={newPassword} />
              </Col>
            </Row>

            <Col md="3">
              <Button color="success" type="submit" data-cy="submit">
                <Translate contentKey="password.form.button">Save</Translate>
              </Button>
            </Col>
            </Row>

          </ValidatedForm>

        </Col>
      </Row>
    </div>
  );
};

export default SettingsPage;
