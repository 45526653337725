import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getSession } from 'app/shared/reducers/authentication';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Institution from './institution';
import InstitutionDetail from './institution-detail';
import InstitutionUpdate from './institution-update';
import InstitutionDeleteDialog from './institution-delete-dialog';
import InstitutionSurveyToDoList from './survey-to-do/institution-survey-to-do-list';
import InstitutionSurveyToDoDetails from './survey-to-do/institution-survey-to-do-details';
import InstitutionSurveyToDoEdit from './survey-to-do/institution-survey-to-do-update';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';


const InstitutionRoutesForIrexAdmin = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Institution/>} />
    <Route path="new" element={<InstitutionUpdate isIrexAdmin={true} />} />
    <Route path=":id">
      <Route index element={<InstitutionDetail isIrexAdmin={true} />} />
      <Route path="edit" element={<InstitutionUpdate isIrexAdmin={true} />} />
      <Route path="delete" element={<InstitutionDeleteDialog />} />
      <Route path="survey-to-dos">
        <Route index element={<InstitutionSurveyToDoList />} />
        <Route path="new" element={<InstitutionSurveyToDoEdit />} />
        <Route path=":surveyToDoId" element={<InstitutionSurveyToDoDetails />} />
        <Route path=":surveyToDoId/edit" element={<InstitutionSurveyToDoEdit />} />
      </Route>
    </Route>
  </ErrorBoundaryRoutes>
);

const InstitutionRoutesForTvetAdmin = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<Institution />} />
    <Route path="new" element={<InstitutionUpdate isIrexAdmin={false} />} />
    <Route path=":id">
      <Route index element={<InstitutionDetail isIrexAdmin={false} />} />
      <Route path="edit" element={<InstitutionUpdate isIrexAdmin={false} />} />
      <Route path="survey-to-dos">
        <Route index element={<InstitutionSurveyToDoList />} />
        <Route path="new" element={<InstitutionSurveyToDoEdit />} />
        <Route path=":surveyToDoId" element={<InstitutionSurveyToDoDetails />} />
        <Route path=":surveyToDoId/edit" element={<InstitutionSurveyToDoEdit />} />
      </Route>
    </Route>
  </ErrorBoundaryRoutes>
);

export const InstitutionRoutes = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getSession());
  }, []);

  const isIrexAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities,
    [AUTHORITIES.ADMIN, AUTHORITIES.IREX_ADMIN]));

  return (
  <>
    { isIrexAdmin ? <InstitutionRoutesForIrexAdmin /> : <InstitutionRoutesForTvetAdmin /> }
  </>
  );
};

export default InstitutionRoutes;
