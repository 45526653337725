import React, {useEffect, useState} from 'react';
import {Link, useNavigate, useParams} from 'react-router-dom';
import {
  Button,
  Row,
  Col,
  Table,
  Badge,
  Alert,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import {translate, Translate} from 'react-jhipster';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilter} from '@fortawesome/free-solid-svg-icons';
import {useAppDispatch, useAppSelector} from 'app/config/store';
import Select from 'react-select';

import {getEntity as getInstitutionEntity} from '../institution.reducer';
import {setInstitutionId, getEntitiesByInstitutionWithFilters, getAllStates} from './surveyToDo.reducer';
import {getEntitiesByInstitution as getSurveys} from 'app/entities/survey/survey.reducer';
import {getAvailableYearsByInstitution} from 'app/shared/filter/filter-component-reducer';
import {SURVEY_STATUS_COLORS, SURVEY_TYPE, SURVEYTODO_STATUS} from 'app/config/constants';
import { getSurveyTodoStatusDisplay } from 'app/shared/util/display-constants';


export const InstitutionSurveyToDoList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {id} = useParams<'id'>();

  const institutionEntity = useAppSelector(state => state.institution.entity);
  const surveyToDoList = useAppSelector(state => state.surveyToDo.entities);
  const loading = useAppSelector(state => state.surveyToDo.loading);
  const states = useAppSelector(state => state.surveyToDo.toDoStates);
  const surveys = useAppSelector(state => state.survey.entities);
  const years = useAppSelector(state => state.filterSlice.years);

  const objAll = {value: '', label: translate('global.selectAll')};
  const [filterStatus, setFilterStatus] = useState({value: SURVEYTODO_STATUS.PENDING, label: getSurveyTodoStatusDisplay(SURVEYTODO_STATUS.PENDING)});
  const [filterSurvey, setFilterSurvey] = useState(objAll);
  const [filterYear, setFilterYear] = useState(objAll);
  const [filterLocatorId, setFilterLocatorId] = useState('');

  const [modalNewToDoIsOpen, setModalNewToDoIsOpen] = useState(false);


  useEffect(() => {
    dispatch(getAllStates());
    dispatch(getAvailableYearsByInstitution(id));
    dispatch(getSurveys(id));
    dispatch(setInstitutionId(id));
    dispatch(getInstitutionEntity(id));
    handleSyncList();
  }, []);

  const handleSyncList = () => {
    dispatch(getEntitiesByInstitutionWithFilters({
      institutionId: id,
      status: filterStatus.value,
      survey: filterSurvey.value,
      year: filterYear.value,
      locatorId: filterLocatorId
    }));
  };

  const handleFilterStatusChange = (selectedOption: any) => {
    setFilterStatus(selectedOption);
  };

  const handleFilterSurveyChange = (selectedOption: any) => {
    setFilterSurvey(selectedOption);
  };

  const handleFilterYearChange = (selectedOption: any) => {
    setFilterYear(selectedOption);
  };

  const navigateTo = (value) => {
    navigate(value);
  };

  return (
    <Row>
      <Col>
        <h2 data-cy="institutionDetailsHeading">

          <Translate contentKey="irexForsahApp.surveyToDos.listView.title">List</Translate> {' '}
          <em>&apos;{institutionEntity.name}&apos;</em>

          <div className="d-flex justify-content-end" style={{marginTop: 10}}>

            <button className='btn btn-primary me-2' onClick={() => setModalNewToDoIsOpen(true)}>
              <FontAwesomeIcon icon="plus"/>  &nbsp;
              <Translate contentKey="irexForsahApp.surveyToDos.buttons.createNew">Create new Survey ToDo</Translate>
            </button>

            <Link to={`/usersHome/institution/${institutionEntity.id}`} className="btn btn-ligth-blue pad-t-15 me-2"
                  id="jh-create-entity" data-cy="entityCreateButton">
              <img src="/content/images/icons/tree-icon.svg" alt="tree icon"/>{' '}
              <Translate contentKey="irexForsahApp.surveyToDos.buttons.backInstitution">Back to Institution</Translate>
            </Link>
            <Link to='/usersHome/institution' className="btn btn-secondary pad-t-15"
                  id="jh-create-entity" data-cy="entityCreateButton">
              <FontAwesomeIcon icon="arrow-left"/>{' '}
              <Translate contentKey="irexForsahApp.surveyToDos.buttons.backList">Back to List</Translate>
            </Link>
          </div>
        </h2>

        <Card className="my-2" style={{width: '100%'}}>
          <CardBody>
            <Row>
              <Col md="2">
                <div className="filter-header">
                  <Translate contentKey="irexForsahApp.surveyToDos.listView.status">status</Translate>
                </div>
                <Select
                  name="status"
                  options={[
                    objAll,
                    ...states.map(state => ({value: state, label: getSurveyTodoStatusDisplay(state)}))
                  ]}
                  value={filterStatus}
                  classNamePrefix="select"
                  onChange={handleFilterStatusChange}
                />
              </Col>

              <Col md="6">
                <div className="filter-header">
                  <Translate contentKey="irexForsahApp.surveyToDos.listView.survey">survey</Translate>
                </div>
                <Select
                  name="surveys"
                  options={[
                    objAll,
                    ...surveys.map(survey => ({value: survey.id, label: survey.name}))
                  ]}
                  value={filterSurvey}
                  classNamePrefix="select"
                  onChange={handleFilterSurveyChange}
                />
              </Col>

              <Col md="2">
                <div className="filter-header">
                  <Translate contentKey="irexForsahApp.surveyToDos.listView.year">year</Translate>
                </div>
                <Select
                  name="year"
                  options={[
                    objAll,
                    ...years.map(year => ({value: year, label: year}))
                  ]}
                  value={filterYear}
                  classNamePrefix="select"
                  onChange={handleFilterYearChange}
                />
              </Col>

              <Col md="2">
                <div>
                  <div className="filter-header">
                    <Translate contentKey="irexForsahApp.surveyToDos.listView.locatorId">locatorId</Translate>
                  </div>
                  <input type='number' className='form-control' value={filterLocatorId}
                         onChange={(event) => setFilterLocatorId(event.target.value)}/>
                </div>
              </Col>
            </Row>

          </CardBody>
          <CardFooter>
            <Button color="secondary" onClick={handleSyncList} outline>
              <FontAwesomeIcon icon={faFilter}/>{' '}
              <Translate contentKey="irexForsahApp.surveyToDos.listView.search">search</Translate>
            </Button>
          </CardFooter>
        </Card>

        <dl className="jh-entity-details">

          {surveyToDoList && surveyToDoList.length > 0 ? (
            <Col>
              {[...surveyToDoList].sort((a, b) => a.year - b.year).map((surveyToDo, i) => (
                <Alert key={i} color={i % 2 === 0 ? "dark" : "secondary"} style={{padding: 0, height: 100}}>
                  <Row style={{paddingLeft: 10}}>
                    <Col md="10" style={{padding: 10}}>
                      <div>
                        <Badge color={SURVEY_STATUS_COLORS[surveyToDo.status] || "default"}>
                          {getSurveyTodoStatusDisplay(surveyToDo.status)}
                        </Badge> &nbsp;
                        <strong><em>{surveyToDo.year}</em>{' | ' + surveyToDo.survey.name}</strong>
                      </div>
                      <br/>

                      <div>
                        <Badge color="secondary" pill> {translate('irexForsahApp.surveyToDo.locator')} {surveyToDo.locatorId} </Badge> &nbsp;
                        <em>{surveyToDo.comment}</em>
                      </div>
                    </Col>
                    <Col md="2">

                      <div className="table-buttons-column background-f2">
                        <div className="table-buttons-wrapper">
                      
                          <span className='pointer view' onClick={() => navigateTo(`/usersHome/institution/${institutionEntity.id}/survey-to-dos/${surveyToDo.id}`)}> 
                            <img src="/content/images/icons/eye-icon.svg" alt="view icon"/> 
                            <Translate contentKey="entity.action.view">View</Translate>
                          </span>

                          <span className='pointer edit' onClick={() => navigateTo(`/usersHome/institution/${institutionEntity.id}/survey-to-dos/${surveyToDo.id}/edit?surveyType=${surveyToDo.survey.type}`)}> 
                            <img src="/content/images/icons/pencil-icon.svg" alt="edit icon"/> 
                            <Translate contentKey="entity.action.edit">Edit</Translate>
                          </span> 
                                                
                        </div>
                      </div>

                    </Col>
                  </Row>
                </Alert>
              ))}
            </Col>
          ) : (
            <div className="alert alert-warning">
              <Translate contentKey="irexForsahApp.surveyToDos.listView.notFound" />
            </div>
          )}

        </dl>
      </Col>

      <Modal isOpen={modalNewToDoIsOpen} backdrop={true}>
        <ModalHeader>
          <FontAwesomeIcon icon="plus"/> &nbsp;
          <Translate contentKey="irexForsahApp.surveyToDos.buttons.createNew" />
        </ModalHeader>
        <ModalBody>
          <p><Translate contentKey="irexForsahApp.surveyToDos.listView.chooseSurveyType" /></p>

          <ButtonGroup style={{width: '100%'}}>
            <Button outline tag={Link} color="secondary" block
                    to={`/usersHome/institution/${institutionEntity.id}/survey-to-dos/new?surveyType=${SURVEY_TYPE.DATA_COLLECTION}`}>
              <Translate contentKey="irexForsahApp.surveyToDos.listView.dataCollection" />
            </Button>
            <Button outline tag={Link} color="secondary" block
                    to={`/usersHome/institution/${institutionEntity.id}/survey-to-dos/new?surveyType=${SURVEY_TYPE.DATA_ANALYSIS_SCORING}`}>
              <Translate contentKey="irexForsahApp.surveyToDos.listView.dataAnalysis" />
            </Button>
          </ButtonGroup>
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={() => setModalNewToDoIsOpen(false)}>
            <Translate contentKey="irexForsahApp.dataValidation.modal.close"/>
          </Button>
        </ModalFooter>
      </Modal>
    </Row>
  );
};

export default InstitutionSurveyToDoList;
