import React, { useEffect, useMemo, useState } from "react";
import { getScoringMatrix } from "../tables/table-scoring-matrix-reducer";
import { checkAllInsitutionFormsAreApproved, assessmentReportSlice } from "../reports/assessment-report-reducer";
import {Translate, translate} from 'react-jhipster';
import { useAppDispatch, useAppSelector } from "app/config/store";
import { Alert, Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Filter from "app/shared/filter/filter-component";
import { faCheck, faCircleExclamation, faDownload, faRefresh, faTimes, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as echarts from 'echarts/core';
import { RadarChart, BarChart } from 'echarts/charts';
import { LegendComponent, RadarComponent } from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import { getRadarData } from "app/modules/charts/radar-chart-reducer"; 
import { getBarData } from "app/modules/charts/bar-chart-reducer";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { ICheckInstitutionHasAllSectionsApproved } from "app/shared/model/check-inst-has-all-forms-approved.model";
import { IInstitutionHasAllSectionsApproved } from "app/shared/model/institution-has-all-sections-approved.model";
import LoadingSpinner from "app/shared/components/LoadingSpinner";
import { COLOR_PALLETE } from "app/config/constants";
import { fontsObject, getBase64Image, getPdfContentWithCoverPage, loadSVG } from "app/modules/visualizations/pdfResources";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface AssessmentData {
  institution: any;
  scoringMatrix: any;
  radarChartData: any;
  barChartData: any;
  scoringMatrixDone: boolean;
  radarChartDataDone: boolean;
  barChartDataDone: boolean;
}


export const AssessmentReport = () => {
  const dispatch = useAppDispatch();

  echarts.use([
    RadarChart,
    BarChart,
    LegendComponent,
    RadarComponent,
    CanvasRenderer
  ]);

  pdfMake.fonts = fontsObject;

  const radarLegend = useAppSelector((state) => state.radarChartsSlice.radarData.legend);
  const radarIndicators = useAppSelector((state) => state.radarChartsSlice.radarData.indicators);
  const radarSeries = useAppSelector((state) => state.radarChartsSlice.radarData.series);
  const radarLoading = useAppSelector((state) => state.radarChartsSlice.loading);

  const barChart = useAppSelector((state) => state.barChartSlice.barChartData)
  const barLoading = useAppSelector((state) => state.barChartSlice.loading);

  const loadingScoringMatrix = useAppSelector((state) => state.tableScoringMatrixReducer.loading);
  const scoringMatrixData = useAppSelector((state) => state.tableScoringMatrixReducer.entity);
  const listScoringMatrixData = useAppSelector((state) => state.tableScoringMatrixReducer.entities);
  const scoringMatrixError = useAppSelector((state) => state.tableScoringMatrixReducer.errorMessage);

  const filterState = useAppSelector((state) => state.filterSlice);
  const institutions = useAppSelector((state) => state.filterSlice.institutions);
  const years = useAppSelector((state) => state.filterSlice.years);

  const institutionsFormsApproved: IInstitutionHasAllSectionsApproved[] = useAppSelector((state) => state.assessmentReportReducer.entities);

  const [preparingPdf, setPreparingPdf] = useState(false);
  const [showYearAlert, setShowYearAlert] = useState(false);

  const [reportFilterState, setReportFilterState] = useState({
    selectedInstitutions: [],
    selectedYears: [],
    institutions,
    years
  });
  
  const [yearSelected, setYearSelected] = useState('');

  const [logoUsaid, setLogoUsai] = useState<string | null>(null);
  const [logoIrex, setLogoIrex] = useState<string | null>(null);
  const [logoDai, setLogoDai] = useState<string | null>(null);
  const [logoVATech, setLogoVATech] = useState<string | null>(null);
  const [logoDG, setLogoDG] = useState<string | null>(null);
  const [logoPartner4, setLogoPartner4] = useState<string | null>(null);
  const [logoPartner5, setLogoPartner5] = useState<string | null>(null);
  const [logoPartner6, setLogoPartner6] = useState<string | null>(null);

  const [listInstitutionsData, setListInstitutionsData] = useState<AssessmentData[]>([]);
  const [canBePrinted, setCanBePrinted] = useState(false);
  const [institutionsSelectedWithoutData, setInstitutionsSelectedWithoutData] = useState<string>('');
  const [institutionsSelectedWithoutFormsApproved, setInstitutionsSelectedWithoutFormsApproved] = useState<string>('');
  const [thereAreInstiSelectedWithFormsApproved, setThereAreInstiSelectedWithFormsApproved] = useState(false);
  const [pageInitialized, setPageInitialized] = useState(false);

  const columns = useMemo(
    () => [
      {
        header: `${translate('irexForsahApp.tables.columns.section')}`,
        accessorKey: 'section',
      },
      {
        header: `${translate('irexForsahApp.tables.columns.subsection')}`,
        accessorKey: 'subSection',
      },
      {
        header: `${translate('irexForsahApp.tables.columns.criteria')}`,
        accessorKey: 'criteria',
      },
      {
        header: `${translate('irexForsahApp.tables.columns.objective')}`,
        accessorKey: 'objective',
      },
      {
        header: `${translate('irexForsahApp.tables.scoringMatrix.columns.score')}`,
        accessorKey: 'score',
      },
      {
        header: `${translate('irexForsahApp.tables.scoringMatrix.columns.capacityBuilding')}`,
        accessorKey: 'capacityBuilding',
      },
      {
        header: `${translate('irexForsahApp.tables.scoringMatrix.columns.facilitatorComments')}`,
        accessorKey: 'facilitatorComments',
      }
    ],[]
  );

  const initStates = () => {
    setListInstitutionsData([]);
    setInstitutionsSelectedWithoutData('');
    setCanBePrinted(false);
    setThereAreInstiSelectedWithFormsApproved(false);
    setInstitutionsSelectedWithoutFormsApproved('');
  }

  useEffect(() => {
    dispatch(assessmentReportSlice.actions.reset());
    initStates();
    setPageInitialized(true);
    loadPngLogos();
    loadSvgLogos();
  }, []);


  useEffect(() => {
    setReportFilterState(prevState => ({
      ...prevState,
      institutions,
      years
    }));
  }, [institutions, years]);

  useEffect(() => {
    if (pageInitialized && institutionsFormsApproved && institutionsFormsApproved.length > 0) {
      const selectedInstitutions: number[] = []; 
      const selectedYears = reportFilterState.selectedYears;
      let institutionsWithoutFormsApproved = '';
      let currentInstitution: number;

      const listInitialInstitutionData: AssessmentData[] = [];
      
      for (let i = 0; i < institutionsFormsApproved.length; i++) {
          currentInstitution = institutionsFormsApproved[i].institutionId;
          const institution = reportFilterState.institutions.find(ii => ii.id === currentInstitution);
        if (institutionsFormsApproved[i].hasAllSectionsApproved) {
          selectedInstitutions.push(currentInstitution);
          const initialInstitutionData = {
            institution,
            scoringMatrix: null,
            radarChartData: null,
            barChartData: null,
            scoringMatrixDone: false,
            radarChartDataDone: false,
            barChartDataDone: false
          }
          listInitialInstitutionData.push(initialInstitutionData);
        } else {          
          institutionsWithoutFormsApproved += institution.name + ' | ';
        }
      }

      if (institutionsWithoutFormsApproved !== '') {
        institutionsWithoutFormsApproved = institutionsWithoutFormsApproved.substring(0, institutionsWithoutFormsApproved.length - 3);
      }
      setInstitutionsSelectedWithoutFormsApproved(institutionsWithoutFormsApproved);

      if (selectedInstitutions.length > 0) {
        setListInstitutionsData(listInitialInstitutionData);
        setThereAreInstiSelectedWithFormsApproved(true);
        const filter = {
          selectedInstitutions,
          selectedYears
        };

        dispatch(getScoringMatrix(filter));
        dispatch(getRadarData(filter));
        dispatch(getBarData(filter));
      }

    } else {
      initStates();
    }

  }, [institutionsFormsApproved]);

  useEffect(() => {
    if (listScoringMatrixData && listScoringMatrixData.length > 0) {
      setListInstitutionsData(prevState =>
        prevState.map(stateItem => {
          // Finds the ITableScoringMatrix corresponding to this institution
          const scoringData = listScoringMatrixData.find(matrix =>
            matrix.institutions && matrix.institutions.some(inst => inst.id === stateItem.institution.id)
          );
  
          // If found, updates scoringMatrix, otherwise returns the item unchanged.
          if (scoringData) {
            return {
              ...stateItem,
              scoringMatrix: scoringData.rows,
              scoringMatrixDone: true
            };
          }
          return stateItem;
        })
      );
    }
  }, [listScoringMatrixData]);

  useEffect(() => {
    if (radarSeries && radarSeries.length > 0) {
      setListInstitutionsData(prevState =>
        prevState.map(stateItem => {
          // Finds the ITableScoringMatrix corresponding to this institution
          const radarData = radarSeries.find(radar =>
            radar.name === stateItem.institution.acronym
          );
  
          // If found, updates scoringMatrix, otherwise returns the item unchanged.
          if (radarData) {
            const radarOption = getRadarOption([radarData]);
            const chartDataURL = generateChartImage(radarOption);
            return {
              ...stateItem,
              radarChartData: chartDataURL,
              radarChartDataDone: true
            };
          }
          return stateItem;
        }
      ));
    }
  }, [radarSeries]);

  useEffect(() => {
    if (barChart) {
      // #region Delete "Section n" from the x-axis labels
      let xData = []
      if (barChart.xAxis.data.length > 0 && Array.isArray(barChart.xAxis.data)) {
        xData = barChart.xAxis.data.map(data => {
          const splitData = data.split(":");
          return splitData.length > 1 ? splitData[1].trim() : data;
        });
      }
      // #endregion

      setListInstitutionsData(prevState =>
        prevState.map(stateItem => {
          // Finds the ITableScoringMatrix corresponding to this institution
          const barData = barChart.series.find(bar =>
            bar.name === stateItem.institution.acronym
          );
  
          if (barData) {
            const barChartOption = getBarChartOption([barData], xData);
            const chartDataURL = generateChartImage(barChartOption);
            return {
              ...stateItem,
              barChartData: chartDataURL,
              barChartDataDone: true
            };
          }
          return stateItem;
        }));
    }
  },[barChart]);

  useEffect(() => {
    if (
      scoringMatrixData && scoringMatrixData.rows && scoringMatrixData.rows.length > 0 &&
      radarSeries && radarSeries.length > 0 &&
      barChart.series && barChart.series.length > 0 &&
      yearSelected &&
      !showYearAlert && 
      !loadingScoringMatrix && 
      scoringMatrixError === '' && 
      !radarLoading && 
      !barLoading
    ) {
      listInstitutionsData.forEach(institutionData => {
        if (!institutionData.scoringMatrixDone || !institutionData.radarChartDataDone || !institutionData.barChartDataDone) {
          setCanBePrinted(false);
          return;
        }
      });
      setCanBePrinted(true);
      checkInstitutionsData();
    }
  }, [scoringMatrixData, radarSeries, yearSelected, showYearAlert, loadingScoringMatrix, scoringMatrixError, radarLoading, barLoading, listInstitutionsData]);
  

  const checkInstitutionsData = () => {
    let institutionsWithoutData = '';
    listInstitutionsData.forEach(institutionData => {
      if (institutionData.scoringMatrix == null || institutionData.radarChartData == null || institutionData.barChartData == null) {
        institutionsWithoutData += institutionData.institution.name + ' | ';
      }
    });
    if (institutionsWithoutData !== '') {
      institutionsWithoutData = institutionsWithoutData.substring(0, institutionsWithoutData.length - 3);
    }
    setInstitutionsSelectedWithoutData(institutionsWithoutData);
  }

  const getBarChartOption = (pBarChartSeries, pXData) => {
    const seriesDataValues = extractDataValues(pBarChartSeries);
      const barChartOption = {
        animation: false,
        xAxis: {
          data: pXData,
          axisLabel: {
            interval: 0
          },
          axisTick: {show: true},
        },
        yAxis: {},
        legend: {
          data: seriesDataValues.map(series => series.name),
          textStyle: {
            fontSize: 17,
            fontWeight: 'bold'
          }
        },        
        series: seriesDataValues.map(series => ({
          ...series,
          itemStyle: {
            color: COLOR_PALLETE.USAID_BLUE
          },
          emphasis: {
            focus: 'series'
          },
          label: {
            show: true,
            position: 'top',
            verticalAlign: 'middle'
          }
        }))
      };

      return barChartOption;
  };

  const extractDataValues = (series: any[]): any[] => {
    const groupedData: any = {};

    series.forEach(item => {
      if (Array.isArray(item.data)) {
        item.data.forEach((dataPoint: any) => {
          if (Array.isArray(dataPoint.value)) {
            dataPoint.value.forEach(value => {
              if (value !== null && value !== undefined && !isNaN(value)) {
                if (!groupedData[item.name]) {
                  groupedData[item.name] = [];
                }
                groupedData[item.name].push(Number(value));
              }
            });
          }
        });
      }
    });

    return Object.keys(groupedData).map(key => {
      return {
        name: key,
        type: 'bar',
        data: groupedData[key]
      };
    });
  };

  const getRadarOption = (pRadarSeries) => {
    return {
      animation: false,
      legend: {
        data: radarLegend && radarLegend.data ? radarLegend.data : [],
        textStyle: {
          fontSize: 17,
          fontWeight: 'bold'
        }
      },
      radar: {
        indicator: radarIndicators
      },
      series: pRadarSeries ? pRadarSeries.map((seriesItem: any, index: number) => ({
        ...seriesItem,
        itemStyle: {
          color: COLOR_PALLETE.USAID_BLUE
        },
        label: {
          show: true,
          position: 'top',
          verticalAlign: 'middle'
        }
      })) : []
    };
  }

  const generateChartImage = (option) => {
    // Create a hidden div to render the chart
    const chartDiv = document.createElement('div');
    chartDiv.style.width = '100%';
    chartDiv.style.height = '700px';
    document.body.appendChild(chartDiv);
  
    // Create echart instance
    const chart = echarts.init(chartDiv);
    chart.setOption(option);

    // Get the base64 image URL
    const chartDataURL = chart.getDataURL({
      pixelRatio: 2,
      backgroundColor: '#fff'
    });
  
    // Destroy the echarts instance and remove the div from the DOM
    chart.dispose();
    document.body.removeChild(chartDiv);
  
    return chartDataURL;
  }; 

  const loadSvgLogos = async () => {
    const usaidLogo = await loadSVG('content/images/usaid-logo.svg');
    setLogoUsai(usaidLogo);

    const irexLogo = await loadSVG('content/images/irex-logo.svg');
    setLogoIrex(irexLogo);

    const daiLogo = await loadSVG('content/images/dai-logo.svg');
    setLogoDai(daiLogo);

    const vaTechLogo = await loadSVG('content/images/va-tech-logo.svg');
    setLogoVATech(vaTechLogo);

    const dgLogo = await loadSVG('content/images/dg-logo.svg');
    setLogoDG(dgLogo);
  };

  const loadPngLogos = async () => {
    try {
        const base64_p4 = await getBase64Image('content/images/bwfp-logo.png');
        setLogoPartner4(`data:image/png;base64,${base64_p4}`);

        const base64_p5 = await getBase64Image('content/images/bethlehem-chamber-of-commerce.png');
        setLogoPartner5(`data:image/png;base64,${base64_p5}`);

        const base64_p6 = await getBase64Image('content/images/partner-logo.png');
        setLogoPartner6(`data:image/png;base64,${base64_p6}`);
    } catch (error) {
        console.error('Error loading image', error);
    }
  };
  
  const handleFilterChange = (filter: { selectedInstitutions: number[]; selectedYears: number[] }) => {
    setShowYearAlert(filter.selectedYears.length > 1 && filter.selectedInstitutions.length > 0);
    if (filter.selectedInstitutions.length > 0 && filter.selectedYears.length === 1) {
      const checkInstitutionsFormsStatus: ICheckInstitutionHasAllSectionsApproved = {
        year: filter.selectedYears[0],
        institutionsIds: filter.selectedInstitutions
      };

      setInstitutionsSelectedWithoutData('');
      setInstitutionsSelectedWithoutFormsApproved('');
      setThereAreInstiSelectedWithFormsApproved(false);
      setCanBePrinted(false);
      setReportFilterState({
        ...filter,
        institutions: reportFilterState.institutions,
        years: reportFilterState.years
      });
      setYearSelected(filter.selectedYears[0].toString());

      dispatch(checkAllInsitutionFormsAreApproved(checkInstitutionsFormsStatus));
    }
  };

  const getTableData = (rows) => {
    
    const tableData: any[] = [];
    rows.forEach((row) => {
        tableData.push({
            section: row.section,
            subSection: row.subSection,
            criteria: row.criteria,
            objective: row.objective,
            score: row.score,
            capacityBuilding: row.capacityBuilding,
            facilitatorComments: row.facilitatorComments
        });
    });

    const groupedData: any[] = [];
    let currentSection = '';
    let currentSubSection = '';

    tableData.forEach((item) => {
        if (item.section !== currentSection) {
            currentSection = item.section;
            currentSubSection = item.subSection;
            groupedData.push([
                { 
                    text: currentSection, 
                    rowSpan: tableData.filter(d => d.section === currentSection).length,
                    style: 'group1'
                }, 
                { 
                    text: currentSubSection, 
                    rowSpan: tableData.filter(d => d.section === currentSection && d.subSection === currentSubSection).length,
                    style: 'group2'
                }, 
                { text: item.criteria || '', style: 'smallText' },
                { text: item.objective || '', style: 'smallText' },
                { text: item.score || '', style: 'smallText', alignment: 'center' },
                { text: item.capacityBuilding || '', style: 'smallText' },
                { text: item.facilitatorComments || '', style: 'smallText' }
            ]);
        } else if (item.subSection !== currentSubSection) {
            currentSubSection = item.subSection;
            groupedData.push([
                '', 
                { 
                    text: item.subSection, 
                    rowSpan: tableData.filter(d => d.section === currentSection && d.subSection === currentSubSection).length,
                    style: 'group2'
                }, 
                { text: item.criteria || '', style: 'smallText' },
                { text: item.objective || '', style: 'smallText' },
                { text: item.score || '', style: 'smallText', alignment: 'center' },
                { text: item.capacityBuilding || '', style: 'smallText' },
                { text: item.facilitatorComments || '', style: 'smallText' }
            ]);
        } else {
            groupedData.push([
                '', 
                '', 
                { text: item.criteria || '', style: 'smallText' },
                { text: item.objective || '', style: 'smallText' },
                { text: item.score || '', style: 'smallText', alignment: 'center' },
                { text: item.capacityBuilding || '', style: 'smallText' },
                { text: item.facilitatorComments || '', style: 'smallText' }
            ]);
        }
    });

    const tableHeaders = columns.map((c) => ({
        text: c.header,
        style: 'tableHeader'
    }));

    const response = {
        tableHeaders,
        groupedData
    };
    return response;
    
  };


  const buildPdfContent = () => {
    const acronyms = [];
    let institutionSelected = '';

    let institutionLogoObj = {};
    let institutionLogo = '';

    const pdfContent = getPdfContentWithCoverPage('Assessment Report', logoUsaid, logoIrex, logoDai, logoVATech, logoDG, logoPartner4, logoPartner5, logoPartner6);    

    listInstitutionsData.forEach((institutionData) => {
      if (institutionData.scoringMatrix != null && institutionData.radarChartData != null && institutionData.barChartData != null) {
        const { tableHeaders, groupedData } = getTableData(institutionData.scoringMatrix);

        institutionSelected = institutionData.institution.name;
        pdfContent.push({ text: institutionData.institution.name, style: 'institutionName', margin: [0, 40, 0, 0], pageBreak: 'before', currentInstitutionName: institutionData.institution.name })

        pdfContent.push({ text: `(${institutionData.institution.acronym})`, style: 'subHeader', currentInstitutionName: institutionData.institution.name });
        acronyms.push(institutionData.institution.acronym);

        
        institutionLogoObj = {};
        institutionLogo = '';
        if (institutionData.institution.logo) {
          institutionLogoObj = {
            image: 'data:image/jpeg;base64,' + institutionData.institution.logo,
            fit: [200, 200],
            alignment: 'left', 
            currentInstitutionName: institutionData.institution.name
          };

          institutionLogo = 'data:image/jpeg;base64,' + institutionData.institution.logo;
        }
        
        pdfContent.push({ columns: [
            {
              stack: [
                { text: `${translate('irexForsahApp.tables.institutionReport.information')}`, style: 'subheaderWithMargin', currentInstitutionName: institutionData.institution.name },
                { text: `${institutionData.institution.address}`, style: 'bold', currentInstitutionName: institutionData.institution.name },
                { text: `${institutionData.institution.region.name}${(institutionData.institution.governorate ? ', ' + institutionData.institution.governorate.name : '')}`, 
                  margin: [0, 0, 0, 20], currentInstitutionName: institutionData.institution.name
                }
              ]
            },
            institutionLogoObj
          ], 
          margin: [0, 0, 0, 20], 
          currentInstitutionName: institutionData.institution.name
        });     
        

        pdfContent.push({ columns: [
            {
              stack: [
                { text: `${translate('irexForsahApp.dataValidation.infoTitles.contactName')}`, style: 'redBold' },
                `${institutionData.institution.focalPointName}`
              ]
            },
            {
              stack: [
                { text: `${translate('irexForsahApp.dataValidation.infoTitles.contactNumber')}`, style: 'redBold' },
                `${institutionData.institution.telephone} / ${institutionData.institution.mobilePhone} / ${institutionData.institution.email}`,
              ]
            }
          ], 
          margin: [0, 0, 0, 20], 
          currentInstitutionName: institutionData.institution.name
        });

        pdfContent.push({ columns: [
            {
              stack: [
                { text: `${translate('irexForsahApp.institution.accreditationBody')}`, style: 'redBold' },
                `${institutionData.institution.accreditationBody.name}`
              ]
            },
            {
              stack: [
                { text: `${translate('irexForsahApp.institution.programLevel')}`, style: 'redBold' },
                `${institutionData.institution.programLevel.name}`,
              ]
            }
          ], 
          margin: [0, 0, 0, 20], 
          currentInstitutionName: institutionData.institution.name
        });

        pdfContent.push({ text: `${translate('visualisation.charts.subSectionComparison')}`, style: 'header', pageBreak: 'before', currentInstitutionName: institutionData.institution.name, currentLogo: institutionLogo });
        pdfContent.push({ image: institutionData.radarChartData, width: 900, margin: [0, 50, 0, 0], alignment: 'center', currentInstitutionName: institutionData.institution.name, currentLogo: institutionLogo});

        pdfContent.push({ text: `${translate('visualisation.charts.sectionComparison')}`, style: 'header', pageBreak: 'before', currentInstitutionName: institutionData.institution.name, currentLogo: institutionLogo });
        pdfContent.push({ image: institutionData.barChartData, width: 900, margin: [0, 50, 0, 0], alignment: 'center', currentInstitutionName: institutionData.institution.name, currentLogo: institutionLogo});

        pdfContent.push({ text: `${translate('irexForsahApp.tables.scoringMatrix.title')}`, style: 'header', margin: [0, 10, 0, 10], pageBreak: 'before', currentInstitutionName: institutionData.institution.name, currentLogo: institutionLogo });
        pdfContent.push({
          // layout: 'lightHorizontalLines',
          table: {
              headerRows: 1,
              widths: [70, '*', '*', '*', 43, '*', '*'],
              body: [
                  tableHeaders,
                  ...groupedData
              ]
          }, 
          currentInstitutionName: institutionData.institution.name,
          currentLogo: institutionLogo
        });
      }

    });

    const response = {
      pdfContent,
      acronyms,
      institutionSelected
    };
    return response;
  };
  
  const printPDF = () => {
    setPreparingPdf(true);
    const {pdfContent, acronyms, institutionSelected} = buildPdfContent();

    if (pdfContent.length > 0) {
      let institutionsAcronyms = '';
      if (acronyms.length > 1) {
        institutionsAcronyms = acronyms.map(acronym => acronym).join(' - ');
      }

      const today = new Date();
      const footerDate = `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${today.getFullYear()}`; // MM-dd-yyyy

      const docDefinition = {
        pageOrientation: 'landscape',
        pageMargins: 40,  
        header(currentPage) {
          let headerResult = {};

          if (currentPage === 1) {
            return headerResult;
          }

          let currentInstitution = "";
          let currentInstitutionLogo = {};
          for(let i = 0; i < docDefinition.content.length; i++) {
              if (docDefinition.content[i] && docDefinition.content[i].positions[0] && docDefinition.content[i].positions[0].pageNumber &&
                 docDefinition.content[i].currentInstitutionName && currentPage >= docDefinition.content[i].positions[0].pageNumber) {
                  currentInstitution = docDefinition.content[i].currentInstitutionName;

                  if (docDefinition.content[i].currentLogo) {
                    currentInstitutionLogo = {
                      image: docDefinition.content[i].currentLogo,
                      fit: [25, 25],
                      alignment: 'right',
                      margin: [0, 10, 20, 0]
                    };
                  } else {
                    currentInstitutionLogo = {};
                  }
              }
          }

          if (Object.keys(currentInstitutionLogo).length === 0) {
            headerResult = {
              columns: [
                { text: `${translate('irexForsahApp.tables.institutionReport.title')}`, alignment: 'left', margin: [40, 13, 0, 0], style: 'footer'},
                { text: yearSelected + ' | ' + currentInstitution, alignment: 'right', margin: [0, 13, 40, 0], style: 'footer'}
              ]
            }
          } else {
            headerResult = {
              columns: [
                { text: `${translate('irexForsahApp.tables.institutionReport.title')}`, alignment: 'left', margin: [40, 13, 0, 0], style: 'footer'},
                { text: yearSelected + ' | ' + currentInstitution, alignment: 'center', margin: [0, 13, 0, 0], style: 'footer'},
                currentInstitutionLogo
              ]
            }
          }

          return headerResult;          
        },     
        footer(currentPage, pageCount) {
          if (currentPage === 1) {
            return {};
          }

          return {
            columns: [
              { text: footerDate, alignment: 'left', margin: [40, 13, 0, 0], style: 'footer'},
              { text: institutionsAcronyms, alignment: 'center', margin: [0, 13, 0, 0], style: 'footer'},
              { text: currentPage.toString() + ' / ' + pageCount, alignment: 'right', margin: [0, 13, 40, 0], style: 'footer'}
            ]
          };
        },
        content: pdfContent,
        defaultStyle: {
          font: 'GillSans'
        },
        styles: {  
          institutionName: {
            fontSize: 27,
            bold: true,
            color: COLOR_PALLETE.USAID_BLUE
          },
          coverPageSub: {
            fontSize: 25,
            bold: true,
            color: COLOR_PALLETE.USAID_RED,
            alignment: 'center'
          },
          coverPageSub2: {
            fontSize: 18,
            color: COLOR_PALLETE.RICH_BLACK,
            alignment: 'center'
          },
          coverDisclaimer: {
            fontSize: 10,
            italic: true,
            color: COLOR_PALLETE.DARK_GRAY,
            alignment: 'center',
            margin: [0, 15, 0, 0]
          },
          header: {
            fontSize: 20,
            bold: true,
            color: COLOR_PALLETE.USAID_BLUE
          },
          subheader: {
            fontSize: 15,
            bold: true,
            italics: true
          },          
          subheaderWithMargin: {
            fontSize: 15,
            bold: true,
            margin: [0, 40, 0, 5],
            color: COLOR_PALLETE.USAID_BLUE
          },          
          tableHeader: {
            fontSize: 15,
            bold: true,
            color: '#fafafa',
            fillColor: COLOR_PALLETE.USAID_BLUE,
            margin: [0, 10, 0, 0]
          },
          group1: {
            fontSize: 13,
            bold: true,
            italics: true,
            color: COLOR_PALLETE.USAID_RED
          },
          group2: {
            bold: true,
            italics: true,
            color: COLOR_PALLETE.USAID_RED
          },
          smallText: {
            fontSize: 10
          },
          footer: {
            fontSize: 7,
            bold: true,
            italics: true,
            color: COLOR_PALLETE.MEDIUM_GRAY
          },
          bold: {
            bold: true
          },
          redBold: {
            bold: true,
            color: COLOR_PALLETE.USAID_RED
          }
        }
      };

      const ymdDate = today.toISOString().split('T')[0]; // YYYY-MM-DD
      const pdfInstName = (institutionsAcronyms === '') ? institutionSelected : institutionsAcronyms;
      pdfMake.createPdf(docDefinition)
            .download(`AssessmentReport-${ymdDate} - ${pdfInstName} - ${yearSelected}.pdf`, 
                        function() { setPreparingPdf(false); });
    }
  }


  

  

  const getScoringMatrixAlertColor = () => {
    if (scoringMatrixError !== '') return 'danger';
    else {
      if (loadingScoringMatrix) return 'info';
      else {
        if (!scoringMatrixData || !scoringMatrixData.rows || scoringMatrixData.rows.length === 0) return 'warning';
        else return 'success';
      }
    }
  }

  

  

  return (

    <div className="validation-wrapper">
      <div style={{marginBottom: '20px'}}>
        <Filter handleFilterChange={handleFilterChange} filterState={reportFilterState}
          maxInstitutions={6} useDefaults={false}/>
      </div>

      { (loadingScoringMatrix || radarLoading || barLoading) && ( <LoadingSpinner />) }

      {showYearAlert && (
        <Alert color='warning'>
          <div>
            <FontAwesomeIcon icon={faCircleExclamation}/> {' '}
            <span><Translate contentKey="irexForsahApp.tables.institutionReport.errorYearSelection" /></span>            
          </div>
        </Alert>
      )}

      <div className="spacer20"/>

      {institutionsSelectedWithoutFormsApproved !== '' && (
        <Alert color='warning'>
          <div>
            <FontAwesomeIcon icon={faTriangleExclamation}/> {' '}
            <span>
              <Translate contentKey="irexForsahApp.tables.institutionReport.institutionsWithouAllSectionsApproved" interpolate={{yearSelected}} />{' '}
              <strong> { institutionsSelectedWithoutFormsApproved } </strong>
            </span>
          </div>
        </Alert>
      )}

      <div className="spacer20"/>

      <div style={{height: '100%'}}>

        { yearSelected && !showYearAlert && thereAreInstiSelectedWithFormsApproved && (

          <Card className="my-2" style={{width: '100%'}}>
            <CardHeader>
              Report for {yearSelected}
            </CardHeader>

            <CardBody>
              <Row>
                <div className='table-column-tags'>
                  <span><Translate contentKey="irexForsahApp.tables.institutionReport.institutionsSelected" />{' '}</span>
                  {
                    listInstitutionsData.map((val, j) => (
                      <span key={j} className='table-tag'>
                        {val.institution.name}
                      </span>
                    ))
                  }
                </div>
              </Row>

              <Row style={{marginTop: 10}}>
                <Col>
                  <Alert color='success'>
                    <div className="justify-content-between" style={{display: 'flex'}}>
                      <span><Translate contentKey="irexForsahApp.tables.institutionReport.institutionData" /></span>
                      <FontAwesomeIcon icon={faCheck} size="xl" />
                    </div>
                  </Alert>
                </Col>

                <Col>
                  <Alert color={getScoringMatrixAlertColor()}>
                    <div className="justify-content-between" style={{display: 'flex'}}>
                      <span><Translate contentKey="irexForsahApp.tables.institutionReport.matrixData" /></span>
                      {
                        scoringMatrixError !== '' ? ( <FontAwesomeIcon icon={faTimes} size="xl" /> ) : (
                          loadingScoringMatrix ? 
                          ( <FontAwesomeIcon icon={faRefresh} spin size="xl" /> ) : (
                            !scoringMatrixData || !scoringMatrixData.rows || scoringMatrixData.rows.length === 0 ? 
                            ( <FontAwesomeIcon icon={faTriangleExclamation} size="xl" /> ) : 
                            ( <FontAwesomeIcon icon={faCheck} size="xl" /> )
                          )                      
                        )
                      }
                    </div> 
                    {
                      !loadingScoringMatrix && scoringMatrixError === '' && (
                        !scoringMatrixData || !scoringMatrixData.rows || scoringMatrixData.rows.length === 0 
                      ) && (
                        <span className="f-size-08">
                          <strong><em>
                            <Translate contentKey="irexForsahApp.tables.institutionReport.noData" />
                          </em></strong>
                        </span>
                      )
                    }
                  </Alert>
                  
                </Col>

                <Col>
                  <Alert color={radarLoading ? 'info' : (radarSeries && radarSeries.length > 0 ? 'success' : 'warning')}>
                    <div className="justify-content-between" style={{display: 'flex'}}>
                      <span><Translate contentKey="irexForsahApp.tables.institutionReport.subSectionData" /></span>
                      {
                        radarLoading ? 
                        ( <FontAwesomeIcon icon={faRefresh} spin size="xl" /> ) : 
                        ( radarSeries && radarSeries.length > 0 ? <FontAwesomeIcon icon={faCheck} size="xl" /> : <FontAwesomeIcon icon={faTriangleExclamation} size="xl" /> )
                      }
                    </div>
                    {
                      !radarLoading && (!radarSeries || radarSeries.length === 0) && (
                        <span className="f-size-08">
                          <strong><em>
                            <Translate contentKey="irexForsahApp.tables.institutionReport.noData" />
                          </em></strong>
                        </span>
                      )
                    }
                  </Alert>
                </Col>
              
                <Col>
                  <Alert color={barLoading ? 'info' : (barChart.series && barChart.series.length > 0 ? 'success' : 'warning')}>
                    <div className="justify-content-between" style={{display: 'flex'}}>
                      <span><Translate contentKey="irexForsahApp.tables.institutionReport.sectionData" /></span>
                      {
                        barLoading ? 
                        ( <FontAwesomeIcon icon={faRefresh} spin size="xl" /> ) : 
                        ( barChart.series && barChart.series.length > 0 ? <FontAwesomeIcon icon={faCheck} size="xl" /> : <FontAwesomeIcon icon={faTriangleExclamation} size="xl" /> )
                      }
                    </div>
                    {
                      !barLoading && ( !barChart.series || barChart.series.length === 0) && (
                        <span className="f-size-08">
                          <strong><em>
                            <Translate contentKey="irexForsahApp.tables.institutionReport.noData" />
                          </em></strong>
                        </span>
                      )
                    }
                  </Alert>
                </Col>
              </Row>

              { institutionsSelectedWithoutData !== '' && (
                <Row>
                  <div>
                    <Alert color='warning'>
                      <FontAwesomeIcon icon={faTriangleExclamation} />{' '}
                      <span className="f-size-085">
                      <Translate contentKey="irexForsahApp.tables.institutionReport.institutionsWithouData" />{' '}
                        <strong> { institutionsSelectedWithoutData } </strong>
                      </span>
                    </Alert>
                  </div>
                </Row>
                )
              }
              

              { canBePrinted && (
                <div style={{textAlign: 'center', marginTop: 20}}>

                  <Row><span><Translate contentKey="irexForsahApp.tables.institutionReport.clickToDownload" /> </span></Row>
                  
                  <Button color="primary" onClick={printPDF} style={{marginTop: 5}} disabled={preparingPdf}>              
                    {
                      preparingPdf ? ( <Translate contentKey="irexForsahApp.tables.institutionReport.preparingPdf" /> ) : (
                        <>
                          <FontAwesomeIcon icon={faDownload} /> {' '}
                          <Translate contentKey="irexForsahApp.tables.institutionReport.download" />
                        </>
                      )
                    }
                  </Button>
                </div>
              ) }

            </CardBody>
          </Card>



          
        )}

        
        
      </div>
    </div>
  );
};

export default AssessmentReport;
