import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IFilter} from "app/shared/model/forsah-filter.model";


const filterState: IFilter = {
  loading: false,
  errorMessage: null,
  selectedInstitutions: [],
  selectedYears: [],
  institutions: [],
  years: [],
  loginName: null
}

const apiURL = 'api/filter';

export const getFilter = createAsyncThunk(
  'data/fetch',
  async (params: { userLogin: string, useDefaults: boolean }) => {
    const response = await axios.post<IFilter>(`${apiURL}/all-by-user`, params);
    return response.data;
  }
);

export const getAvailableYearsByInstitution = createAsyncThunk(
  'surveyToDo/fetch_available_years_by_institution',
  async (institutionId: string | number ) => {    
    const requestUrl = `${apiURL}/available-years-by-institution/${institutionId}`;
    return axios.get<any[]>(requestUrl);
  }
);

export const filterSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(getFilter.fulfilled, (state, action) => {
        state.loading = false;
        state.institutions = action.payload.institutions;
        state.years = action.payload.years;
        state.selectedInstitutions = action.payload.selectedInstitutions;
        state.selectedYears = action.payload.selectedYears;
      })
      .addCase(getFilter.pending, state => {
        state.loading = true;
      })
      .addCase(getFilter.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      }).addCase(getAvailableYearsByInstitution.fulfilled, (state, action) => {
        state.loading = false;
        state.years = action.payload.data;
      })
      .addCase(getAvailableYearsByInstitution.pending, state => {
        state.loading = true;
      })
      .addCase(getAvailableYearsByInstitution.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
  initialState: filterState,
  name: 'filter',
  reducers: {
    reset() {
      return filterState;
    },
  },
});

export default filterSlice.reducer;
