import axios from 'axios';
import {serializeAxiosError, EntityState} from "app/shared/reducers/reducer.utils";
import {IDataValidation, defaultValue} from "app/shared/model/dataValidation.model";
import {createAsyncThunk, createSlice, createAction} from '@reduxjs/toolkit';
import { IResponseObject } from 'app/shared/model/response-object.model';


interface ExtendedEntityState<T> extends EntityState<T> {
  tempViewLink: string;
  loadingLink: boolean;
  linkSuccess: boolean;
  message: string;
}

const initialStateDataValidation: ExtendedEntityState<IDataValidation> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  tempViewLink: '',
  loadingLink: false,
  linkSuccess: false,
  message: '',
};

const apiSToDoUrl = 'api/survey-to-dos';
const apiRepliesUrl = 'api/replies';

export const getRepliesToReview = createAsyncThunk<IDataValidation[], { pYearTodo?: number | null, pDateFrom?: string | null, pDateTo?: string | null, pStates?: string | null }>(
  'dataValidation/fetchRepliesToReview',
  async ({ pYearTodo = null, pDateFrom, pDateTo, pStates = '' }) => {
    let requestUrl = `${apiSToDoUrl}/all-to-review?`;

    if (pYearTodo !== null) {
      requestUrl += `todoYear=${pYearTodo}&`;
    }
    if (pDateFrom !== null) {
      requestUrl += `replyDateFrom=${pDateFrom}&`;
    }
    if (pDateTo !== null) {
      requestUrl += `replyDateTo=${pDateTo}&`;
    }
    if (pStates !== null) {
      requestUrl += `states=${pStates}&`;
    }

    // Remove trailing '&' or '?' if no parameters were added
    requestUrl = requestUrl.endsWith('&') || requestUrl.endsWith('?') ? requestUrl.slice(0, -1) : requestUrl;

    const response = await axios.get<IDataValidation[]>(requestUrl);
    return response.data;
  },
  { serializeError: serializeAxiosError },
)

export const getTempViewLink = createAsyncThunk<string, {surveyKoboId: string, replyKoboId: number}>(
  'dataValidation/fetchTempViewLink',
  async ({surveyKoboId, replyKoboId}) => {
    const requestUrl = `${apiRepliesUrl}/temp-view-link/${replyKoboId}/${surveyKoboId}`;
    const response = await axios.get<string>(requestUrl);
    return response.data;
  },
  {serializeError: serializeAxiosError},
);

export const approveReply = createAsyncThunk<IResponseObject, {todoId: number, reviewComment: string}>(
  'dataValidation/approveReply',
  async ({reviewComment, todoId}) => {
    const requestUrl = `${apiSToDoUrl}/approve/${todoId}`;
    const body = {reviewComment};
    const response = await axios.patch<IResponseObject>(requestUrl, body);
    return response.data;
  },
  {serializeError: serializeAxiosError},
);

export const rejectReply = createAsyncThunk<IResponseObject, {todoId: number, reviewComment: string}>(
  'dataValidation/rejectReply',
  async ({reviewComment, todoId}) => {
    const requestUrl = `${apiSToDoUrl}/reject/${todoId}`;
    const body = {reviewComment};
    const response = await axios.patch<IResponseObject>(requestUrl, body);
    return response.data;
  },
  {serializeError: serializeAxiosError},
);

export const setTempViewLink = createAction<string>('dataValidation/setTempViewLink');


export const dataValidationSlice = createSlice({
  extraReducers(builder) {
    builder
      // getRepliesToReview
      .addCase(getRepliesToReview.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = []; // clear the array
        state.entities = Array.isArray(action.payload) ? action.payload : [action.payload];
      })
      .addCase(getRepliesToReview.pending, state => {
        state.loading = true;
      })
      .addCase(getRepliesToReview.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })

      // getTempViewLink
      .addCase(getTempViewLink.fulfilled, (state, action) => {
        state.loadingLink = false;
        state.linkSuccess = true;
        state.tempViewLink = action.payload;
      })
      .addCase(getTempViewLink.pending, state => {
        state.tempViewLink = '';
        state.linkSuccess = false;
        state.loadingLink = true;
      })
      .addCase(getTempViewLink.rejected, (state, action) => {
        state.tempViewLink = '';
        state.loadingLink = false;
        state.linkSuccess = false;
        state.errorMessage = action.error.message;
      })
      .addCase(setTempViewLink, (state, action) => {
        state.tempViewLink = action.payload;
      })

      // approveReply
      .addCase(approveReply.fulfilled, (state, action) => {
        const response = action.payload;
        state.updating = false;

        if (response.isError) {
          state.updateSuccess = false;
          state.errorMessage = response.message;
          state.message = undefined;
        } else {
          state.updateSuccess = true;
          state.message = response.message;
          state.errorMessage = undefined;
        }
      })
      .addCase(approveReply.pending, state => {
        state.updating = true;
      })
      .addCase(approveReply.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })

      // rejectReply
      .addCase(rejectReply.fulfilled, (state, action) => {
        const response = action.payload;
        state.updating = false;

        if (response.isError) {
          state.updateSuccess = false;
          state.errorMessage = response.message;
          state.message = undefined;
        } else {
          state.updateSuccess = true;
          state.message = response.message;
          state.errorMessage = undefined;
        }
      })
      .addCase(rejectReply.pending, state => {
        state.updating = true;
      })
      .addCase(rejectReply.rejected, (state, action) => {
        state.updating = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      })
      ;
  },
  initialState: initialStateDataValidation,
  name: 'dataValidation',
  reducers: {
    reset() {
      return initialStateDataValidation;
    },
  },
});

export default dataValidationSlice.reducer;
