// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* FilterComponent.css */
.filter-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.filter-header {
  font-size: 0.9em;
  font-weight: 600;
  margin-bottom: 5px;
}

.filter-container .select__control {
  border-color: #e1e1e1;
  border-width: 2px;
}

.filter-select {
  width: 60%;
}

[dir="ltr"] .filter-select {
  margin-right: 20px;
}

[dir="rtl"] .filter-select {
  margin-left: 20px;
}

.filter-select-small {
  width: 20%;
}

/* Apply button */
.filter-button {
  margin-top: 5px;
}

.filter-button button {
  color: #ffffff;
  height: 38px;
  line-height: 38px;
  padding-bottom: 0;
  padding-top: 0;
}

.filter-button button:hover {
  background-color: #0a2c2d !important;
  border: 1px solid #0a2c2d !important;
}`, "",{"version":3,"sources":["webpack://./src/main/webapp/app/shared/filter/filter.css"],"names":[],"mappings":"AAAA,wBAAA;AAEA;EACE,aAAA;EACA,2BAAA;EACA,qBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,gBAAA;EACA,kBAAA;AAAF;;AAGA;EACC,qBAAA;EACA,iBAAA;AAAD;;AAGA;EAEE,UAAA;AAAF;;AAFA;EACC,kBAAA;AACD;;AAFA;EACC,iBAAA;AACD;;AAGA;EACE,UAAA;AAAF;;AAGA,iBAAA;AACA;EACC,eAAA;AAAD;;AAGA;EACC,cAAA;EACA,YAAA;EACA,iBAAA;EACA,iBAAA;EACA,cAAA;AAAD;;AAGA;EACC,oCAAA;EACA,oCAAA;AAAD","sourcesContent":["/* FilterComponent.css */\n\n.filter-container {\n  display: flex;\n  justify-content: flex-start;\n  align-items: flex-end;\n}\n\n.filter-header {\n  font-size: 0.9em;\n  font-weight: 600;\n  margin-bottom: 5px;\n}\n\n.filter-container .select__control{\n\tborder-color: #e1e1e1;\n\tborder-width: 2px;\n}\n\n.filter-select {\n\tmargin-right: 20px;\n  width: 60%;\n}\n\n.filter-select-small {\n  width: 20%;\n}\n\n/* Apply button */\n.filter-button{\n\tmargin-top: 5px;\n}\n\n.filter-button button{\n\tcolor: #ffffff;\n\theight: 38px;\n\tline-height: 38px;\n\tpadding-bottom: 0;\n\tpadding-top: 0;\n}\n\n.filter-button button:hover{\n\tbackground-color: #0a2c2d !important;\n\tborder: 1px solid #0a2c2d !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
