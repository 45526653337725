import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { EntityState } from 'app/shared/reducers/reducer.utils';
import { ITableBandingCriteria } from 'app/shared/model/tables_visualization/table-banding-criteria.model';


const initialState: EntityState<ITableBandingCriteria> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: {},
  updating: false,
  updateSuccess: false
};

const apiUrl = 'api/data-visualization/table';

export const getBandingCriteria = createAsyncThunk(
  'charts/fetch_banding_criteria',
  async () => {
    return axios.get<ITableBandingCriteria[]>(`${apiUrl}/banding-criteria`);
  }
);

export const tableBandingCriteriaSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(getBandingCriteria.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(getBandingCriteria.pending, state => {
        state.loading = true;
      })
      .addCase(getBandingCriteria.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
  initialState,
  name: 'tableDataVisualization-bandingCriteria',
  reducers: {
    reset() {
      return initialState;
    },
  },
});

export default tableBandingCriteriaSlice.reducer;
