import { ISurvey } from 'app/shared/model/survey.model';
import { IQuestionOption } from 'app/shared/model/question-option.model';

export interface IQuestion {
  id?: number;
  question?: string;
  description?: string | null;
  koboXPath?: string | null;
  koboName?: string | null;
  survey?: ISurvey;
  questionOptions?: IQuestionOption[] | null;
}

export const defaultValue: Readonly<IQuestion> = {};
