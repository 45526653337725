import React from 'react';

interface LoadingSpinnerProps {
  message?: string;
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ message = 'Loading...' }) => (
  <div className="loading-overlay">
    <div className="loading-spinner">
      <div className="spinner"></div>
      <h4>{message}</h4>
    </div>
  </div>
);

export default LoadingSpinner;

