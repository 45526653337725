
export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  TVET_USER: 'ROLE_TVET_USER',
  TVET_ADMIN: 'ROLE_TVET_ADMIN',
  IREX_USER: 'ROLE_IREX_USER',
  IREX_ADMIN: 'ROLE_IREX_ADMIN',
  VALIDATOR: 'ROLE_VALIDATOR'
};

export const SURVEYTODO_STATUS = {
  PENDING: 'pending',
  ANSWERED: 'answered',
  REJECTED: 'rejected',
  RE_ANSWERED: 'reAnswered',
  APPROVED: 'approved'
};

export const SURVEY_STATUS_COLORS = {
  pending: "pending",
  answered: "answered",
  reAnswered: "reanswered",
  approved: "approved",
  rejected: "rejected"
};


export const SURVEY_STATUS_COLORS_VALIDATION = {
  answered: "pending",
  reAnswered: "pending",
  approved: "approved",
  rejected: "rejected"
};

export const SURVEY_TYPE = {
  DATA_COLLECTION: 'data_collection',
  DATA_ANALYSIS_SCORING: 'data_analysis'
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'DD/MM/YY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';


export const SYNC_CONFIG_TYPE = {
  SURVEYS_SYNC_PROCESS: "surveys-sync-process",
  REPLIES_SYNC_PROCESS: "replies-sync-process"
};


export const SURVEY_ISNOT_DELETEABLE_REASON = {
  HAVE_REPLIES: 1,
  HAVE_TODOS_NOT_PENDING: 2,
  HAVE_TODOS_PENDING: 3
};

export const COLOR_PALLETE = {
  USAID_BLUE: '#002F6C',
  USAID_RED: '#BA0C2F',
  RICH_BLACK: '#212721',
  MEDIUM_BLUE: '#0067B9',
  LIGHT_BLUE: '#A7C6ED',
  WEB_BLUE: '#205493',
  DARK_RED: '#651D32',
  DARK_GRAY: '#6C6463',
  MEDIUM_GRAY: '#8C8985',
  LIGHT_GRAY: '#CFCDC9'
};
