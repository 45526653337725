import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { login } from 'app/shared/reducers/authentication';
import LoginForm from './login-form';

class ILoginSectionProps {
  loginError: boolean;
  loginLoading: boolean;
}

export const LoginSection = (props: ILoginSectionProps) => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const pageLocation = useLocation();

  const {loginError, loginLoading} = props;

  const handleLogin = (username: string, password: string, rememberMe = false) => dispatch(login(username, password, rememberMe));

  const { from } = pageLocation.state || { from: { pathname: '/', search: pageLocation.search } };
  if (isAuthenticated) {
    return <Navigate to={from} replace />;
  }
  return (
    <div id="login-section">
      <LoginForm handleLogin={handleLogin} loginError={loginError} loginLoading={loginLoading}/>
    </div>
  );
};

export default LoginSection;
