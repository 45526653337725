import { IInstitution } from 'app/shared/model/institution.model';

export interface ISurvey {
  id?: number;
  idCode?: string;
  publicLink?: string | null;
  name?: string | null;
  type?: string | null;
  activated?: boolean | null;
  sectionNumber?: number | null;
  institutions?: IInstitution[] | null;
}

export const defaultValue: Readonly<ISurvey> = {};
