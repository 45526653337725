import { ISurvey } from 'app/shared/model/survey.model';
import { IInstitution } from 'app/shared/model/institution.model';
import { IReply } from 'app/shared/model/reply.model';

export interface ISurveyToDo {
  id?: number;
  status?: string;
  link?: string | null;
  locatorId?: number;
  comment?: string | null;
  survey?: ISurvey | null;
  institution?: IInstitution | null;
  reply?: IReply | null;
}

export const defaultValue: Readonly<ISurveyToDo> = {};
