export const fontsObject = {
    GillSans: {
      normal: 'https://fonts.cdnfonts.com/s/58185/Gill Sans.woff',
      condensed: 'https://fonts.cdnfonts.com/s/58185/GillSans Condensed.woff',
      italics: 'https://fonts.cdnfonts.com/s/58185/Gill Sans Italic.woff',
      light: 'https://fonts.cdnfonts.com/s/58185/Gill Sans Light.woff',
      lightitalic: 'https://fonts.cdnfonts.com/s/58185/Gill Sans Light Italic.woff',
      bold: 'https://fonts.cdnfonts.com/s/58185/Gill Sans Bold.woff',
      bolditalics: 'https://fonts.cdnfonts.com/s/58185/Gill Sans Bold Italic.woff'
    },
 
    // default Roboto font from cdnjs.com
    Roboto: {
      normal: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Regular.ttf',
      bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
      italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
      bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
 };


 export const loadSVG = async (svgPath: string): Promise<string> => {
    const response = await fetch(svgPath);
    const svgText = await response.text();
    return svgText;
};

 export function getBase64Image(imgPath: string): Promise<string> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.src = imgPath;
        img.crossOrigin = 'Anonymous';
        
        img.onload = () => {
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            if (ctx) {
                ctx.drawImage(img, 0, 0);
                const dataURL = canvas.toDataURL('image/jpeg');
                resolve(dataURL.replace(/^data:image\/(png|jpg|jpeg);base64,/, ''));
            } else {
                reject('2D context could not be obtained');
            }
        };
        
        img.onerror = (error) => {
            reject(error);
        };
    });
}

export const getPdfContentWithCoverPage = (
    reportTitle: string,
    logoUsaid: string, 
    logoIrex: string,
    logoDai:string,
    logoVATech:string,
    logoDG:string,
    logoPartner4:string,
    logoPartner5:string,
    logoPartner6:string
    ) => {
        const pdfContent = [];
        pdfContent.push({ text: 'FORSAH Technical and Vocational Capacity Assessment Tool (TVCAT)', style: 'institutionName', alignment: 'center', margin: [0, 40, 0, 0] });


        // Main Logos
        pdfContent.push({ 
        columns: [
            { svg: logoUsaid, width: 180 },
            { svg: logoIrex, width: 110, margin: [0, 4, 0, 0] }
        ],
        columnGap: 40,
        margin: [210, 30, 0, 0],
        alignment: 'center'
        });

        pdfContent.push({ text: reportTitle, style: 'coverPageSub', margin: [0, 80, 0, 0] });

        pdfContent.push({ text: 'Partners', style: 'coverPageSub2', margin: [0, 110, 0, 0] });
        
        // Partners logos
        pdfContent.push({ 
        columns: [
            { svg: logoDai, width: 70, margin: [0, 15, 0, 0] },
            { svg: logoVATech, width: 70, margin: [0, 15, 0, 0] },
            { svg: logoDG, width: 70, margin: [0, 15, 0, 0] },
            { image: logoPartner4, width: 70 },
            { image: logoPartner5, width: 70 },
            { image: logoPartner6, width: 70, margin: [0, 7, 0, 0] }
        ],
        columnGap: 20,
        margin: [130, 5, 0, 0],
        alignment: 'center'
        });

        pdfContent.push({ 
        text: 'This report is made possible by the support of the American People through the United States Agency for International Development (USAID). The contents of this report are the sole responsibility of IREX and do not necessarily reflect the views of USAID or the United States Government.', 
        style: 'coverDisclaimer'
        }); 

        return pdfContent;
    };