import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import { Translate, getSortState, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faCheckSquare, faLightbulb, faShield } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, SORT } from 'app/shared/util/pagination.constants';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntitiesToListPage, checkIsDeleatable, deleteEntity } from './institution.reducer';
import {hasAnyAuthority} from "app/shared/auth/private-route";
import {AUTHORITIES} from "app/config/constants";

export const Institution = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));

  const institutionList = useAppSelector(state => state.institution.entities);
  const loading = useAppSelector(state => state.institution.loading);
  const account = useAppSelector(state => state.authentication.account);

  const institutionDeleteable = useAppSelector(state => state.institution.isDeleteableResponse);

  const {from} = pageLocation.state || {from: {pathname: '/', search: pageLocation.search}};
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities,
    [AUTHORITIES.ADMIN, AUTHORITIES.IREX_ADMIN]));
  const isTVETAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities,
    [AUTHORITIES.TVET_ADMIN]));
  const isUser = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities,
    [AUTHORITIES.USER]));

  useEffect(() => {
    getAllEntities()
  }, [account]);

  const getAllEntities = () => {
    if (account && account.login) {
      dispatch(getEntitiesToListPage(account.login));
    }
  };

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    getAllEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const navigateTo = (value) => {
    navigate(value);
  };

   // #region Delete Survey
   const [institutionSelectedToDelete, setInstitutionSelectedToDelete] = useState(null);
   const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
   const [modalAreYouSure, setModalAreYouSure] = useState('');
   const [modalHaveItemsLinked, setModalHaveItemsLinked] = useState(''); // modalToDosPending
 
   const checkIsDeletable = (institution) => {
     setModalAreYouSure('');
     setModalHaveItemsLinked('');
     setInstitutionSelectedToDelete(institution);
     dispatch(checkIsDeleatable(institution.id));
   }
 
   useEffect(() => {
     if (institutionDeleteable && institutionSelectedToDelete) {
        if (!institutionDeleteable.isDeleteable) {
          let usersLinked = "";
          if (institutionDeleteable.users.length > 0) {
            institutionDeleteable.users.forEach((user) => {
              usersLinked += `<br /> - ${user}`;
            });
          }

          let surveysLinked = "";
          if (institutionDeleteable.surveys.length > 0) {
            surveysLinked += "<br />";
            institutionDeleteable.surveys.forEach((survey) => {
              surveysLinked += `[${survey}]  `;
            });
          }
 
          let translation = translate(`irexForsahApp.institution.deleteModal.hasLinkedItems`);
          let message = translation.props.dangerouslySetInnerHTML.__html;
          if (usersLinked !== "") {
            translation = translate(`irexForsahApp.institution.deleteModal.hasUsers`, { users: usersLinked });
            message += translation.props.dangerouslySetInnerHTML.__html;
          }
          if (surveysLinked !== "") {
            translation = translate(`irexForsahApp.institution.deleteModal.hasSurveys`, { surveys: surveysLinked });
            message += translation.props.dangerouslySetInnerHTML.__html;
          }
          if (institutionDeleteable.repliesCount > 0) {
            translation = translate(`irexForsahApp.institution.deleteModal.hasReplies`, { repliesCount: institutionDeleteable.repliesCount });
            message += translation.props.dangerouslySetInnerHTML.__html;
          }
          if (institutionDeleteable.surveysToDoCount > 0) {
            translation = translate(`irexForsahApp.institution.deleteModal.hasSurveysToDo`, { surveysToDoCount: institutionDeleteable.surveysToDoCount });
            message += translation.props.dangerouslySetInnerHTML.__html;
          }
          translation = translate(`irexForsahApp.institution.deleteModal.questionWantDelete`);
          message += translation;

          setModalHaveItemsLinked(message);
        }
 
        const institutionName = institutionSelectedToDelete.name ? institutionSelectedToDelete.name : '';
        const areYouSureHTML = translate(`irexForsahApp.institution.deleteModal.areYouSure`, { institutionName });
        setModalAreYouSure(areYouSureHTML.props.dangerouslySetInnerHTML.__html);
        setDeleteModalIsOpen(true);       
     }
   }, [institutionDeleteable]);
 
   const handleCloseDeleteModal = () => {
     setModalAreYouSure('');
     setModalHaveItemsLinked('');
     setInstitutionSelectedToDelete(null);
     setDeleteModalIsOpen(false);
   }
 
   const confirmDelete = () => {
     dispatch(deleteEntity({id: institutionSelectedToDelete.id, login: account.login}));
     setInstitutionSelectedToDelete(null);
     setDeleteModalIsOpen(false);
   }
 
   // #endregion

  return (
    <div>
      <h1 className="bordered" id="institution-heading" data-cy="InstitutionHeading">
        <Translate contentKey="irexForsahApp.institution.home.title"/>
      </h1>
      <div className="spacer20"/>
      <div className="d-flex justify-content-end">
        <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
          <FontAwesomeIcon icon="sync" spin={loading} />{' '}
          <Translate contentKey="irexForsahApp.institution.home.refreshListLabel">Refresh List</Translate>
        </Button>
        {  isAdmin &&
          <Link to="/institution/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="irexForsahApp.institution.home.createLabel">Create new Institution</Translate>
          </Link>
        }

      </div>

      <div className="table-responsive">
        {institutionList && institutionList.length > 0 ? (
          <Table responsive>
            <thead>
            <tr>
              {/* <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="irexForsahApp.institution.id">ID</Translate>{' '}
                  <FontAwesomeIcon icon={getSortIconByFieldName('id')} />
                </th> */}
              <th >
                <Translate contentKey="irexForsahApp.institution.logo">Logo</Translate>
              </th>
              <th className="hand" onClick={sort('name')}>
                <Translate contentKey="irexForsahApp.institution.name">Name</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('name')}/>
              </th>
              <th className="hand" onClick={sort('acronym')}>
                <Translate contentKey="irexForsahApp.institution.acronym">Name</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('acronym')}/>
              </th>
              <th className="hand">
                <Translate contentKey="irexForsahApp.institution.region">Region</Translate>{' '}
              </th>
              <th className="hand" onClick={sort('comment')}>
                <Translate contentKey="irexForsahApp.institution.commentTable">Comment</Translate>{' '}
                <FontAwesomeIcon icon={getSortIconByFieldName('comment')}/>
              </th>
              <th/>
            </tr>
            </thead>
            <tbody>
            {institutionList.map((institution, i) => (
              <tr key={`entity-${i}`} data-cy="entityTable">
                  <td className="image-cell">
                    <div className="table-image-container">
                      {institution.logo ? (
                          <img src={`data:image/png;base64,${institution.logo}`} alt={translate('irexForsahApp.institution.logo')}/>
                      ) : (
                          <img src='/content/images/NoImage.webp' alt={translate('irexForsahApp.institution.noImage')}/>
                      )}
                    </div>
                  </td>
                  <td className='background-f5'>{institution.name}</td>
                  <td>{institution.acronym}</td>
                  <td className='background-f5'>{institution.regionName}{institution.governorateName ? ' | ' + institution.governorateName : ''}</td>
                  <td>{institution.comment}</td>

                  <td className="table-buttons-column background-f2">

                    <div className="table-buttons-wrapper">
                    <span className='pointer view' onClick={() => navigateTo(`/usersHome/institution/${institution.id}`)}>
                      <img src="/content/images/icons/eye-icon.svg" alt="view icon"/>
                      <Translate contentKey="entity.action.view">View</Translate>
                    </span>

                    <span className='pointer edit' onClick={() => navigateTo(`/institution/${institution.id}/edit`)}>
                      <img src="/content/images/icons/pencil-icon.svg" alt="edit icon"/>
                      <Translate contentKey="entity.action.edit">Edit</Translate>
                    </span>

                    <span className='pointer todo' onClick={() => navigateTo(`/usersHome/institution/${institution.id}/survey-to-dos`)}>
                      <img src="/content/images/icons/list-icon.svg" alt="SurveysToDo icon"/>
                      <Translate contentKey="irexForsahApp.institution.buttonSurveyToDo">Survey ToDos</Translate>
                    </span>

                    {isAdmin &&
                      <span className='pointer delete' onClick={() => checkIsDeletable(institution)}>
                        <img src="/content/images/icons/times-circle-icon.svg" alt="delete icon"/>
                        <Translate contentKey="entity.action.delete">Delete</Translate>
                      </span>
                    }

                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="irexForsahApp.institution.home.notFound">No Institutions found</Translate>
            </div>
          )
        )}
      </div>


      <Modal isOpen={deleteModalIsOpen} backdrop={true} size='lg'>
        <ModalHeader className='text-rejected'>
          <span className="status rejected" /> {' '}
          <Translate contentKey="irexForsahApp.institution.deleteModal.title"/>
        </ModalHeader>
        <ModalBody>
          <p><Label dangerouslySetInnerHTML={{ __html: modalAreYouSure }} /></p>
          {
            modalHaveItemsLinked && modalHaveItemsLinked.length > 0 &&
            <>
              <Alert color="secondary">
                <div dangerouslySetInnerHTML={{ __html: modalHaveItemsLinked }} />
              </Alert>

              <Alert color='info' className='f-size-08' style={{marginBottom: 0}}>
                <FontAwesomeIcon icon={faShield} />{' '}
                <Translate contentKey="irexForsahApp.institution.deleteModal.isSafe"/>
              </Alert>
            </>
          }
        </ModalBody>

        <ModalFooter className="justify-content-between">
          <Button color="danger" onClick={confirmDelete}>
            <Translate contentKey='irexForsahApp.institution.deleteModal.wantDelete'/>
          </Button>

          <Button color="secondary" onClick={handleCloseDeleteModal}>
            <Translate contentKey="irexForsahApp.institution.cancel"/>
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

export default Institution;
