import { translate } from 'react-jhipster';
import { SURVEYTODO_STATUS, AUTHORITIES, SURVEY_TYPE } from 'app/config/constants';

export const getSurveyTodoStatusDisplay = (key: string): string => {
  switch (key) {
    case SURVEYTODO_STATUS.PENDING:
    case SURVEYTODO_STATUS.ANSWERED:
    case SURVEYTODO_STATUS.REJECTED:
    case SURVEYTODO_STATUS.RE_ANSWERED:
    case SURVEYTODO_STATUS.APPROVED:
      return translate(`global.constants.SURVEYTODO_STATUS_DISPLAY.${key}`);
    default:
      return '';
  }
};

export const getSurveyTodoStatusDisplayValidation = (key: string): string => {
  switch (key) {
    case SURVEYTODO_STATUS.ANSWERED:
    case SURVEYTODO_STATUS.REJECTED:
    case SURVEYTODO_STATUS.RE_ANSWERED:
    case SURVEYTODO_STATUS.APPROVED:
      return translate(`global.constants.SURVEYTODO_STATUS_DISPLAY_VALIDATION.${key}`);
    default:
      return '';
  }
};

export const getRoleDisplayName = (key: string): string => {
    switch (key) {
        case AUTHORITIES.ADMIN:
            return translate('global.constants.ROLE_DISPLAY_NAMES.ROLE_ADMIN');
        case AUTHORITIES.USER:
            return translate('global.constants.ROLE_DISPLAY_NAMES.ROLE_USER');
        case AUTHORITIES.TVET_USER:
            return translate('global.constants.ROLE_DISPLAY_NAMES.ROLE_TVET_USER');
        case AUTHORITIES.TVET_ADMIN:
            return translate('global.constants.ROLE_DISPLAY_NAMES.ROLE_TVET_ADMIN');
        case AUTHORITIES.IREX_USER:
            return translate('global.constants.ROLE_DISPLAY_NAMES.ROLE_IREX_USER');
        case AUTHORITIES.IREX_ADMIN:
            return translate('global.constants.ROLE_DISPLAY_NAMES.ROLE_IREX_ADMIN');
        case AUTHORITIES.VALIDATOR:
            return translate('global.constants.ROLE_DISPLAY_NAMES.ROLE_VALIDATOR');
        default:
            return '';
    }
};

export const getSurveyTypeDisplay = (key: string): string => {
    switch (key) {
        case SURVEY_TYPE.DATA_COLLECTION:
            return translate('global.constants.SURVEY_TYPE_DISPLAY.data_collection');
        case SURVEY_TYPE.DATA_ANALYSIS_SCORING:
            return translate('global.constants.SURVEY_TYPE_DISPLAY.data_analysis');
        default:
            return '';
    }
};
