import { IReply } from 'app/shared/model/reply.model';
import { IQuestion } from 'app/shared/model/question.model';

export interface IReplyDetails {
  id?: number;
  replyTxt?: string | null;
  questionXPath?: string | null;
  reply?: IReply | null;
  question?: IQuestion | null;
}

export const defaultValue: Readonly<IReplyDetails> = {};
