import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPersonRunning, faBed } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { Translate, translate } from "react-jhipster";
import { Button, Col, Row  } from "reactstrap";
import { getCurrentStatus, updateServiceStatus } from "./sync-configuration-reducer";
import { useAppDispatch, useAppSelector } from "app/config/store";
import { ISyncConfiguration } from "app/shared/model/sync-configuration.model";
import LoadingSpinner from "app/shared/components/LoadingSpinner";
import { SYNC_CONFIG_TYPE } from "app/config/constants";


const StatusComponent = (props) => {
  return (
    <>
      <h3 className="arrow">{props.title}</h3>
      <Row className="justify-content-center">

      { props.isRunning ? (
          <>
            <FontAwesomeIcon icon={faPersonRunning} size="3x" style={{color: '#19494b'}} bounce/> <br />
            <strong className="justify-content-center row">
              {translate('irexForsahApp.syncConfiguration.status.running')}
            </strong> <br />
            <Button color="primary" type="button" style={{marginTop: 10}} onClick={() => props.toggleStatus(props.type, false)}>
              <Translate contentKey="irexForsahApp.syncConfiguration.buttons.turn-off"> Turn Off </Translate>
            </Button>
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faBed} size="3x" style={{color: '#b5b5b5'}}/> <br />
            <em className="justify-content-center row">
              {translate('irexForsahApp.syncConfiguration.status.paused')}
            </em><br />
            <Button color="primary" type="button" style={{marginTop: 10}} onClick={() => props.toggleStatus(props.type, true)}>
              <Translate contentKey="irexForsahApp.syncConfiguration.buttons.turn-on"> Turn On </Translate>
            </Button>
          </>
        )
      }
      </Row>
      </>
    
  );
}

export const SyncConfiguration = () => {

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getCurrentStatus());
  } , []);

  const currentStatus = useAppSelector<ISyncConfiguration>(state => state.syncConfigurationReducer.entity);
  const loading = useAppSelector<boolean>(state => state.syncConfigurationReducer.loading);

  const handleToggleStatus = (serviceName, status) => {
    dispatch(updateServiceStatus({serviceName, status}));
  }
  
  
  return (
    <div>
      <Row className="justify-content-center admin-wrapper">
        <Col md="10">
          <h1 className="bordered" id="settings-title">
            <Translate contentKey="irexForsahApp.syncConfiguration.title" >
              Syncrhonization status
            </Translate>            
          </h1>
        
          { loading ? ( <LoadingSpinner /> ) :
           (
            <>
              { currentStatus && (
                <>
                  <div className="spacer20" />
                  
                  <Row>
                    <Col md="3">
                      <StatusComponent 
                        title={translate('irexForsahApp.syncConfiguration.sub-title.surveys')} 
                        isRunning={currentStatus.surveysSyncStatus}
                        type={SYNC_CONFIG_TYPE.SURVEYS_SYNC_PROCESS} 
                        toggleStatus={handleToggleStatus} />
                    </Col>
        
                    <Col md="1"></Col>
        
                    <Col md="3">
                      <StatusComponent 
                        title={translate('irexForsahApp.syncConfiguration.sub-title.replies')} 
                        isRunning={currentStatus.repliesSyncStatus}
                        type={SYNC_CONFIG_TYPE.REPLIES_SYNC_PROCESS}
                        toggleStatus={handleToggleStatus} />
                    </Col>
                  </Row> 
                </>
              )}
            </>
          )}


          

        </Col>
      </Row>
      
    </div>

  );
};

export default SyncConfiguration;