import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { ASC } from 'app/shared/util/pagination.constants';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { IInstitution, defaultValue } from 'app/shared/model/institution.model';
import { IInstitutionItemList } from 'app/shared/model/institutionItemList.model';
import { IInstitutionDeleteableResponse } from 'app/shared/model/institution-deleteable-response.model';

interface ExtendedEntityState<T> extends EntityState<T> {
  institutionsByUser: ReadonlyArray<T>;
  institutionsByUserLoading: boolean;
  isDeleteableResponse?: IInstitutionDeleteableResponse;
}

const initialState: ExtendedEntityState<IInstitution> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  institutionsByUser: [],
  institutionsByUserLoading: false
};

const apiUrl = 'api/institutions';
let userLogin = '';

// Actions

export const getEntities = createAsyncThunk(
  'institution/fetch_entity_list_by_userRol', 
  async (login: string) => {
    userLogin = login;
    const requestUrl = `${apiUrl}/all-by-user-rol/${login}`;
    return axios.get<IInstitution[]>(requestUrl);
  });

  
  export const getEntitiesToListBox = createAsyncThunk(
    'institution/fetch_entity_list_by_userRol_to_listbox', 
    async (login: string) => {
      userLogin = login;
      const requestUrl = `${apiUrl}/all-by-user-rol/${login}`;
      return axios.get<IInstitution[]>(requestUrl);
  });

  
  export const getEntitiesToListPage = createAsyncThunk(
    'institution/fetch_entity_list_by_userRol_to_listpage', 
    async (login: string) => {
      userLogin = login;
      const requestUrl = `${apiUrl}/all-by-user-rol-to-list/${login}`;
      return axios.get<IInstitutionItemList[]>(requestUrl);
  });

export const getEntitiesByUser = createAsyncThunk(
  'institution/fetch_entity_list_by_username', 
  async (login: string) => {
    const requestUrl = `${apiUrl}/all-by-user/${login}`;
    return axios.get<IInstitution[]>(requestUrl);
  });

export const getEntity = createAsyncThunk(
  'institution/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<IInstitution>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const createEntity = createAsyncThunk(
  'institution/create_entity',
  async ({ entity, logoFile }: { entity: IInstitution; logoFile?: File }, thunkAPI) => {
    const formData = new FormData();
    formData.append('institution', new Blob([JSON.stringify(entity)], { type: 'application/json' }));
    if (logoFile) {
      formData.append('logo', logoFile);
    }

    const result = await axios.post<IInstitution>(apiUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    thunkAPI.dispatch(getEntitiesToListPage(userLogin));
    return result;
  },
  { serializeError: serializeAxiosError },
);



export const updateEntity = createAsyncThunk(
  'institution/update_entity',
  async ({ entity, logoFile }: { entity: IInstitution; logoFile?: File }, thunkAPI) => {
    const formData = new FormData();
    formData.append('institution', new Blob([JSON.stringify(entity)], { type: 'application/json' }));
    if (logoFile) {
      formData.append('logo', logoFile);
    }

    const result = await axios.put(`${apiUrl}/${entity.id}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    thunkAPI.dispatch(getEntitiesToListPage(userLogin));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'institution/partial_update_entity',
  async (entity: IInstitution, thunkAPI) => {
    const result = await axios.patch<IInstitution>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntitiesToListPage(userLogin));
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const checkIsDeleatable = createAsyncThunk(
  'institution/check_is_deletable',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/is-deleteable/${id}`;
    return axios.get<IInstitutionDeleteableResponse>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'institution/delete_entity',
  async ({ id, login }: { id: string | number; login: string }, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<IInstitution>(requestUrl);
    thunkAPI.dispatch(getEntitiesToListPage(login));
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const InstitutionSlice = createEntitySlice({
  name: 'institution',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities, getEntitiesToListBox, getEntitiesToListPage), (state, action) => {
        return {
          ...state,
          loading: false,
          entities: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(getEntitiesByUser), (state, action) => {
        return {
          ...state,
          institutionsByUserLoading: false,
          institutionsByUser: action.payload.data,
        };
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity, getEntitiesByUser, getEntitiesToListBox, getEntitiesToListPage), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
        state.institutionsByUserLoading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isPending(checkIsDeleatable), state => {
        state.isDeleteableResponse = null;
      })
      .addMatcher(isFulfilled(checkIsDeleatable), (state, action) => {
        state.isDeleteableResponse = action.payload.data
      });
  },
});

export const { reset } = InstitutionSlice.actions;

// Reducer
export default InstitutionSlice.reducer;
