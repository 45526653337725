import React, {useEffect, useState} from 'react';
import Select from 'react-select';
import {getFilter} from './filter-component-reducer';
import './filter.css';
import {useAppDispatch, useAppSelector} from "app/config/store";
import { translate } from 'react-jhipster';

interface FilterComponentProps {
  filterState: { selectedInstitutions: number[]; selectedYears: number[]; institutions: any[]; years: any[] };
  handleFilterChange: (filter: { selectedInstitutions: number[]; selectedYears: number[] }) => void;
  maxInstitutions?: number;
  useDefaults?: boolean;
}

interface OptionType {
  value: any;
  label: any;
}

const FilterComponent: React.FC<FilterComponentProps> = ({
                                                           filterState
                                                           , handleFilterChange, maxInstitutions, useDefaults
                                                         }) => {

  const dispatch = useAppDispatch();
  const loginName = useAppSelector((state) => state.authentication.account.login);
  const {institutions = [], years = []} = filterState || {};
  const [pendingInstitutions, setPendingInstitutions] = useState<(number | string)[]>([]);
  const [pendingYears, setPendingYears] = useState<(number | string)[]>([]);
  const [selectedYears, setSelectedYears] = useState<number[]>([]);
  const [selectedInstitutions, setSelectedInstitutions] = useState<number[]>([]);

  useEffect(() => {
    dispatch(getFilter({userLogin: loginName, useDefaults}));
  }, [dispatch]);


  useEffect(() => {
    if (selectedInstitutions && selectedInstitutions.length > 0 && institutions && institutions.length > 0) {
      const selectedInstitutionObjects = selectedInstitutions.map(id => institutions.find(institution => institution.id === id));
      handleInstitutionChange(selectedInstitutionObjects.map(institution => ({
        value: institution,
        label: institution.name
      })));
    }
  }, [selectedInstitutions]);


  useEffect(() => {
    if (filterState.selectedYears && filterState.selectedYears.length > 0) {
      setSelectedYears(filterState.selectedYears);
      handleYearChange(filterState.selectedYears.map(year => ({value: year, label: year.toString()})));
    }
  }, [filterState.selectedYears]);

  useEffect(() => {
    if (filterState.selectedYears && filterState.selectedYears.length > maxInstitutions) {
      setSelectedInstitutions(filterState.selectedInstitutions.slice(0, maxInstitutions));
    } else {
      setSelectedInstitutions(filterState.selectedInstitutions);
    }
  }, [filterState.selectedInstitutions, maxInstitutions]);


  const handleInstitutionChange = (selectedOptions: OptionType[]) => {
    if (selectedOptions.some((option) => option.value.id === 'all')) {
      if (selectedOptions.length === institutions.length + 1) {
        setPendingInstitutions([]);
      } else {
        setPendingInstitutions(institutions.map(institution => institution.id));
      }
    } else {
      const newInstitutions = selectedOptions.map((option) => option.value.id);
      const uniqueInstitutions = [...new Set(newInstitutions)];
      if (!maxInstitutions || uniqueInstitutions.length <= maxInstitutions) {
        setPendingInstitutions(uniqueInstitutions);
      }
    }
  };

  const handleYearChange = (selectedOptions: any) => {
    if (selectedOptions.some((option: any) => option.value === 'all')) {
      if (selectedOptions.length === years.length + 1) {
        setPendingYears([]);
      } else {
        setPendingYears(years);
      }
    } else {
      setPendingYears(selectedOptions.map((option: any) => option.value));
    }
  };

  const handleApply = () => {
    setSelectedInstitutions(pendingInstitutions.filter(item => item !== 'all').map(item => Number(item)));
    setSelectedYears(pendingYears.filter(item => item !== 'all').map(item => Number(item)));
    handleFilterChange({
      selectedInstitutions: pendingInstitutions.filter(item => item !== 'all').map(item => Number(item)),
      selectedYears: pendingYears.filter(item => item !== 'all').map(item => Number(item))
    });
  };

  const getInstitutionsOptions = () => {
    if (maxInstitutions && maxInstitutions === 1) {
      return institutions.map(institution => ({value: institution, label: institution.name}));
    } else {
      return [
        {
          value: {id: 'all'},
          label: translate('global.selectAll')
        },
        ...institutions.map(institution => ({value: institution, label: institution.name}))
      ];
    }
    
  }

  return (
    <div>
      <div className="filter-container">
        <div className="filter-select">
          <div className="filter-header">{translate('entity.filters.institutions')}</div>
          <Select
            key={pendingInstitutions.join(',')}
            isMulti
            name="institutions"
            options={getInstitutionsOptions()}
            value={pendingInstitutions.includes('all') ? [{
              value: {id: 'all'},
              label: translate('global.selectAll')
              },
              ...institutions.map(institution => ({
                value: institution,
                label: institution.name
              }))] : pendingInstitutions.map(id => {
              const ins = institutions.find(institution => institution.id === id);
              return {
                value: {id},
                label: ins ? (ins.acronym ? ins.acronym : ins.name) : ''
              }
            })}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleInstitutionChange}
            isOptionDisabled={(option) =>
              pendingInstitutions.includes(option.value.id)
            }
          />
        </div>

        <div className="filter-select filter-select-small">
          <div className="filter-header">{translate('entity.filters.years')}</div>
          <Select
            isMulti
            name="years"
            options={[{value: 'all', label: translate('global.selectAll')}, ...years.map(year => ({
              value: year,
              label: year.toString()
            }))]}
            value={pendingYears.includes('all') ? [{
              value: 'all',
              label: translate('global.selectAll')
            }, ...years.map(year => ({value: year, label: year.toString()}))] : pendingYears.map(year => ({
              value: year,
              label: year.toString()
            }))}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleYearChange}
          />
        </div>
        <div className="filter-button">
          <button className='btn btn-primary' onClick={handleApply}>{translate('entity.filters.apply')}</button>
        </div>
      </div>
    </div>
  );
};

export default FilterComponent;
