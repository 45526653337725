import './footer.scss';

import React from 'react';
import { Translate } from 'react-jhipster';
import { Col, Row } from 'reactstrap';

const Footer = () => (
  <div className="footer page-content">
    <Row className="w-75 mx-auto">
      <Col md="6">
        <p className="pt-3">
          <Translate contentKey="footer">{new Date().getFullYear().toString()} Irex</Translate>
        </p>
      </Col>
      <Col md="6">
            <ul className="social list-unstyled">
              <li><a href="https://www.facebook.com/irexinternational" className="facebook">facebook</a></li>
              <li><a href="http://www.twitter.com/irexintl" className="twitter">twitter</a></li>
              <li><a href="http://www.linkedin.com/company/irex" className="linkedin">linkedin</a></li>
              <li><a href="http://eepurl.com/ekVOw" className="mailchimp">mailchimp</a></li></ul>
      </Col>
    </Row>
  </div>
);

export default Footer;
