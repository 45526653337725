import React, { useEffect, useMemo, useState } from "react";
import { MRT_Row, MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table'
import { getBandingCriteria } from "./table-banding-criteria-reducer";
import {Translate, translate} from 'react-jhipster';
import {useAppDispatch, useAppSelector} from "app/config/store";
import LoadingSpinner from "app/shared/components/LoadingSpinner";
import { Box } from "@mui/material";
import { Button } from "reactstrap";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ITableBandingCriteria } from "app/shared/model/tables_visualization/table-banding-criteria.model";
import { COLOR_PALLETE } from "app/config/constants";
import { fontsObject, getBase64Image, getPdfContentWithCoverPage, loadSVG } from "app/modules/visualizations/pdfResources";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


export const BandingCriteria = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.tableBandingCriteriaSlice.loading);
  const bandingCriteriaData = useAppSelector((state) => state.tableBandingCriteriaSlice.entities);

  const [bandingCriteriaRows, setBandingCriteriaRows] = useState([]);
  const [logoUsaid, setLogoUsai] = useState<string | null>(null);
  const [logoIrex, setLogoIrex] = useState<string | null>(null);
  const [logoDai, setLogoDai] = useState<string | null>(null);
  const [logoVATech, setLogoVATech] = useState<string | null>(null);
  const [logoDG, setLogoDG] = useState<string | null>(null);
  const [logoPartner4, setLogoPartner4] = useState<string | null>(null);
  const [logoPartner5, setLogoPartner5] = useState<string | null>(null);
  const [logoPartner6, setLogoPartner6] = useState<string | null>(null);

  pdfMake.fonts = fontsObject;

  const columns = useMemo(
    () => [
      {
        header: `${translate('irexForsahApp.tables.columns.section')}`,
        accessorKey: 'section',
      },
      {
        header: `${translate('irexForsahApp.tables.columns.subsection')}`,
        accessorKey: 'subSection',
      },
      {
        header: `${translate('irexForsahApp.tables.columns.criteria')}`,
        accessorKey: 'criteria',
      },
      {
        header: `${translate('irexForsahApp.tables.bandingCriteria.columns.fledgling')}`,
        accessorKey: 'fledgling',
      },
      {
        header: `${translate('irexForsahApp.tables.bandingCriteria.columns.emerging')}`,
        accessorKey: 'emerging',
      },
      {
        header: `${translate('irexForsahApp.tables.bandingCriteria.columns.consolidating')}`,
        accessorKey: 'consolidating',
      },
      {
        header: `${translate('irexForsahApp.tables.bandingCriteria.columns.sustaining')}`,
        accessorKey: 'sustaining',
      }
    ],[]
  );

  useEffect(() => {
    dispatch(getBandingCriteria());
    loadPngLogos();
    loadSvgLogos();
  }, []);

  useEffect(() => {
    if (!loading && bandingCriteriaData) {

      const rowsAux = bandingCriteriaData.map((row, index) => {
        return {
            ...row,
            section: row.section.split(':')[1].trim()
          }
      });
      setBandingCriteriaRows(rowsAux);
    }
  }, [loading, bandingCriteriaData, columns]);

  const loadSvgLogos = async () => {
    const usaidLogo = await loadSVG('content/images/usaid-logo.svg');
    setLogoUsai(usaidLogo);

    const irexLogo = await loadSVG('content/images/irex-logo.svg');
    setLogoIrex(irexLogo);

    const daiLogo = await loadSVG('content/images/dai-logo.svg');
    setLogoDai(daiLogo);

    const vaTechLogo = await loadSVG('content/images/va-tech-logo.svg');
    setLogoVATech(vaTechLogo);

    const dgLogo = await loadSVG('content/images/dg-logo.svg');
    setLogoDG(dgLogo);
  };

  const loadPngLogos = async () => {
    try {
        const base64_p4 = await getBase64Image('content/images/bwfp-logo.png');
        setLogoPartner4(`data:image/png;base64,${base64_p4}`);

        const base64_p5 = await getBase64Image('content/images/bethlehem-chamber-of-commerce.png');
        setLogoPartner5(`data:image/png;base64,${base64_p5}`);

        const base64_p6 = await getBase64Image('content/images/partner-logo.png');
        setLogoPartner6(`data:image/png;base64,${base64_p6}`);
    } catch (error) {
        console.error('Error loading image', error);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: bandingCriteriaRows ? bandingCriteriaRows : [],
    enableRowSelection: false,
    enableGrouping: true,
    enableStickyHeader: true,
    initialState: {
      // grouping: ['section', 'subSection'], //an array of columns to group by by default (can be multiple)
      pagination: { pageIndex: 0, pageSize: 20 },
    },
    muiTableContainerProps: { sx: { maxHeight: '600px' } },
    renderTopToolbarCustomActions: ({ table: tbl }) => (
      <Box sx={{ display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap', }} >
        <Button
          disabled={tbl.getPrePaginationRowModel().rows.length === 0}
          // export all rows, including from the next page, (still respects filtering and sorting)
          onClick={printAllRows}
        >
          <FontAwesomeIcon icon={faDownload} />{' '}
          <Translate contentKey="irexForsahApp.tables.buttons.exportAllRows" />
        </Button>

        <Button
          disabled={tbl.getRowModel().rows.length === 0}
          // export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => printSelectedRows(tbl.getRowModel().rows)}
        >
          <FontAwesomeIcon icon={faDownload} />{' '}
          <Translate contentKey="irexForsahApp.tables.buttons.exportPageRows" />
        </Button>
      </Box>
    )
  });




  const printAllRows = () => {
    const rows = bandingCriteriaRows;
    
    const tableData: any[] = [];
    rows.forEach((row) => {
        tableData.push({
            section: row.section,
            subSection: row.subSection,
            criteria: row.criteria,
            fledgling: row.fledgling,
            emerging: row.emerging,
            consolidating: row.consolidating,
            sustaining: row.sustaining
        });
    });

    printPDFGroupedRows(tableData);
  }

  const printSelectedRows = (rows: MRT_Row<ITableBandingCriteria>[]) => {
    const tableData: any[] = [];
    rows.forEach((row) => {
        tableData.push({
            section: row.original.section,
            subSection: row.original.subSection,
            criteria: row.original.criteria,
            fledgling: row.original.fledgling,
            emerging: row.original.emerging,
            consolidating: row.original.consolidating,
            sustaining: row.original.sustaining
        });
    });

    printPDFGroupedRows(tableData);
  }



  const printPDFGroupedRows = (tableData: any[]) => {
    const groupedData: any[] = [];
    let currentSection = '';
    let currentSubSection = '';
    const today = new Date();
    const footerDate = `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${today.getFullYear()}`; // MM-dd-yyyy


    tableData.forEach((item) => {
        if (item.section !== currentSection) {
            currentSection = item.section;
            currentSubSection = item.subSection;
            groupedData.push([
                { 
                    text: currentSection, 
                    rowSpan: tableData.filter(d => d.section === currentSection).length,
                    style: 'group1'
                }, 
                { 
                    text: currentSubSection, 
                    rowSpan: tableData.filter(d => d.section === currentSection && d.subSection === currentSubSection).length,
                    style: 'group2'
                }, 
                { text: item.criteria || '', style: 'smallText' },
                { text: item.fledgling || '', style: 'smallText' },
                { text: item.emerging || '', style: 'smallText' },
                { text: item.consolidating || '', style: 'smallText' },
                { text: item.sustaining || '', style: 'smallText' }
            ]);
        } else if (item.subSection !== currentSubSection) {
            currentSubSection = item.subSection;
            groupedData.push([
                '', 
                { 
                    text: item.subSection, 
                    rowSpan: tableData.filter(d => d.section === currentSection && d.subSection === currentSubSection).length,
                    style: 'group2'
                }, 
                { text: item.criteria || '', style: 'smallText' },
                { text: item.fledgling || '', style: 'smallText' },
                { text: item.emerging || '', style: 'smallText' },
                { text: item.consolidating || '', style: 'smallText' },
                { text: item.sustaining || '', style: 'smallText' }
            ]);
        } else {
            groupedData.push([
                '', 
                '', 
                { text: item.criteria || '', style: 'smallText' },
                { text: item.fledgling || '', style: 'smallText' },
                { text: item.emerging || '', style: 'smallText' },
                { text: item.consolidating || '', style: 'smallText' },
                { text: item.sustaining || '', style: 'smallText' }
            ]);
        }
    });

    const tableHeaders = columns.map((c, index) => ({
        text: c.header,
        style: 'header'
    }));

    const pdfContent = getPdfContentWithCoverPage('Banding Criteria', logoUsaid, logoIrex, logoDai, logoVATech, logoDG, logoPartner4, logoPartner5, logoPartner6);
    pdfContent.push({
      // layout: 'lightHorizontalLines',
      table: {
          headerRows: 1,
          widths: [80, '*', 80, '*', '*', '*', '*'],
          body: [
              tableHeaders,
              ...groupedData
          ]
      }
    });

    const docDefinition = {
        pageOrientation: 'landscape',
        pageMargins: 40,
        header(currentPage) {
          let headerResult = {};
  
          if (currentPage === 1) {
            return headerResult;
          }  
          headerResult = { text: 'Banding Criteria', alignment: 'left', margin: [40, 13, 0, 0], style: 'footer'}
  
          return headerResult;        
        },       
        footer(currentPage, pageCount) {
          if (currentPage === 1) {
            return {};
          }
  
          return {
            columns: [
              { text: footerDate, alignment: 'left', margin: [40, 13, 0, 0], style: 'footer'},
              { text: currentPage.toString() + ' / ' + pageCount, alignment: 'right', margin: [0, 13, 40, 0], style: 'footer'}
            ]
          };
        },
        content: pdfContent,
        defaultStyle: {
          font: 'GillSans'
        },
        styles: {
          institutionName: {
            fontSize: 27,
            bold: true,
            color: COLOR_PALLETE.USAID_BLUE
          },
          coverPageSub: {
            fontSize: 25,
            bold: true,
            color: COLOR_PALLETE.USAID_RED,
            alignment: 'center'
          },
          coverPageSub2: {
            fontSize: 18,
            color: COLOR_PALLETE.RICH_BLACK,
            alignment: 'center'
          },
          coverDisclaimer: {
            fontSize: 10,
            italic: true,
            color: COLOR_PALLETE.DARK_GRAY,
            alignment: 'center',
            margin: [0, 15, 0, 0]
          },  
          header: {
            fontSize: 15,
            bold: true,
            color: '#fafafa',
            fillColor: COLOR_PALLETE.USAID_BLUE,
            margin: [0, 5, 0, 5]
          },
          group1: {
            fontSize: 13,
            bold: true,
            italics: true,
            color: COLOR_PALLETE.USAID_RED
          },
          group2: {
            bold: true,
            italics: true,
            color: COLOR_PALLETE.USAID_RED
          },
          smallText: {
            fontSize: 10
          },
          footer: {
            fontSize: 7,
            bold: true,
            italics: true,
            color: COLOR_PALLETE.MEDIUM_GRAY
          },
          redBold: {
            bold: true,
            color: COLOR_PALLETE.USAID_RED
          }
        }
    };

    const formattedDate = today.toISOString().split('T')[0]; // YYYY-MM-DD
    pdfMake.createPdf(docDefinition).download(`Banding Criteria Table - ${formattedDate}.pdf`);
  }



  return (

    <div className="validation-wrapper">

      <div style={{height: '100%'}}>
        { loading ? ( <LoadingSpinner /> ) : ( <MaterialReactTable table={table} /> ) }
      </div>
    </div>
  );
};

export default BandingCriteria;
