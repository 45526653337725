import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, Alert, Card, CardBody, CardHeader, Input, Badge } from 'reactstrap';
import { Translate, ValidatedField, ValidatedForm, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning, faCheck, faTimes, faPlusSquare, faHandPointer, faSpinner, faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import Select from 'react-select';

import { getEntity as getInstitutionEntity } from '../institution.reducer';
import { updateEntity, createEntity, getEntity, reset, getAllStates, getNewLocatorId, setInstitutionId, getOpenByInstitutionAndType } from './surveyToDo.reducer';

import { getEntitiesByInstitutionAndType as getSurveys } from 'app/entities/survey/survey.reducer';
import FieldFormWithValidation from 'app/shared/components/FieldFormWithValidation';
import { SURVEYTODO_STATUS, SURVEY_TYPE } from 'app/config/constants';
import { ISurvey } from 'app/shared/model/survey.model';
import LoadingSpinner from 'app/shared/components/LoadingSpinner';
import { getSurveyTypeDisplay } from 'app/shared/util/display-constants';

export const InstitutionSurveyToDoEdit = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [locatorIdGenerated, setLocatorIdGenerated] = useState(false);
  const [institutionWithoutSurvey, setInstitutionWithoutSurvey] = useState(false);
  const [locatorId, setLocatorId] = useState(null);
  const [surveySelected, setSurveySelected] = useState<ISurvey>(null);
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState('');
  const [showGetLocatorIdFromPrevious, setShowGetLocatorIdFromPrevious] = useState(false);
  const [previousLocatorIdSelected, setPreviousLocatorIdSelected] = useState(-1);
  const [othersToDosFiltered, setOthersToDosFiltered] = useState([]);
  const [year, setYear] = useState('' + new Date().getFullYear());
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>({});

  const { id, surveyToDoId } = useParams();
  const isNew = surveyToDoId === undefined;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const surveyType = queryParams.get('surveyType');
  const surveyToDoEntity = useAppSelector(state => state.surveyToDo.entity);

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      setLocatorIdGenerated(false);
    } else {
      dispatch(getEntity(surveyToDoId));
      setLocatorIdGenerated(true);
    }
    dispatch(getInstitutionEntity(id));
    dispatch(getSurveys({ institutionId: id, type: surveyType }));
    dispatch(getAllStates());
    dispatch(setInstitutionId(id));
  }, []);

  const institutionEntity = useAppSelector(state => state.institution.entity);
  const institutionLoading = useAppSelector(state => state.institution.loading);
  const loading = useAppSelector(state => state.surveyToDo.loading);
  const updateSuccess = useAppSelector(state => state.surveyToDo.updateSuccess);
  const othersToDos = useAppSelector(state => state.surveyToDo.openEntities);
  const updating = useAppSelector(state => state.surveyToDo.updating);
  const states = useAppSelector(state => state.surveyToDo.toDoStates);
  const newLocatorIdCreated = useAppSelector(state => state.surveyToDo.newLocatorId);
  const surveys = useAppSelector(state => state.survey.entities);
  const surveysLoading = useAppSelector(state => state.survey.loading);

useEffect(() => {
    configSurveySelected();
  }, [surveys]);

  useEffect(() => {
    if (newLocatorIdCreated !== null) {
      setLocatorIdGenerated(true);
      setLocatorId(newLocatorIdCreated);
    }
  }, [newLocatorIdCreated]);


  useEffect(() => {
    if (isNew) {
      setLocatorIdGenerated(false);
      (states && states.length > 0) ? setStatus(states[0]) : setStatus(SURVEYTODO_STATUS.PENDING);
    } else {
      setLocatorId(surveyToDoEntity.locatorId);
      setYear(surveyToDoEntity.year);
      setComment(surveyToDoEntity.comment);
      setStatus(surveyToDoEntity.status);
      configSurveySelected()
    }
  }, [surveyToDoEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    let othersAux = othersToDos;
    if (othersToDos && othersToDos.length > 0) {
      if (!isNew && surveyToDoEntity?.id) {
        othersAux = othersToDos.filter(todo => todo.id !== surveyToDoEntity.id);
      }
      // if (othersAux.length > 0) {
      //   setPreviousLocatorIdSelected(othersAux[0].locatorId);
      // }
    }
    setOthersToDosFiltered(getOthersToDosFilteredFormated(othersAux));
  }, [othersToDos, surveyToDoEntity]);

  const configSurveySelected = () => {
    if (surveys && surveys.length > 0) {
      setInstitutionWithoutSurvey(false);
      if (isNew) {
        setSurveySelected(surveys[0]);
      } else {
        if (surveyToDoEntity.survey != null) {
          const surveyId = surveyToDoEntity.survey?.id;
          const survey = surveys.find(s => s.id === surveyId);
          if (survey) {
            setSurveySelected(survey);
          } else {
            setSurveySelected(surveys[0]);
          }
        }

      }
    } else {
      setInstitutionWithoutSurvey(true);
    }
  };

  const getOthersToDosFilteredFormated = (oToDos) => {
    const listNewObjects = [];
    oToDos.forEach(todo => {
      listNewObjects.push({
        value: todo.locatorId,
        label: todo.survey.name,
        status: todo.status,
        localtorId: todo.locatorId,
        year: todo.year
      });
    });
    return listNewObjects;
  }

  const formatOptionLabel = ({ value, label, status: pStatus, year: pYear }) => (
    <div style={{ display: "flex" }}>
      <Badge color="secondary">{`${pStatus}`}</Badge> &nbsp;
      <span className='badge bg-info'>LocID # {`${value}`}</span> &nbsp;
      <em>{` (${pYear}) ${label}`}</em>
    </div>
  );

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }
    const entity = {
      ...surveyToDoEntity,
      ...values,
      institution: institutionEntity,
      locatorId,
      survey: surveySelected,
      year,
      comment,
      status
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const handleClose = () => {
    navigate(`/usersHome/institution/${id}/survey-to-dos`);
  };

  const defaultValues = () => isNew ? { year: new Date().getFullYear() } : {
    ...surveyToDoEntity,
    survey: surveyToDoEntity?.survey?.id,
    year: surveyToDoEntity?.year
  };

  const generateNewLocatorId = () => {
    dispatch(getNewLocatorId());
  };

  const getLocatorIdFromPrevious = (pSurveySelected?) => {
    setLocatorIdGenerated(false);
    setShowGetLocatorIdFromPrevious(true);
    const paramType = surveyType === SURVEY_TYPE.DATA_COLLECTION ? SURVEY_TYPE.DATA_ANALYSIS_SCORING : SURVEY_TYPE.DATA_COLLECTION;
    const paramSurveySection = pSurveySelected != null ? pSurveySelected.sectionNumber : surveySelected.sectionNumber;
    dispatch(getOpenByInstitutionAndType({ institutionId: id, surveySection: paramSurveySection, type: paramType}));
  };

  const handleSurveyChange = event => {
    const surveyId = event.target.value;
    const survey = surveys.find(s => s.id === Number(surveyId));
    setSurveySelected(survey);

    if (surveyType === SURVEY_TYPE.DATA_ANALYSIS_SCORING &&
        (showGetLocatorIdFromPrevious || locatorIdGenerated)) {
      getLocatorIdFromPrevious(survey);
    }
  };

  const handleYearChange = event => {
    setYear(event.target.value);
  }

  const handelChangeOtherLocatorId = (selectedOption: any) => {
    setPreviousLocatorIdSelected(selectedOption.value);
  };

  const confirmSelPreviousLocId = () => {
    setLocatorId(previousLocatorIdSelected);
    setLocatorIdGenerated(true);
    setShowGetLocatorIdFromPrevious(false);
  };

  const cancelSelPreviousLocId = () => {
    setShowGetLocatorIdFromPrevious(false);
    if (locatorId) {
      setLocatorIdGenerated(true);
    }
  };

  const handleFieldErrorChange = (fieldName: string, hasError: boolean) => {
    setFieldErrors(prev => ({
      ...prev,
      [fieldName]: hasError
    }));
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 data-cy="SurveyToDoDetailsHeading">

            { isNew ? (
                <Translate contentKey="irexForsahApp.surveyToDos.editView.titleNew">Create a Survey To Do</Translate>
            ) : (
              <Translate contentKey="irexForsahApp.surveyToDos.editView.titleEdit">Edit a Survey To Do</Translate>
            )}

          </h2>

          {
              institutionEntity && institutionEntity.name && (
                <p>
                  <h5>
                    <Translate contentKey="irexForsahApp.surveyToDo.forInstitution">For Institution</Translate>
                    <em className='font-w-400'>{institutionEntity.name}</em>
                  </h5>
                  <h5>
                    <Translate contentKey="irexForsahApp.surveyToDos.editView.surveyType">Type</Translate>
                    <em className='font-w-400'>{getSurveyTypeDisplay(surveyType)}</em>
                  </h5>
                </p>
              )
            }
        </Col>
      </Row>


      <Row className="justify-content-center" style={{marginTop: 10}}>
        <Col md="8">
        {(loading || institutionLoading || surveysLoading) ? (
            <LoadingSpinner />
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              <Row>
              {!isNew ? (
                <Col md="2">
                  <ValidatedField
                    name="id"
                    required
                    readOnly
                    id="survey-to-do-id"
                    label={translate('global.field.id')}
                    validate={{ required: true }}
                    value={surveyToDoEntity.id}
                  />
                </Col>

              ) : null}


                <Col md={isNew ? "4" : "2"}>
                  <ValidatedField label={translate('irexForsahApp.surveyToDos.editView.status')}
                    id="survey-to-do-status" name="status" data-cy="status" type="select"
                    value={status} onChange={(event) => setStatus(event.target.value)}>
                      {states
                      ? states.map(state => (
                          <option value={state} key={state}>
                            {state}
                          </option>
                        ))
                      : null}
                  </ValidatedField>
                </Col>

                <Col md="8">
                    {
                      institutionWithoutSurvey ? (
                        <Alert color="warning" style={{marginBottom: 0, marginTop: 13}}>
                          <FontAwesomeIcon icon={faWarning} />{' '}
                          <Translate contentKey="irexForsahApp.surveyToDos.editView.noSurveyFound">No survey available</Translate>
                        </Alert>
                      ) : (
                        surveySelected &&
                        <ValidatedField label={translate('irexForsahApp.surveyToDos.editView.survey')}
                          id="surveyToDo-survey" data-cy="survey" type="select" name="survey"
                          onChange={handleSurveyChange} value={surveySelected.id}>
                          {surveys
                            ? surveys.map(otherEntity => (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.name}
                                </option>
                              ))
                            : null}
                        </ValidatedField>
                      )
                    }
                </Col>
              </Row>

              <Row>
                <Col md="3">
                  <FieldFormWithValidation
                    label={translate('irexForsahApp.surveyToDos.editView.year')}
                    id="surveyToDo-year"
                    name="year"
                    data-cy="year"
                    type="number"
                    value={year}
                    onChange={setYear}
                    onError={handleFieldErrorChange}
                    validate={{
                      pattern: { value: new RegExp('^[0-9]{4}$'), message: translate('irexForsahApp.surveyToDo.yearError') }
                    }}
                  />
                </Col>

                <Col md="9">
                  <ValidatedField label={translate('irexForsahApp.surveyToDos.editView.comment')}
                    id="surveyToDo-comment" name="comment" data-cy="comment" type="text"
                    value={comment} onChange={(event) => setComment(event.target.value)} />
                </Col>
              </Row>

              <div className="mb-3">
                <label htmlFor="surveyToDo-locatorId" id="locatorIdLabel" className="form-label">
                  <Translate contentKey="irexForsahApp.surveyToDos.editView.locatorId">Locator ID</Translate>
                </label>

                <Row>
                  {
                    locatorIdGenerated === false ? (
                      <div>

                      {
                        showGetLocatorIdFromPrevious ? (
                          <div style={{paddingLeft: 0}}>
                            <Card>
                              <CardHeader>
                                <h6>
                                  <Translate contentKey="irexForsahApp.surveyToDos.editView.selectPreviousLocatorIdTile">Previous Survey To Do</Translate>
                                </h6>
                              </CardHeader>
                              <CardBody>
                                <Row>
                                  {
                                    othersToDosFiltered.length === 0 ? (

                                        <Alert color="warning">
                                          <Row>
                                            <Col md="10">
                                              <FontAwesomeIcon icon={faWarning} />{' '}
                                              <Translate contentKey="irexForsahApp.surveyToDos.editView.noPreviousSurveyToDoFound">No previous Survey To Do found</Translate>
                                            </Col>
                                            <Col md="2" className='justify-content-end'>
                                              <Button color="secondary" className='justify-content-end' onClick={cancelSelPreviousLocId}>
                                                <FontAwesomeIcon icon={faArrowLeftLong}/>
                                              </Button>
                                            </Col>
                                          </Row>
                                        </Alert>
                                    ) : (
                                      <Row>
                                        <Col md="10">
                                            <Select
                                              formatOptionLabel={formatOptionLabel}
                                              options={othersToDosFiltered}
                                              onChange={handelChangeOtherLocatorId}
                                            />
                                          </Col>

                                          <Col md="2" className='justify-content-end'>
                                            <Button color="primary" className='pad-buttons me-2' onClick={confirmSelPreviousLocId}>
                                              <FontAwesomeIcon icon={faCheck}/>
                                            </Button>

                                            <Button color="secondary" onClick={cancelSelPreviousLocId}>
                                              <FontAwesomeIcon icon={faTimes}/>
                                            </Button>
                                          </Col>
                                      </Row>
                                    )
                                  }



                                </Row>
                              </CardBody>
                            </Card>

                          </div>
                        ) : (
                          <div>
                            { surveyType === SURVEY_TYPE.DATA_COLLECTION && (
                              <Button className="me-2" color="info" onClick={generateNewLocatorId} block outline>
                                <FontAwesomeIcon icon={faPlusSquare} />{' '}
                                <Translate contentKey="irexForsahApp.surveyToDos.editView.bntNewLocatorId">Generate LocatorId</Translate>
                              </Button>
                            )}

                            {
                              surveyType === SURVEY_TYPE.DATA_ANALYSIS_SCORING && (
                                <Button className="me-2" color="info" onClick={() => getLocatorIdFromPrevious(null)} block outline>
                                  <FontAwesomeIcon icon={faHandPointer}/>{' '}
                                  <Translate contentKey="irexForsahApp.surveyToDos.editView.btnGetLocatorFromPrevious">Get LocatorId from previous</Translate>
                                </Button>
                              )
                            }
                        </div>
                        )
                      }

                      </div>
                    ) : (
                      <div className='row' style={{paddingRight: 0}}>
                        <Col md="6">
                          <input name="locatorId" id="surveyToDo-locatorId" readOnly
                            data-cy="locatorId" value={locatorId} type="text"
                            className="is-touched is-valid form-control" aria-invalid="false" />
                        </Col>

                        <Col md="6" style={{paddingRight: 0}}>
                          <div>
                            { surveyType === SURVEY_TYPE.DATA_COLLECTION && (
                              <Button className="me-2" color="info" onClick={generateNewLocatorId} block outline>
                                <FontAwesomeIcon icon={faPlusSquare} />{' '}
                                <Translate contentKey="irexForsahApp.surveyToDos.editView.bntNewLocatorId">Generate LocatorId</Translate>
                              </Button>
                            )}

                            {
                              surveyType === SURVEY_TYPE.DATA_ANALYSIS_SCORING && (
                                <Button className="me-2" color="info" onClick={() => getLocatorIdFromPrevious(null)} block outline>
                                  <FontAwesomeIcon icon={faHandPointer}/>{' '}
                                  <Translate contentKey="irexForsahApp.surveyToDos.editView.btnGetLocatorFromPrevious">Get LocatorId from previous</Translate>
                                </Button>
                              )
                            }
                          </div>

                        </Col>

                      </div>
                    )
                  }
                </Row>
              </div>


                {
                institutionWithoutSurvey ? (
                  <Alert color="warning" style={{marginTop: 5}}>
                    <FontAwesomeIcon icon={faWarning} />{' '}
                    <Translate contentKey="irexForsahApp.surveyToDos.editView.noSurveyFoundMessage">No survey available</Translate>
                  </Alert>
                ) : (

                  <Button color="primary" className='pad-buttons' id="save-entity" data-cy="entityCreateSaveButton"
                    type="submit" disabled={updating || showGetLocatorIdFromPrevious}>
                    <FontAwesomeIcon icon="save" />
                    &nbsp;
                    <Translate contentKey="entity.action.save">Save</Translate>
                  </Button>
                )
              }
              &nbsp;

              <Button onClick={handleClose} className="btn btn-secondary me-2"
                  id="jh-create-entity" data-cy="entityCreateButton">
                <FontAwesomeIcon icon="arrow-left" />{' '}
                <Translate contentKey="irexForsahApp.surveyToDos.buttons.back">Back</Translate>
              </Button>

            </ValidatedForm>
          )}
        </Col>
      </Row>

    </div>
  );
};

export default InstitutionSurveyToDoEdit;
