import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Alert, Badge, Button, Label, Modal, ModalBody, ModalFooter, ModalHeader, Table, Tooltip } from 'reactstrap';
import { Translate, getSortState, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown, faCheck, faBan, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { ASC, DESC, SORT } from 'app/shared/util/pagination.constants';
import { overrideSortStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntities, checkIsDeleatable, deleteEntity } from './survey.reducer';
import { AUTHORITIES, SURVEY_ISNOT_DELETEABLE_REASON } from 'app/config/constants';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import LoadingSpinner from 'app/shared/components/LoadingSpinner';
import { getSurveyTypeDisplay } from 'app/shared/util/display-constants';

export const Survey = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities,
    [AUTHORITIES.ADMIN, AUTHORITIES.IREX_ADMIN]));

  const [sortState, setSortState] = useState(overrideSortStateWithQueryParams(getSortState(pageLocation, 'id'), pageLocation.search));
  const [tooltipInstitutionOpen, setTooltipInstitutionOpen] = useState('');

  const toggleTooltipInstitution = (name) => {
    if (tooltipInstitutionOpen === name) {
      setTooltipInstitutionOpen('');
    } else {
      setTooltipInstitutionOpen(name);
    }
  };

  const surveyList = useAppSelector(state => state.survey.entities);
  const loading = useAppSelector(state => state.survey.loading);

  const surveyDeleteable = useAppSelector(state => state.survey.isDeleteableResponse);
  

  const getAllEntities = () => {
    dispatch(getEntities());
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?sort=${sortState.sort},${sortState.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [sortState.order, sortState.sort]);

  const sort = p => () => {
    setSortState({
      ...sortState,
      order: sortState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handleSyncList = () => {
    sortEntities();
  };

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = sortState.sort;
    const order = sortState.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const navigateTo = (value) => {
    navigate(value);
  };

  // #region Delete Survey
  const [surveySelectedToDelete, setSurveySelectedToDelete] = useState(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [modalAreYouSure, setModalAreYouSure] = useState('');
  const [modalToDosPending, setModalToDosPending] = useState('');
  const [cannotDeleteModalIsOpen, setCannotDeleteModalIsOpen] = useState(false);

  const checkIsDeletable = (survey) => {
    setModalAreYouSure('');
    setModalToDosPending('');
    setSurveySelectedToDelete(survey);
    dispatch(checkIsDeleatable(survey.id));
  }

  useEffect(() => {
    if (surveyDeleteable && surveySelectedToDelete) {
      const surveyName = surveySelectedToDelete.name ? surveySelectedToDelete.name : '';
      if (surveyDeleteable.isDeleteable || surveyDeleteable.reasonCode === SURVEY_ISNOT_DELETEABLE_REASON.HAVE_TODOS_PENDING) {
        const activateStr = surveySelectedToDelete.activated ? 'activated' : 'deactivated';        
        const areYouSureHTML = translate(`irexForsahApp.survey.deleteModal.areYouSure.${activateStr}`, { surveyName });

        if (surveyDeleteable.reasonCode === SURVEY_ISNOT_DELETEABLE_REASON.HAVE_TODOS_PENDING) {
          let institutions = "";
          surveyDeleteable.institutions.forEach((inst) => {
            institutions += `<br /> - ${inst}`;
          });

          const todosPendingHTML = translate(`irexForsahApp.survey.deleteModal.havePendingToDos`, { institutions });
          setModalToDosPending(todosPendingHTML.props.dangerouslySetInnerHTML.__html);
        }

        setModalAreYouSure(areYouSureHTML.props.dangerouslySetInnerHTML.__html);
        setDeleteModalIsOpen(true);
      } else {
        setCannotDeleteModalIsOpen(true);
      }
    }
  }, [surveyDeleteable]);

  const handleCloseDeleteModal = () => {
    setModalAreYouSure('');
    setModalToDosPending('');
    setSurveySelectedToDelete(null);
    setDeleteModalIsOpen(false);
    setCannotDeleteModalIsOpen(false)
  }

  const confirmDelete = () => {
    dispatch(deleteEntity(surveySelectedToDelete.id));
    setSurveySelectedToDelete(null);
    setDeleteModalIsOpen(false);
  }

  // #endregion



  return (
    <div>
      <h1 className="bordered" id="survey-heading" data-cy="SurveyHeading">
        <Translate contentKey="irexForsahApp.survey.home.title">Surveys</Translate>
      </h1>
      <div className="spacer20"/>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="irexForsahApp.survey.home.refreshListLabel">Refresh List</Translate>
          </Button>
        </div>

        {loading ? (<LoadingSpinner />) : (
          <div className="table-responsive">
          {surveyList && surveyList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th className="hand" onClick={sort('name')}>
                    <Translate contentKey="irexForsahApp.survey.name">Name</Translate>{' '}
                    <FontAwesomeIcon icon={getSortIconByFieldName('name')} />
                  </th>
                  <th onClick={sort('type')}  style={{minWidth: '170px'}}>
                    <Translate contentKey="irexForsahApp.survey.type">Type</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th onClick={sort('activated')}>
                    <Translate contentKey="irexForsahApp.survey.activated">Activated</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th>
                    <Translate contentKey="irexForsahApp.survey.institution">Institution</Translate> <FontAwesomeIcon icon="sort" />
                  </th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {surveyList.map((survey, i) => (
                  <tr key={`entity-${i}`} data-cy="entityTable">
                    <td className='table-principal-text'>{survey.name}</td>
                    <td><span className={`table-badge ${survey.type === 'data_collection' ? 'color-1' : 'color-2'}`}>{getSurveyTypeDisplay(survey.type)}</span></td>
  
                    <td style={{minWidth: '120px'}} className='table-column-tags'>
                      { survey.activated ?
                        <span className='table-tag active'><FontAwesomeIcon icon={faCheck} /></span> :
                        <span className='table-tag deactivated'><FontAwesomeIcon icon={faBan} /></span>
                      }
                    </td>
  
                    <td className='table-column-tags'>
                      {survey.institutions
                        ? survey.institutions.map((val, j) => (
  
                            <span key={j} className='table-tag' onClick={() => navigateTo(`/institution/${val.id}`)} id={`srv-${survey.id}-insti-${val.id}`}>
                              {val.acronym}
                              <Tooltip target={`srv-${survey.id}-insti-${val.id}`} placement="top"
                                autohide={true} flip={true} toggle={() => toggleTooltipInstitution(`srv-${survey.id}-insti-${val.id}`)}
                                isOpen={tooltipInstitutionOpen === `srv-${survey.id}-insti-${val.id}`}>
                                {val.name}
                              </Tooltip>
                            </span>
                          ))
                        : null}
                    </td>
                    
                    <td className="table-buttons-column background-f2">
  
                    <div className="table-buttons-wrapper">
                      
                      <span className='pointer view' onClick={() => navigateTo(`/usersHome/survey/${survey.id}`)}> 
                        <img src="/content/images/icons/eye-icon.svg" alt="view icon"/> 
                        <Translate contentKey="entity.action.view">View</Translate>
                      </span>
  
                      <span className='pointer edit' onClick={() => navigateTo(`/usersHome/survey/${survey.id}/edit`)}> 
                        <img src="/content/images/icons/pencil-icon.svg" alt="edit icon"/> 
                        <Translate contentKey="entity.action.edit">Edit</Translate>
                      </span>
  
                      {isAdmin &&
                        <span className='pointer delete' onClick={() => checkIsDeletable(survey)}>
                          <img src="/content/images/icons/times-circle-icon.svg" alt="delete icon"/>
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      }
                    </div>
  
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && (
              <div className="alert alert-warning">
                <Translate contentKey="irexForsahApp.survey.home.notFound">No Surveys found</Translate>
              </div>
            )
          )}
        </div>
        )}


      <Modal isOpen={deleteModalIsOpen} backdrop={true}>
        <ModalHeader className='text-rejected'>
          <span className="status rejected" /> {' '}
          <Translate contentKey="irexForsahApp.survey.deleteModal.title"/>
        </ModalHeader>
        <ModalBody>
          <p><Label dangerouslySetInnerHTML={{ __html: modalAreYouSure }} /></p>
          {
            modalToDosPending && modalToDosPending.length > 0 &&
            <Alert color="secondary">
              <Label dangerouslySetInnerHTML={{ __html: modalToDosPending }} />
            </Alert>
          }

          <Alert color='pending' className='f-size-08' style={{marginBottom: 0}}>
            <FontAwesomeIcon icon={faLightbulb} />{' '}
            <Translate contentKey="irexForsahApp.survey.deleteModal.koboDeletionReminder"/>
          </Alert>
        </ModalBody>

        <ModalFooter className="justify-content-between">
          <Button color="danger" onClick={confirmDelete}>
            <Translate contentKey='irexForsahApp.survey.deleteModal.wantDelete'/>
          </Button>

          <Button color="secondary" onClick={handleCloseDeleteModal}>
            <Translate contentKey="irexForsahApp.survey.close"/>
          </Button>
        </ModalFooter>
      </Modal>


      <Modal isOpen={cannotDeleteModalIsOpen}>
        <ModalHeader>
        <Translate contentKey="irexForsahApp.survey.deleteModal.title"/>
        </ModalHeader>
        <ModalBody>
          <Label>
            <Translate contentKey="irexForsahApp.survey.deleteModal.cannotDelete"/>
          </Label>

        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={handleCloseDeleteModal}>
            <Translate contentKey="irexForsahApp.survey.close"/>
          </Button>
        </ModalFooter>
      </Modal>





    </div>
  );
};

export default Survey;
