import React from "react";
import Barchart from "app/modules/charts/barchart";
import Radar from "app/modules/charts/radarchart";
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {Translate} from "react-jhipster";
import 'react-tabs/style/react-tabs.css';
import ErrorBoundary from "app/shared/error/error-boundary";
import AssessmentReport from "./reports/assessment-report";
import { useAppSelector } from "app/config/store";
import { hasAnyAuthority } from "app/shared/auth/private-route";
import { AUTHORITIES } from "app/config/constants";

const Charts = () => {
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.IREX_ADMIN, AUTHORITIES.TVET_ADMIN]));
  const isValidator = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.VALIDATOR]));

  return (
    <ErrorBoundary>
      <div>
        <Tabs>
          <TabList>
            <Tab><Translate contentKey="visualisation.charts.subSectionComparison"/></Tab>
            <Tab><Translate contentKey="visualisation.charts.sectionComparison"/></Tab>
            {
              (isAdmin || isValidator) && <Tab><Translate contentKey="irexForsahApp.tables.institutionReport.title"/></Tab>
            } 
          </TabList>

          <TabPanel>
            <Radar/>
          </TabPanel>

          <TabPanel>
            <Barchart/>
          </TabPanel>

          {
            (isAdmin || isValidator) && (
              <TabPanel>
                <AssessmentReport />
              </TabPanel>
            )
          }

        </Tabs>
      </div>
    </ErrorBoundary>
  );
};

export default Charts;
