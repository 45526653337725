import { useAppDispatch, useAppSelector } from 'app/config/store';
import React, { useEffect, useState } from 'react';
import {Translate, translate} from 'react-jhipster';
import { Collapse, Table, Button, Badge, Tooltip, Modal, ModalHeader, ModalBody, Input, ModalFooter, Label, Alert, Card, CardBody, Row, Col} from 'reactstrap';
import Select from 'react-select';
import { getRepliesToReview, getTempViewLink, approveReply, rejectReply, setTempViewLink} from 'app/modules/usersHome/data-validation.reducer';
import LoadingSpinner from 'app/shared/components/LoadingSpinner';
import { SURVEYTODO_STATUS, SURVEY_STATUS_COLORS_VALIDATION } from 'app/config/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faBan, faWarning, faComment, faFilter } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { getSurveyTodoStatusDisplayValidation } from 'app/shared/util/display-constants';


import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import { registerLocale } from 'react-datepicker';
// import en from 'date-fns/locale/en-US';
import { format, subMonths  } from 'date-fns';

// registerLocale('en', en);


export const Validation = () => {
  const dispatch = useAppDispatch();

  const [collapseIsOpen, setCollapseIsOpen] = useState([]);
  const [tooltipIsOpen, setTooltipIsOpen] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [reviewAction, setReviewAction] = useState("");
  const [modalActionPlaceholder, setModalActionPlaceholder] = useState("");
  const [modalDataSelected, setModalDataSelected] = useState("");
  const [modalActionComments, setModalActionComments] = useState("");
  const [todoIdSelected, setTodoIdSelected] = useState(0);
  const [showAlertComentInModal, setShowAlertComentInModal] = useState(false);

  const years = [];
  for (let i = 2023; i <= new Date().getFullYear(); i++) {
    years.push(i.toString());
  }

  const states = [
    {value: SURVEYTODO_STATUS.ANSWERED, label: getSurveyTodoStatusDisplayValidation(SURVEYTODO_STATUS.ANSWERED)},
    {value: SURVEYTODO_STATUS.RE_ANSWERED, label: getSurveyTodoStatusDisplayValidation(SURVEYTODO_STATUS.RE_ANSWERED)},
    {value: SURVEYTODO_STATUS.APPROVED, label: getSurveyTodoStatusDisplayValidation(SURVEYTODO_STATUS.APPROVED)},
    {value: SURVEYTODO_STATUS.REJECTED, label: getSurveyTodoStatusDisplayValidation(SURVEYTODO_STATUS.REJECTED)}
  ];

  const twoMonthsAgo = subMonths(new Date(), 2);

  const objAll = {value: '', label: translate('global.selectAll')};
  const [filterYearTodos, setFilterYearTodos] = useState(objAll);
  const [filterDateFrom, setFilterDateFrom] = useState(twoMonthsAgo);
  const [filterDateTo, setFilterDateTo] = useState(null);
  const [filterStatus, setFilterStatus] = useState([states[0], states[1]]);

  const [modalCommentIsOpen, setModalCommentIsOpen] = useState(false);
  const [modalReviewComentText, setModalReviewComentText] = useState("");

  const [updateDispached, setUpdateDispached] = useState(false);


  const account = useAppSelector(state => state.authentication.account);
  const itemsToReview = useAppSelector(state => state.dataValidationReducer.entities);
  const loadingLink = useAppSelector(state => state.dataValidationReducer.loadingLink);
  const loading = useAppSelector(state => state.dataValidationReducer.loading);
  const linkSuccess = useAppSelector(state => state.dataValidationReducer.linkSuccess);
  const tempViewLink = useAppSelector(state => state.dataValidationReducer.tempViewLink);
  const updateSuccess = useAppSelector(state => state.dataValidationReducer.updateSuccess);
  const updating = useAppSelector(state => state.dataValidationReducer.updating);
  const message = useAppSelector(state => state.dataValidationReducer.message);
  const errorMessage = useAppSelector(state => state.dataValidationReducer.errorMessage);

  const REVIEW_ACTIONS = {
    REJECT: 'reject',
    APPROVE: 'approve'
  };

  useEffect(() => {
    if (account && account.login) {
      handleSearch();
    }
  }, [account]);

  useEffect(() => {
    if (itemsToReview.length > 0) {
      setCollapseIsOpen(itemsToReview.map(() => false));

      const tooltipsStatus = [];
      itemsToReview.forEach((item) => {
        item.details.forEach((detail) => {
          tooltipsStatus.push({id: 'app-' + detail.todoReplyId, status: false});
          tooltipsStatus.push({id: 'rej-' + detail.todoReplyId, status: false});
        });
      });
      setTooltipIsOpen(tooltipsStatus);
    }
  }, [itemsToReview]);

  const toggleCollapse = (index) => {
    const collapseCopy = [...collapseIsOpen];
    collapseCopy[index] = !collapseCopy[index];
    setCollapseIsOpen(collapseCopy);
  }

  const toggleTooltip = (replyId) => {
    const tooltipsCopy = [...tooltipIsOpen];
    const index = tooltipsCopy.findIndex(tooltip => tooltip.id === replyId);
    tooltipsCopy[index].status = !tooltipsCopy[index].status;
    setTooltipIsOpen(tooltipsCopy);
  }

  const getTempLinkView = (surveyKoboId, replyKoboId) => {
    dispatch(getTempViewLink({surveyKoboId, replyKoboId}));
  }

  useEffect(() => {
    if (loadingLink === false && linkSuccess && tempViewLink) {
      window.open(tempViewLink, '_blank');
      dispatch(setTempViewLink(''));
    }
  }, [tempViewLink]);
  // const calculateFilesCount = (attachments) => {
  //   let count = 0;
  //   attachments.forEach(attachment => {
  //     count += attachment.files.length;
  //   });
  //   return count;
  // }
  const openModal = (action, todoId, surveyName, replyDate, replySubmmitter) => {
    setModalActionComments("");
    setShowAlertComentInModal(false);
    setReviewAction(action);
    setModalActionPlaceholder(translate(`irexForsahApp.dataValidation.modal.${action}.placeholder`));
    const subtitle = translate('irexForsahApp.dataValidation.modal.subtitle', {surveyName, replySubmmitter, replyDate});
    setModalDataSelected(subtitle.props.dangerouslySetInnerHTML.__html);

    setTodoIdSelected(todoId);
    setModalIsOpen(true);
  }

  const confirmAction = () => {
    setShowAlertComentInModal(false);
    if (reviewAction === REVIEW_ACTIONS.REJECT && modalActionComments.length === 0) {
      setShowAlertComentInModal(true);
      return;
    }
    if (reviewAction === REVIEW_ACTIONS.APPROVE) {
      dispatch(approveReply({todoId: todoIdSelected, reviewComment: modalActionComments}));
    } else {
      dispatch(rejectReply({todoId: todoIdSelected, reviewComment: modalActionComments}));
    }
    setUpdateDispached(true);
  }

  useEffect(() => {
    if (updateDispached && updating === false && updateSuccess === false) {
      setUpdateDispached(false);
      toast.error(errorMessage);
    } else if (updateDispached && updating === false && updateSuccess === true) {
      setUpdateDispached(false);
      setModalIsOpen(false);
      toast.success(message);
      handleSearch();
    }


  }, [updating, updateSuccess]);

  const openCommentModal = (surveyName, replyDate, replySubmmitter, reviewComment) => {
    const subtitle = translate('irexForsahApp.dataValidation.modal.subtitle', {surveyName, replySubmmitter, replyDate});
    setModalDataSelected(subtitle.props.dangerouslySetInnerHTML.__html);
    setModalReviewComentText(reviewComment);
    setModalCommentIsOpen(true);
  }

  const handleFilterYearChange = (selectedOption: any) => {
    setFilterYearTodos(selectedOption);
  };

  const handleFilterStatusChange = (selectedOption: any) => {
    setFilterStatus(selectedOption);
  };

  const handleFilterDateFromChange = (date) => {
    setFilterDateFrom(date);
  };

  const handleFilterDateToChange = (date) => {
    setFilterDateTo(date);
  };

  const handleSearch = () => {
    const entityFilters = {
      pYearTodo: filterYearTodos.value === '' ? null : Number(filterYearTodos.value),
      pDateFrom: filterDateFrom ? format(filterDateFrom, 'MM-dd-yyyy') : null,
      pDateTo: filterDateTo ? format(filterDateTo, 'MM-dd-yyyy') : null,
      pStates: filterStatus.map(status => status.value).join(',')
    };
    
    dispatch(getRepliesToReview(entityFilters));
  }

  return (
    <div className="validation-wrapper">

      <h1 className="bordered">
        <Translate contentKey="irexForsahApp.dataValidation.home.title"/>
      </h1>

      <Card className="my-2" style={{width: '100%'}}>
          <CardBody>
            <Row>
              <Col md="2">
                <div className="filter-header">
                <Translate contentKey="irexForsahApp.dataValidation.home.filter.year"/> 
                </div>
                <Select
                  name="year"
                  options={[
                    objAll,
                    ...years.map(year => ({value: year, label: year}))
                  ]}
                  value={filterYearTodos}
                  classNamePrefix="select"
                  onChange={handleFilterYearChange}
                />
              </Col>

              <Col md="2">
                <div className="filter-header">
                <Translate contentKey="irexForsahApp.dataValidation.home.filter.dateFrom"/>                  
                </div>

                <DatePicker
                  showIcon
                  isClearable
                  showWeekNumbers
                  showMonthDropdown
                  todayButton={translate('global.today')}
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={15}
                  scrollableYearDropdown
                  selected={filterDateFrom}
                  onChange={handleFilterDateFromChange}
                  dateFormat="MM-dd-yyyy"
                  minDate={new Date('2023-01-01')}
                  placeholderText={translate('global.selectDate')}
                  customInput={
                    <Input
                      style={{ height: 38, borderRadius: 4 }}
                      placeholder="MM-dd-yyyy"
                      className="custom-input"
                    />
                  }
                />
              </Col>

              <Col md="2">
                <div className="filter-header">
                <Translate contentKey="irexForsahApp.dataValidation.home.filter.dateTo"/>                  
                </div>

                <DatePicker
                  showIcon
                  isClearable
                  showWeekNumbers
                  showMonthDropdown
                  todayButton={translate('global.today')}
                  showYearDropdown
                  dateFormatCalendar="MMMM"
                  yearDropdownItemNumber={15}
                  scrollableYearDropdown
                  selected={filterDateTo}
                  onChange={handleFilterDateToChange}
                  dateFormat="MM-dd-yyyy"
                  maxDate={new Date()}
                  placeholderText={translate('global.selectDate')}
                  customInput={
                    <Input
                      style={{ height: 38, borderRadius: 4 }}
                      placeholder="MM-dd-yyyy"
                      className="custom-input"
                    />
                  }
                />
              </Col>

              <Col md="5">
                <div className="filter-header">
                  <Translate contentKey="irexForsahApp.surveyToDos.listView.status" />
                </div>
                <Select
                  name="status"
                  options={states}
                  value={filterStatus}
                  classNamePrefix="select"
                  onChange={handleFilterStatusChange}
                  isMulti={true}
                />
              </Col>

              <Col md="1" className='d-flex justify-content-end'>
                <Button color="secondary" onClick={handleSearch} outline style={{height: 38, marginTop: 26}}>
                  <Translate contentKey="irexForsahApp.surveyToDos.listView.search" />
                </Button>
              </Col>
            </Row>

          </CardBody>
        </Card>
      <div className="spacer20"/>

      { loadingLink && <LoadingSpinner message={translate('irexForsahApp.dataValidation.messages.loadingTempLink') } /> }
      { loading && <LoadingSpinner /> }

      { itemsToReview.map((item, index) => (
            <React.Fragment key={index}>
              <div className="card institution-card">
                <div className={`card-header ${collapseIsOpen[index] ? 'card-selected' : ''}`}
                  onClick={() => toggleCollapse(index)}>
                  <h2 className="pt-2 h2-with-circle bold">
                    {item.institutionName}
                    <Badge color="secondary" pill className="badge-custom">{item.details.length}</Badge>
                  </h2>
                </div>
              </div>

              <Collapse isOpen={collapseIsOpen[index]} className='validation-item-border'>
                {/* Institution info */}
                <div className="card-body pb-1 pt-4 border-0">
                  <div className="row mb-3">
                    <div className="inst-info-label">{item.institutionAddress}</div>
                    <div className="inst-info">{item.institutionRegion}, {item.institutionGovernorate}</div>
                    <div className="spacer20"/>
                    <div className="col">
                      <div className="inst-info-label">
                        <Translate contentKey="irexForsahApp.dataValidation.infoTitles.contactName"/>
                      </div>
                      <div className="inst-info">{item.institutionContactName}</div>
                    </div>
                    <div className="col">
                      <div className="inst-info-label">
                      <Translate contentKey="irexForsahApp.dataValidation.infoTitles.contactNumber"/>
                      </div>
                      <div className="inst-info">{item.institutionPhone} / {item.institutionMobilePhone} / {item.institutionEmail}</div>
                    </div>
                  </div>
                </div>

                {/* Submitted surveys table */}
                <Table striped responsive className="survey-table">
                  <thead>
                    <tr>
                      <th className='bold'> <Translate contentKey="irexForsahApp.dataValidation.table.submittedSurvey"/> </th>
                      {/* <th className='bold'> Attachments </th> */}
                      <th className='bold'> <Translate contentKey="irexForsahApp.dataValidation.table.dataCollector"/> </th>
                      <th className='bold'> <Translate contentKey="irexForsahApp.dataValidation.table.dateSubmitted"/> </th>
                      <th className='bold'> <Translate contentKey="irexForsahApp.dataValidation.table.comment"/> </th>
                      <th className='bold'> <Translate contentKey="irexForsahApp.dataValidation.table.status"/> </th>
                      <th className='bold'> <Translate contentKey="irexForsahApp.dataValidation.table.dcForm"/></th>
                      <th className='bold'> <Translate contentKey="irexForsahApp.dataValidation.table.actions"/> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      item.details.map((detail, idx) => (
                        <tr key={idx}>
                          <td>
                            <Button color="link" onClick={() => getTempLinkView(detail.surveyKoboId, detail.replyKoboId)}>
                              {detail.surveyName}
                            </Button>
                          </td>
                          {/* <td>{calculateFilesCount(detail.attachments)} Files</td> */}
                          <td>{detail.replySubmmitter}</td>
                          <td>{detail.replyDate}</td>
                          <td className="justify-content-center">
                            {
                              detail.replyReviewComment && detail.replyReviewComment !== '' ? (
                                <>
                                  <FontAwesomeIcon icon={faComment} style={{color: '#a9c8c8', cursor: 'pointer'}}
                                    onClick={() => openCommentModal(detail.surveyName, detail.replyDate, detail.replySubmmitter, detail.replyReviewComment)}/>
                                </>
                              ) : (
                                <p className="small-text">{translate('global.noComments')}</p> )
                            }
                          </td>
                          <td>
                            <Badge color={SURVEY_STATUS_COLORS_VALIDATION[detail.todoStatus]}>
                              {getSurveyTodoStatusDisplayValidation(detail.todoStatus)}
                            </Badge>
                          </td>
                          <td>
                            {
                              detail.dataCollection_SurveyKoboId != null && detail.dataCollection_SurveyKoboId !== '' &&
                              detail.dataCollection_ReplyKoboId != null && detail.dataCollection_ReplyKoboId > 0 && (
                                <Button color="link" onClick={() => getTempLinkView(detail.dataCollection_SurveyKoboId, detail.dataCollection_ReplyKoboId)}>
                                  {detail.dataCollection_SurveyName}
                                </Button>
                              )
                            }
                          </td>
                          <td>

                            {
                              (detail.todoStatus === SURVEYTODO_STATUS.ANSWERED || detail.todoStatus === SURVEYTODO_STATUS.RE_ANSWERED) && (
                                <>
                                  <span className="status approved hand" id={'approveButton-' + detail.todoReplyId}
                                    onClick={() => openModal(REVIEW_ACTIONS.APPROVE, detail.todoId, detail.surveyName, detail.replyDate, detail.replySubmmitter)}/>

                                  <Tooltip autohide={true} flip={true} target={'approveButton-' + detail.todoReplyId}
                                    isOpen={tooltipIsOpen.find(t => t.id === 'app-' + detail.todoReplyId && t.status) }
                                    toggle={() => toggleTooltip('app-' + detail.todoReplyId)} >
                                    <Translate contentKey="irexForsahApp.dataValidation.table.approve"/>
                                  </Tooltip>


                                  <span className="status rejected hand" id={'rejectButton-' + detail.todoReplyId}
                                    onClick={() => openModal(REVIEW_ACTIONS.REJECT, detail.todoId, detail.surveyName, detail.replyDate, detail.replySubmmitter)}/>

                                  <Tooltip autohide={true} flip={true} target={'rejectButton-' + detail.todoReplyId}
                                    isOpen={tooltipIsOpen.find(t => t.id === 'rej-' + detail.todoReplyId && t.status) }
                                    toggle={() => toggleTooltip('rej-' + detail.todoReplyId)} >
                                    <Translate contentKey="irexForsahApp.dataValidation.table.reject"/>
                                  </Tooltip>
                            </>
                              )
                            }
                            
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </Table>

              </Collapse>
            </React.Fragment>
        ))
      }

      <Modal isOpen={modalIsOpen} backdrop={true} size={`${reviewAction === REVIEW_ACTIONS.REJECT ? 'lg' : ''}`}>
        <ModalHeader>
          {reviewAction === REVIEW_ACTIONS.APPROVE && (
            <>
              <span className="status approved" />
              <Translate contentKey="irexForsahApp.dataValidation.modal.approve.title"/>
            </>)
          }

          {reviewAction === REVIEW_ACTIONS.REJECT && (
            <>
              <span className="status rejected" /> {' '}
              <Translate contentKey="irexForsahApp.dataValidation.modal.reject.title"/>
            </>)
          }
        </ModalHeader>
        <ModalBody>
          <p><Label dangerouslySetInnerHTML={{ __html: modalDataSelected }} /></p>
          <Label style={{marginTop: 10}}>
            <Translate contentKey="irexForsahApp.dataValidation.modal.commentsTitle"/>
          </Label>
          <Input
            type="textarea"
            placeholder={modalActionPlaceholder}
            rows={5}
            onChange={(e) => setModalActionComments(e.target.value)}
          />

          {showAlertComentInModal &&  (
            <Alert color="danger"  style={{marginTop: 10}}>
              <FontAwesomeIcon icon={faWarning} /> {' '}
              <Translate contentKey="irexForsahApp.dataValidation.modal.reject.alert"/>
            </Alert> )
          }
        </ModalBody>

        <ModalFooter className="justify-content-between">
          <Button color={`${reviewAction === REVIEW_ACTIONS.REJECT ? 'danger' : 'primary'}`} onClick={confirmAction}>
            <FontAwesomeIcon icon={reviewAction === REVIEW_ACTIONS.REJECT ? faBan : faCheck} /> {' '}
            <Translate contentKey={`irexForsahApp.dataValidation.modal.${reviewAction}.button`}/>
          </Button>

          <Button color="secondary" onClick={() => setModalIsOpen(false)}>
            <Translate contentKey="irexForsahApp.dataValidation.modal.close"/>
          </Button>
        </ModalFooter>
      </Modal>


      <Modal isOpen={modalCommentIsOpen} backdrop={true} size='lg'>
        <ModalHeader>
          <FontAwesomeIcon icon={faComment} /> {' '} Review comments
        </ModalHeader>
        <ModalBody>
          <Label dangerouslySetInnerHTML={{ __html: modalDataSelected }} />

          <Alert color="secondary">
            <em><Label dangerouslySetInnerHTML={{ __html: modalReviewComentText }} /></em>
          </Alert>
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={() =>  setModalCommentIsOpen(false)}>
            <Translate contentKey="irexForsahApp.dataValidation.modal.close"/>
          </Button>
        </ModalFooter>
      </Modal>


    </div>
  );
}
export default Validation;
