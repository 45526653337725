import { IReply } from 'app/shared/model/reply.model';

export interface IReplyAttachment {
  id?: number;
  fileName?: string | null;
  url?: string | null;
  questionXPath?: string | null;
  reply?: IReply | null;
}

export const defaultValue: Readonly<IReplyAttachment> = {};
