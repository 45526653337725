import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table, Badge } from 'reactstrap';
import { Translate, TextFormat, JhiPagination, JhiItemCount, getPaginationState, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';

import { APP_DATE_FORMAT, AUTHORITIES } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { getUsersAsAdmin, updateUser } from './user-management.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { toast } from 'react-toastify';
import { getRoleDisplayName } from 'app/shared/util/display-constants';


export const UserManagement = () => {
  const dispatch = useAppDispatch();

  const pageLocation = useLocation();
  const navigate = useNavigate();

  const [isTvetAdmin, setIsTvetAdmin] = useState(false);

  const [pagination, setPagination] = useState(
    overridePaginationStateWithQueryParams(getPaginationState(pageLocation, ITEMS_PER_PAGE, 'id'), pageLocation.search),
  );

  const getUsersFromProps = () => {
    dispatch(
      getUsersAsAdmin({
        page: pagination.activePage - 1,
        size: pagination.itemsPerPage,
        sort: `${pagination.sort},${pagination.order}`,
      }),
    );
    const endURL = `?page=${pagination.activePage}&sort=${pagination.sort},${pagination.order}`;
    if (pageLocation.search !== endURL) {
      navigate(`${pageLocation.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    getUsersFromProps();
  }, [pagination.activePage, pagination.order, pagination.sort]);

  useEffect(() => {
    const params = new URLSearchParams(pageLocation.search);
    const page = params.get('page');
    const sortParam = params.get(SORT);
    if (page && sortParam) {
      const sortSplit = sortParam.split(',');
      setPagination({
        ...pagination,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [pageLocation.search]);

  const sort = p => () =>
    setPagination({
      ...pagination,
      order: pagination.order === ASC ? DESC : ASC,
      sort: p,
    });

  const handlePagination = currentPage =>
    setPagination({
      ...pagination,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    getUsersFromProps();
  };

  const toggleActive = user => () => {
    dispatch(
      updateUser({
        ...user,
        activated: !user.activated,
      }),
    );
  };

  const account = useAppSelector(state => state.authentication.account);
  const users = useAppSelector(state => state.userManagement.users);
  const totalItems = useAppSelector(state => state.userManagement.totalItems);
  const loading = useAppSelector(state => state.userManagement.loading);
  const updateSuccess = useAppSelector(state => state.userManagement.updateSuccess);
  const resetPasswordSuccess = useAppSelector(state => state.userManagement.resetPasswordSuccess);

  useEffect(() => {
    if (updateSuccess) {
      handleSyncList();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (account && account.login) {
      account.authorities.forEach(authority => {
        if (authority === AUTHORITIES.TVET_ADMIN) {
          setIsTvetAdmin(true);
        }
      });
    }
  }, [account]);

  useEffect(() => {
    if (resetPasswordSuccess) {
      toast.success(translate('userManagement.resetPasswordSuccess'));
    }
  }, [resetPasswordSuccess]);

  const getSortIconByFieldName = (fieldName: string) => {
    const sortFieldName = pagination.sort;
    const order = pagination.order;
    if (sortFieldName !== fieldName) {
      return faSort;
    } else {
      return order === ASC ? faSortUp : faSortDown;
    }
  };

  const navigateTo = (value) => {
    navigate(value);
  };

  const getBadgeColor = (authority: string) => {
    if (authority === AUTHORITIES.ADMIN) {
      return 'color-3';
    } else if (authority.includes('IREX')) {
      return 'color-1';
    } else if (authority === AUTHORITIES.VALIDATOR) {
      return 'color-4';
    } else {
      return 'color-2';
    }
  }

  return (
    <div className="table-responsive">
        <h1 className="bordered" id="user-management-page-heading" data-cy="userManagementPageHeading">
          <Translate contentKey="userManagement.home.title" />
        </h1>
        <div className="spacer20"/>

        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="userManagement.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="new" className="btn btn-primary jh-create-entity">
            <FontAwesomeIcon icon="plus" /> <Translate contentKey="userManagement.home.createLabel">Create a new user</Translate>
          </Link>
        </div>
      <Table responsive>
        <thead>
          <tr>
            <th className="hand" onClick={sort('firstName')}>
              <Translate contentKey="userManagement.name" /> <FontAwesomeIcon icon={getSortIconByFieldName('firstName')} />
            </th>
            <th className="hand" onClick={sort('login')}>
              <Translate contentKey="userManagement.login" /> <FontAwesomeIcon icon={getSortIconByFieldName('login')} />
            </th>
            {/* <th className="hand" onClick={sort('email')}>
              <Translate contentKey="userManagement.email">Email</Translate> <FontAwesomeIcon icon={getSortIconByFieldName('email')} />
            </th> */}
            <th>
            <Translate contentKey="userManagement.status" />
            </th>

            <th>
              <Translate contentKey="userManagement.profiles" />
            </th>

            <th style={{width: 200}} />
          </tr>
        </thead>
        <tbody>
          {users.map((user, i) => (
            <tr id={user.login} key={`user-${i}`}>

              <td className='table-principal-text'>{user.firstName} {user.lastName}</td>
              <td>{user.login}</td>

              <td>
                <div className={`table-status pointer ${user.activated ? 'activated' : 'deactivated'}`} onClick={toggleActive(user)}>
                  <span className="status-circle"></span>
                  <span className='f-size-085'>{user.activated ? translate('userManagement.activated') : translate('userManagement.deactivated')}</span>
                </div>
              </td>

              <td>
                {user.authorities
                  ? user.authorities.map((authority, j) => (
                      <div key={`user-auth-${i}-${j}`}>
                        <span className={`table-badge ${getBadgeColor(authority)}`}>
                          {getRoleDisplayName(authority)}
                        </span>
                      </div>
                    ))
                  : null}
              </td>

              <td className="table-buttons-column background-f2" style={{width: 200}}>
                <div className="table-buttons-wrapper">
                                  
                  <span className='pointer view' onClick={() => navigateTo(`${user.login}`)}> 
                    <img src="/content/images/icons/eye-icon.svg" alt="view icon"/> 
                    <Translate contentKey="entity.action.view" />
                  </span>

                  <span className='pointer edit' onClick={() => navigateTo(`${user.login}/edit`)}> 
                    <img src="/content/images/icons/pencil-icon.svg" alt="edit icon"/> 
                    <Translate contentKey="entity.action.edit" />
                  </span>

                  {isTvetAdmin === false &&
                      <span className='pointer delete' onClick={() => navigateTo(`${user.login}/delete`)}>
                        <img src="/content/images/icons/times-circle-icon.svg" alt="delete icon"/>
                        <Translate contentKey="entity.action.delete" />
                      </span>
                    }
                
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {totalItems ? (
        <div className={users?.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={pagination.activePage} total={totalItems} itemsPerPage={pagination.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={pagination.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={pagination.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default UserManagement;
