import React, {useState, useEffect} from 'react';
import Survey from "./types"
import {Translate, translate} from 'react-jhipster';
import {useAppSelector, useAppDispatch} from "app/config/store";
import {Row, Collapse, Button, Badge, Modal, ModalHeader, ModalBody, Label, Alert, ModalFooter, Col} from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {getUserSurveys, getTempEditLink, getTempViewLink, checkIsAvailableToBeAnswered} from "app/modules/usersHome/user-home.reducer"; // Import Bootstrap CSS
import {SURVEYTODO_STATUS, SURVEY_STATUS_COLORS} from 'app/config/constants';
import LoadingSpinner from 'app/shared/components/LoadingSpinner';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faComment, faCircleXmark} from '@fortawesome/free-solid-svg-icons';
import { getSurveyTodoStatusDisplay } from 'app/shared/util/display-constants';

export const DataCollection = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const account = useAppSelector(state => state.authentication.account);
  const userSurveys = useAppSelector(state => state.userHomeSlice.surveys);
  const loading = useAppSelector(state => state.userHomeSlice.loading);

  const loadingLink = useAppSelector(state => state.userHomeSlice.loadingLink);
  const linkSuccess = useAppSelector(state => state.userHomeSlice.linkSuccess);
  const tempEditLink = useAppSelector(state => state.userHomeSlice.tempEditLink);
  const tempViewLink = useAppSelector(state => state.userHomeSlice.tempViewLink);
  const isAvailableToBeAnswered = useAppSelector(state => state.userHomeSlice.isAvailableToBeAnswered);
  const errorMessage = useAppSelector(state => state.userHomeSlice.errorMessage);

  const [tempLinkRequested, setTempLinkRequested] = useState(false);
  const [tempViewLinkRequested, setTempViewLinkRequested] = useState(false);

  const [collapseIsOpen, setCollapseIsOpen] = useState([]);
  const [surveyOpened, setSurveyOpened] = useState([]);
  const [todoIdSelected, setTodoIdSelected] = useState(0);
  const [todoUrlSelected, setTodoUrlSelected] = useState("");
  const [surveySelectedOpened, setSurveySelectedOpened] = useState(false);

  const [modalCommentIsOpen, setModalCommentIsOpen] = useState(false);
  const [modalDataSelected, setModalDataSelected] = useState("");
  const [modalReviewComentText, setModalReviewComentText] = useState("");
  const [modalErrorMessage, setModalErrorMessage] = useState("");

  const [modalErrorMessageIsOpen, setModalErrorMessageIsOpen] = useState(false);

  const [connectToSSE, setConnectToSSE] = useState(true);
  const [messageFromBE, setMessageFromBE] = useState('');
  const [pingMessage, setPingMessage] = useState('');
  let eventSource;

  const toggleCollapse = (index) => {
    const collapseCopy = [...collapseIsOpen];
    collapseCopy[index] = !collapseCopy[index];
    setCollapseIsOpen(collapseCopy);
  }

  useEffect(() => {
    if (account && account.login) {
      searchSurveys();
    }
  }, [account]);

  const searchSurveys = () => {
    setSurveyOpened([]);
    setTodoUrlSelected("");
    if (account && account.login) {
      dispatch(getUserSurveys(account.login));
    }
  }

  // SSE: Sent-Service Events
  // This is a way to push data from the server to the client
  useEffect(() => {

    const connectSSE = () => {
      eventSource = new EventSource('/api/sse/stream');

      // eventSource.addEventListener('init', (event) => {
      //   setInitMessage(event.data);
      // });

      eventSource.addEventListener('ping', (event) => {
        setPingMessage(event.data);
      });

      eventSource.addEventListener('koboResponseReceived', (event) => {
        setMessageFromBE(event.data);
        setTimeout(searchSurveys, 2000); // Refresh the surveys after 2 seconds
      });

      eventSource.onerror = (error) => {
        eventSource.close();
        setTimeout(connectSSE, 5000); // Try reconecting after 5 seconds
      };
    };

    if (connectToSSE) {
      connectSSE();
    }

    return () => {
      if (eventSource) {
        eventSource.close();
      }
    };
  }, []);


  // Group surveys by institution
  const surveysByInstitution = userSurveys.reduce((acc, surveyGroup: Survey[]) => {
    surveyGroup.forEach(survey => {
      const {institutionName} = survey;
      if (!acc[institutionName]) {
        acc[institutionName] = [];
      }
      acc[institutionName].push(survey);
    });
    return acc;
  }, {} as { [key: string]: Survey[] });

  const checkTodoIsAvailableToBeAnswered = (surveyToDoId, url) => {
    dispatch(checkIsAvailableToBeAnswered(surveyToDoId));
    setSurveySelectedOpened(false);
    setTodoUrlSelected(String(url));
    setTodoIdSelected(Number(surveyToDoId));
  };

  useEffect(() => {
    if (isAvailableToBeAnswered != null && loading === false && todoUrlSelected !== "") {
      if (isAvailableToBeAnswered) openSurvey();
      else {
        const errMessage = translate(`irexForsahApp.dataCollection.messages.openSurveyErrors.${errorMessage}`);
        setModalErrorMessage(errMessage.props.dangerouslySetInnerHTML.__html);
        setModalErrorMessageIsOpen(true);
      }
    }
  }, [isAvailableToBeAnswered, loading]);
  
  const openSurvey = () => {
    if (surveySelectedOpened || todoUrlSelected === "") return;
    
    setSurveyOpened([...surveyOpened, todoIdSelected]);
    window.open(todoUrlSelected, '_blank');
    setSurveySelectedOpened(true);
  }

  const callGetTempLinkEdit = (surveyKoboId, replyKoboId) => {
    setTempLinkRequested(true);
    dispatch(getTempEditLink({surveyKoboId, replyKoboId}));
  }

  const callGetTempViewLink = (surveyKoboId, replyKoboId) => {
    setTempViewLinkRequested(true);
    dispatch(getTempViewLink({surveyKoboId, replyKoboId}));
  }

  useEffect(() => {
    if (tempLinkRequested) {
      if (loadingLink === false && linkSuccess && tempEditLink) {
        setTempLinkRequested(false);
        window.open(tempEditLink, '_blank');
      }
    }
  }, [tempEditLink]);

  useEffect(() => {
    if (tempViewLinkRequested) {
      if (loadingLink === false && linkSuccess && tempViewLink) {
        setTempViewLinkRequested(false);
        window.open(tempViewLink, '_blank');
      }
    }
  }, [tempViewLink]);


  /* eslint-disable object-shorthand */
  const openCommentModal = (surveyName, surveyYear, reviewComment) => {
    const subtitle = translate('irexForsahApp.dataCollection.modal.subtitle', {
      surveyName: surveyName,
      surveyYear: surveyYear
    });
    setModalDataSelected(subtitle.props.dangerouslySetInnerHTML.__html);
    setModalReviewComentText(reviewComment);
    setModalCommentIsOpen(true);
  }


  const renderSurveyName = (survey) => {
    switch (survey.status) {
      case SURVEYTODO_STATUS.PENDING:
        return (
          <span className="link survey-title pointer" onClick={() => checkTodoIsAvailableToBeAnswered(survey.todoId, survey.link)}>
            {survey.surveyName}
          </span>
        );
      case SURVEYTODO_STATUS.REJECTED:
        return (
          <span className="link pointer" onClick={() => callGetTempLinkEdit(survey.surveyKoboIdStr, survey.replyKoboId)}>
            {survey.surveyName}
          </span>
        );
      case SURVEYTODO_STATUS.ANSWERED:
      case SURVEYTODO_STATUS.RE_ANSWERED:
        return (
          <span className="link pointer" onClick={() => callGetTempViewLink(survey.surveyKoboIdStr, survey.replyKoboId)}>
            {survey.surveyName}
          </span>
        );
      default:
        return <span>{survey.surveyName}</span>;
    }
  };

  return (
    <div>
      {isAuthenticated && account && (
        <div>
          <h1 className="bordered">
            <Translate contentKey="global.form.dataCollection.title"/>
          </h1>

          <div className="spacer20"/>

          {loading && <LoadingSpinner/>}
          {loadingLink &&
            <LoadingSpinner message={translate('irexForsahApp.dataCollection.messages.loadingTempLink')}/>}

          <Row>
            <input type='hidden' value={`BE message: ${messageFromBE}`}/>
            <div className="col banner">
              <p><span><Translate contentKey="main.welcome" interpolate={{login: account.login}} /></span>
              </p>
            </div>
          </Row>

          <div className="d-flex justify-content-end mt-3">
            <Button className="me-2" color="info" onClick={searchSurveys} disabled={loading}>
              <FontAwesomeIcon icon="sync" spin={loading}/>{' '}
              <Translate contentKey="irexForsahApp.institution.home.refreshListLabel" />
            </Button>
          </div>

          {Object.entries(surveysByInstitution).map(([institution, surveys], index) => (
            <div key={institution} className="card survey-card my-3 mb-2">
              <div className="card-header" onClick={() => toggleCollapse(index)}>
                <h3 className="pt-2">
                  <span className="heading-label"><Translate contentKey="irexForsahApp.dataCollection.home.institution" /> /</span> {institution}
                  <Badge color="secondary" pill className="badge-custom">{(surveys as Survey[]).length}</Badge>
                </h3>
              </div>

              <Collapse isOpen={collapseIsOpen[index]}>
                <div className="card-body sub-heading pb-2 pt-2">
                  <Row>
                    <Col md={5}><Translate
                      contentKey="irexForsahApp.dataCollection.table.survey" /></Col>
                    <Col md={1}>
                      <Translate contentKey="irexForsahApp.dataCollection.table.status" />
                    </Col>
                    <Col md={1}>
                      <Translate contentKey="irexForsahApp.dataCollection.table.locatorId" />
                    </Col>
                    <Col md={3}><Translate
                      contentKey="irexForsahApp.dataCollection.table.description" /></Col>
                    <Col md={2}><Translate
                      contentKey="irexForsahApp.dataCollection.table.reviewComment" /></Col>
                  </Row>
                </div>
                <div className="card-body pb-2 pt-3 border-0">
                  {(surveys as Survey[]).map((survey, i) => (

                    <Row key={`${survey.institutionName}-${survey.surveyName}-${i}`} className="mb-2 pb-2">
                      <Col md={5}>
                        <h5>
                          {surveyOpened.includes(survey.todoId) ? (
                            <span>
                              <FontAwesomeIcon icon="ban" className='orange-text' style={{marginRight: 10}}/>{' '}
                              <Badge color="info" pill>{survey.todoYear}</Badge>{' '}
                              {survey.surveyName}
                            </span>
                          ) : (
                            <>
                              <Badge color="info">{survey.todoYear}</Badge>{' '}
                              {renderSurveyName(survey)}
                            </>
                          )}
                        </h5>
                      </Col>

                      <Col md={1}>
                        <Badge color={SURVEY_STATUS_COLORS[survey.status] || "default"}>
                          {getSurveyTodoStatusDisplay(survey.status)}
                        </Badge>
                      </Col>

                      <Col md={1}>
                        <Badge color="info" style={{marginLeft: 5}}> {survey.locatorId || ''} </Badge>
                      </Col>

                      <Col md={3}>
                        <p className="comment">{survey.comment}</p>
                      </Col>

                      <Col md={2} className="justify-content-center">
                        {
                          survey.reviewComment && survey.reviewComment !== '' ? (
                            <>
                              <FontAwesomeIcon icon={faComment} style={{color: '#a9c8c8', cursor: 'pointer'}}
                                               onClick={() => openCommentModal(survey.surveyName, survey.todoYear, survey.reviewComment)}/>
                            </>
                          ) : (
                            <p className="small-text">
                              <Translate contentKey="irexForsahApp.dataCollection.messages.noComments" />
                            </p>
                          )
                        }
                      </Col>
                      {surveyOpened.includes(survey.todoId) && (
                        <p className='small-text orange-text'><em> <Translate contentKey="irexForsahApp.dataCollection.messages.itemBlocked" /></em></p>
                      )}
                    </Row>
                  ))}
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      )}


      <Modal isOpen={modalCommentIsOpen} backdrop={true} size='lg'>
        <ModalHeader>
          <FontAwesomeIcon icon={faComment}/> {' '} 
          <Translate contentKey="irexForsahApp.dataCollection.table.reviewComment" />
        </ModalHeader>
        <ModalBody>
          <Label dangerouslySetInnerHTML={{__html: modalDataSelected}}/>

          <Alert color="secondary">
            <em><Label dangerouslySetInnerHTML={{__html: modalReviewComentText}}/></em>
          </Alert>
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={() => setModalCommentIsOpen(false)}>
            <Translate contentKey="irexForsahApp.dataCollection.modal.close"/>
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={modalErrorMessageIsOpen} backdrop={true}>
        <ModalHeader>
          <FontAwesomeIcon icon={faCircleXmark}/> {' '} <Translate
          contentKey="irexForsahApp.dataCollection.modal.openSurvey.title" />
        </ModalHeader>
        <ModalBody>
          <Label dangerouslySetInnerHTML={{__html: modalErrorMessage}}/>
        </ModalBody>

        <ModalFooter>
          <Button color="secondary" onClick={() => setModalErrorMessageIsOpen(false)}>
            <Translate contentKey="irexForsahApp.dataCollection.modal.close"/>
          </Button>
        </ModalFooter>
      </Modal>


      

    </div>
  )
}
export default DataCollection;
