import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity as getInstitutionEntity } from '../institution.reducer';
import { getEntity } from './surveyToDo.reducer';

export const InstitutionSurveyToDoDetails = () => {
  const dispatch = useAppDispatch();

  const { id, surveyToDoId } = useParams();

  useEffect(() => {
    dispatch(getInstitutionEntity(id));
    dispatch(getEntity(surveyToDoId));
  }, []);

  const surveyToDoEntity = useAppSelector(state => state.surveyToDo.entity);
  const loading = useAppSelector(state => state.surveyToDo.loading);


  return (
    <Row>
      <Col>
        <h2 data-cy="institutionDetailsHeading">

          <Translate contentKey="irexForsahApp.surveyToDos.detailsView.title">Survey To Do Details</Translate>

          <div className="d-flex justify-content-end">
            {surveyToDoEntity.survey && surveyToDoEntity.survey.type && (
              <Button tag={Link} to={`/usersHome/institution/${id}/survey-to-dos/${surveyToDoId}/edit?surveyType=${surveyToDoEntity.survey.type}`} replace color="primary" className='me-2'>
                <FontAwesomeIcon icon="pencil-alt" />{' '}
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.edit">Edit</Translate>
                </span>
              </Button>
            )}

            <Link to={`/usersHome/institution/${id}/survey-to-dos`} className="btn btn-secondary pad-t-15 me-2"
                id="jh-create-entity" data-cy="entityCreateButton">
              <FontAwesomeIcon icon="arrow-left" />{' '}
              <Translate contentKey="irexForsahApp.surveyToDos.buttons.back">Back</Translate>
            </Link>
          </div>

        </h2>

        <dl className="jh-entity-details">

        {
          surveyToDoEntity.id !== undefined ? (
            <div>
              <Row>
                <Col md="2">
                  <dt>
                    <span id="id">
                      <Translate contentKey="global.field.id">ID</Translate>
                    </span>
                  </dt>
                  <dd>{surveyToDoEntity.id}</dd>
                </Col>

                <Col md="2">
                <dt>
                  <span id="status">
                    <Translate contentKey="irexForsahApp.surveyToDos.detailsView.status">Status</Translate>
                  </span>
                </dt>
                <dd>{surveyToDoEntity.status}</dd>
                </Col>

                <Col md="8">
                  {
                    surveyToDoEntity.institution && (
                      <div>
                        <dt>
                          <span id="institution">
                            <Translate contentKey="irexForsahApp.surveyToDos.detailsView.institution">Institution</Translate>
                          </span>
                        </dt>
                        <dd>{surveyToDoEntity.institution.name}</dd>
                      </div>
                    )
                  }

                </Col>
              </Row>

              <Row className='mt-10'>

                <Col md="2">
                  <dt>
                    <span id="id">
                      <Translate contentKey="irexForsahApp.surveyToDos.detailsView.locatorId">Locator ID</Translate>
                    </span>
                  </dt>
                  <dd>{surveyToDoEntity.locatorId}</dd>
                </Col>

                <Col md="10">
                  {
                    surveyToDoEntity.survey && (
                      <div>
                        <dt>
                          <span id="survey">
                            <Translate contentKey="irexForsahApp.surveyToDos.detailsView.survey">Survey</Translate>
                          </span>
                        </dt>
                        <dd>{surveyToDoEntity.year} | {surveyToDoEntity.survey.name}</dd>
                      </div>
                    )
                  }
                </Col>

              </Row>





                <dt className='mt-10'>
                  <span id="comment">
                    <Translate contentKey="irexForsahApp.surveyToDo.comments">Comment</Translate>
                  </span>
                </dt>
                <dd>{surveyToDoEntity.comment}</dd>

                <dt className='mt-10'>
                  <span id="link">
                    <Translate contentKey="irexForsahApp.surveyToDos.detailsView.link">Link</Translate>
                  </span>
                </dt>
                <dd><a href={surveyToDoEntity.link} target='_blank' rel="noreferrer">{surveyToDoEntity.link}</a></dd>

              </div>
            )
            : (
              <div className="alert alert-warning">
                <Translate contentKey="irexForsahApp.surveyToDos.detailsView.notFound">No Survey To Do found</Translate>
            </div>
          )}

        </dl>
      </Col>
    </Row>
    );
  };

  export default InstitutionSurveyToDoDetails;
