import survey from 'app/entities/survey/survey.reducer';
import question from 'app/entities/other-reducers/question.reducer';
import reply from 'app/entities/other-reducers/reply.reducer';
import replyDetails from 'app/entities/other-reducers/reply-details.reducer';
import institution from 'app/entities/institution/institution.reducer';
import surveyVersion from 'app/entities/other-reducers/survey-version.reducer';
import replyAttachment from 'app/entities/other-reducers/reply-attachment.reducer';
import questionOption from 'app/entities/other-reducers/question-option.reducer';
import surveyToDo from 'app/entities/institution/survey-to-do/surveyToDo.reducer';
import userHomeSlice from "app/modules/usersHome/user-home.reducer";
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */
import region from 'app/entities/other-reducers/region-reducer';
import governorate from 'app/entities/other-reducers/governorate-reducer';
import availableFacilities from 'app/entities/other-reducers/availableFacilities-reducer';
import accreditationBody from 'app/entities/other-reducers/accreditationBodies-reducer';
import programLevel from 'app/entities/other-reducers/programsLevel-reducer';
import trainingProgram from 'app/entities/other-reducers/trainingProgram-reducer';
import dataValidationReducer from 'app/modules/usersHome/data-validation.reducer';
import syncConfigurationReducer from 'app/modules/syncConfiguration/sync-configuration-reducer';

const entitiesReducers = {
  survey,
  question,
  reply,
  replyDetails,
  institution,
  surveyVersion,
  replyAttachment,
  questionOption,
  surveyToDo,
  userHomeSlice,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  region,
  governorate,
  availableFacilities,
  accreditationBody,
  programLevel,
  trainingProgram,
  dataValidationReducer,
  syncConfigurationReducer
};

export default entitiesReducers;
