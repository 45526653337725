import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, UncontrolledTooltip, Row, Col, Badge } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntity } from './survey.reducer';
import { getSurveyTypeDisplay } from 'app/shared/util/display-constants';

export const SurveyDetail = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();

  useEffect(() => {
    dispatch(getEntity(id));
  }, []);

  const surveyEntity = useAppSelector(state => state.survey.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="surveyDetailsHeading">
          <Translate contentKey="irexForsahApp.survey.detail.title">Survey</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{surveyEntity.id}&nbsp;
            {surveyEntity.activated ? (
              <Badge color="success">
                <Translate contentKey="userManagement.activated">Activated</Translate>
              </Badge>
            ) : (
              <Badge color="danger">
                <Translate contentKey="userManagement.deactivated">Deactivated</Translate>
              </Badge>
            )}
          </dd>
          <dt>
            <span id="idCode">
              <Translate contentKey="irexForsahApp.survey.idCode">Id Code</Translate>
            </span>
            <UncontrolledTooltip target="idCode">
              <Translate contentKey="irexForsahApp.survey.help.idCode" />
            </UncontrolledTooltip>
          </dt>
          <dd>{surveyEntity.idCode}</dd>
          <dt>
            <span id="publicLink">
              <Translate contentKey="irexForsahApp.survey.publicLink">Public Link</Translate>
            </span>
          </dt>
          <dd>{surveyEntity.publicLink}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="irexForsahApp.survey.name">Name</Translate>
            </span>
          </dt>
          <dd>{surveyEntity.name}</dd>
          <dt>
            <span id="section">
              <Translate contentKey="irexForsahApp.survey.section">section</Translate>
            </span>
          </dt>
          <dd>{surveyEntity.sectionNumber}</dd>
          <dt>
            <span id="type">
              <Translate contentKey="irexForsahApp.survey.type">Type</Translate>
            </span>
          </dt>
          <dd>{getSurveyTypeDisplay(surveyEntity.type)}</dd>
          <dt>
            <Translate contentKey="irexForsahApp.survey.institution">Institution</Translate>
          </dt>
          <dd>
            {surveyEntity.institutions
              ? surveyEntity.institutions.map((val, i) => (
                  <span key={val.id}>
                    <em>{val.name}</em>
                    {surveyEntity.institutions && i === surveyEntity.institutions.length - 1 ? '' : ', '}
                  </span>
                ))
              : null}
          </dd>
        </dl>
        <Button tag={Link} to="/usersHome/survey" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/usersHome/survey/${surveyEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default SurveyDetail;
