import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IRadarChartsModel} from "app/shared/model/charts.model";
import {IFilter} from 'app/shared/model/forsah-filter.model';


const initialState: IRadarChartsModel = {
  loading: false,
  errorMessage: null,
  radarData: [],
}
const radarUrl = 'api/charts/radar';

export const getRadarData = createAsyncThunk(
  'charts/fetch_radar_data',
  async (filter: IFilter) => {
    return axios.post<IRadarChartsModel>(radarUrl, filter);
  }
);

export const radarSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(getRadarData.fulfilled, (state, action) => {
        state.loading = false;
        state.radarData = [];
        state.radarData = action.payload.data;
      })
      .addCase(getRadarData.pending, state => {
        state.loading = true;
      })
      .addCase(getRadarData.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
  initialState,
  name: 'radarChart',
  reducers: {
    reset() {
      return initialState;
    },
  },
});

export default radarSlice.reducer;
