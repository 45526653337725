import dayjs from 'dayjs';
import { ISurvey } from 'app/shared/model/survey.model';
import { IUser } from 'app/shared/model/user.model';
import { IInstitution } from 'app/shared/model/institution.model';
import { IReplyDetails } from 'app/shared/model/reply-details.model';
import { IReplyAttachment } from 'app/shared/model/reply-attachment.model';

export interface IReply {
  id?: number;
  date?: dayjs.Dayjs;
  reviewDate?: dayjs.Dayjs | null;
  reviewComment?: string | null;
  locatorId?: number | null;
  koboIdResponse?: number | null;
  koboIdStrResponse?: string | null;
  survey?: ISurvey;
  reviewUser?: IUser | null;
  submitterUser?: IUser | null;
  institution?: IInstitution | null;
  replyDetails?: IReplyDetails[] | null;
  replyAttachments?: IReplyAttachment[] | null;
}

export const defaultValue: Readonly<IReply> = {};
