import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Badge, Col, Card, CardHeader, ListGroup, ListGroupItem, CardBody, CardTitle } from 'reactstrap';
import { Translate, translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT } from 'app/config/constants';
import { languages } from 'app/config/translation';
import { getUser } from './user-management.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getRoleDisplayName } from 'app/shared/util/display-constants';

export const UserManagementDetail = () => {
  const dispatch = useAppDispatch();

  const [creationData, setCreationData] = useState('');
  const [lastModifiedData, setLastModifiedData] = useState('');

  const { login } = useParams<'login'>();

  useEffect(() => {
    dispatch(getUser(login));
  }, []);

  const user = useAppSelector(state => state.userManagement.user);

  function formatDate(value: string | Date | undefined, formatStr: string = APP_DATE_FORMAT): string {
    if (!value) return '';
  
    try {
      const date = new Date(value);
      const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      };
      return new Intl.DateTimeFormat('en-US', options).format(date);
    } catch (error) {
      return '';
    }
  }

  useEffect(() => {
    if (user) {
      const lastModifiedDate = user.lastModifiedDate ?  formatDate(user.lastModifiedDate) : ''; 
      const lastModifyDataHTML = translate('userManagement.lastModifyData', { lastModifiedBy: user.lastModifiedBy, lastModifiedDate });
      setLastModifiedData(lastModifyDataHTML.props.dangerouslySetInnerHTML.__html);

      const creationDate = user.createdDate ?  formatDate(user.createdDate) : '';
      const creationDataHTML = translate('userManagement.creationData', { createdBy: user.createdBy, createdDate: creationDate });
      setCreationData(creationDataHTML.props.dangerouslySetInnerHTML.__html);
    }
  }, [user]);

  return (
    <div>
      <h2>
        <Translate contentKey="userManagement.detail.title">User</Translate> [<strong>{user.login}</strong>]&nbsp;
            {user.activated ? (
              <Badge color="success" className="small-badge">
                <Translate contentKey="userManagement.activated">Activated</Translate>
              </Badge>
            ) : (
              <Badge color="danger" className="small-badge">
                <Translate contentKey="userManagement.deactivated">Deactivated</Translate>
              </Badge>
            )}
      </h2>

      <Row style={{marginTop: 30}}>
        <Col md="9">
            <Row>
              <Col md="5">
                <dt>
                  <Translate contentKey="userManagement.fullName">fullName</Translate>
                </dt>
                <dd>{user.firstName} {user.lastName}</dd>
              </Col>

              <Col md="3">
                <dt>
                  <Translate contentKey="userManagement.profiles">Profiles</Translate>
                </dt>
                <dd>
                  <ul className="list-unstyled">
                    {user.authorities
                      ? user.authorities.map((authority, i) => (
                          <li key={`user-auth-${i}`}>
                            <Badge color="info">{getRoleDisplayName(authority)}</Badge>
                          </li>
                        ))
                      : null}
                  </ul>
                </dd>
              </Col>

              <Col md="4">
                <dt>
                  <Translate contentKey="userManagement.langKey">Lang Key</Translate>
                </dt>
                <dd>{user.langKey ? languages[user.langKey].name : undefined}</dd>
              </Col>

            </Row>

            <Row style={{marginTop: 10}}>
              <Col md="5">
                <dt>
                  <Translate contentKey="userManagement.email">Email</Translate>
                </dt>
                <dd>{user.email}</dd>
              </Col>

              <Col md="3">
                <dt>
                  <Translate contentKey="userManagement.creation">creation</Translate>
                </dt>
                <dd dangerouslySetInnerHTML={{ __html: creationData}}></dd>
              </Col>

              <Col md="4">
                <dt>
                  <Translate contentKey="userManagement.lastModify">lastModify</Translate>
                </dt>
                <dd dangerouslySetInnerHTML={{ __html: lastModifiedData}}>
                </dd>
              </Col>

            </Row>
        </Col>

        <Col md="3">
          <Card style={{ width: '100%' }} >
            <CardBody>
              <CardTitle tag="h5">
                Institutions
              </CardTitle>
            </CardBody>
            

            <ListGroup flush>
              {
                user.linkedInstitutions && user.linkedInstitutions.length > 0 ? (
                   user.linkedInstitutions.map((institution, i) => (
                  <ListGroupItem key={`user-institution-${i}`}>
                    {institution}
                  </ListGroupItem>
                ))
                ) : (
                  <ListGroupItem>
                    <Translate contentKey="userManagement.noInstitutions">No Institutions</Translate>
                  </ListGroupItem>
                )
              }
              
            </ListGroup>
          </Card>
        </Col>
      </Row>

      
      <Button tag={Link} to="/usersHome/user-management" replace color="info">
        <FontAwesomeIcon icon="arrow-left" />{' '}
        <span className="d-none d-md-inline">
          <Translate contentKey="entity.action.back">Back</Translate>
        </span>
      </Button>
    </div>
  );
};

export default UserManagementDetail;
