import React, { useEffect, useState } from 'react';
import { Translate, translate, ValidatedField, ValidatedForm, isEmail } from 'react-jhipster';
import { Button, Alert, Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';

import { handlePasswordResetInit, reset } from '../password-reset.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const PasswordResetInit = () => {
  const dispatch = useAppDispatch();
  const [resetSuccess, setResetSuccess] = useState(false);

  useEffect(
    () => () => {
      dispatch(reset());
    },
    [],
  );

  const handleValidSubmit = ({ email }) => {
    dispatch(handlePasswordResetInit(email));
  };

  const successMessage = useAppSelector(state => state.passwordReset.successMessage);

  useEffect(() => {
    if (successMessage) {
      setResetSuccess(true);
      toast.success(translate(successMessage));
    }
  }, [successMessage]);

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h1>
            <Translate contentKey="reset.request.title">Reset your password</Translate>
          </h1>
          {
            resetSuccess ? (
              <Alert color="success">
                <p>
                  <Translate contentKey="reset.request.messages.success">If the email is registered, you will receive a password reset link.</Translate>
                </p>
              </Alert>
            ) : (
              <Alert color="warning">
                <p>
                  <Translate contentKey="reset.request.messages.info">Enter the email address you used to register</Translate>
                </p>
              </Alert>
            )
          }
          
          <ValidatedForm onSubmit={handleValidSubmit}>
            <ValidatedField
              name="email"
              label={translate('global.form.email.label')}
              placeholder={translate('global.form.email.placeholder')}
              type="email"
              validate={{
                required: { value: true, message: translate('global.messages.validate.email.required') },
                minLength: { value: 5, message: translate('global.messages.validate.email.minlength') },
                maxLength: { value: 254, message: translate('global.messages.validate.email.maxlength') },
                validate: v => isEmail(v) || translate('global.messages.validate.email.invalid'),
              }}
              data-cy="emailResetPassword"
            />

            {
            resetSuccess ? (
              <Alert color="success">
                <p>
                  <Translate contentKey="reset.request.messages.success2">If the email is registered...</Translate>
                </p>
              </Alert>
            ) : (
              <Button color="primary" type="submit" data-cy="submit">
                <Translate contentKey="reset.request.form.button">Reset password</Translate>
              </Button>
            )
          }

          </ValidatedForm>
        </Col>
      </Row>
    </div>
  );
};

export default PasswordResetInit;
