import './home.scss';
import '../../../content/css/content.scss';

import React, { useEffect } from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Row, Col, Alert} from 'reactstrap';
import { LoginSection } from '../login/login-section';

import { useAppSelector } from 'app/config/store';
import { useNavigate } from 'react-router';

export const Home = () => {
  const account = useAppSelector(state => state.authentication.account);
  const loginError = useAppSelector(state => state.authentication.loginError);
  const loginLoading = useAppSelector(state => state.authentication.loading);
  const navigate = useNavigate();

  useEffect(() => {
    if (account?.login) {
      navigate('/usersHome');
    }
  }, [account]);


  return (
    <Row>
      <Row className="banner">
        <Col md="5" className="col">
          <h1 className="px-3"><Translate contentKey="home.pagetitle" /></h1>
          </Col>
          <Col md="7" className="col banner-img">
          </Col>
      </Row>
    <Row className="home-content px-5 py-5">
      <Col md="6" className="col-left">
        <h2><Translate contentKey="home.introtitle" /></h2>
        <p><Translate contentKey="home.intro" /></p>      

        <div className="partners-logos main-partners">
          <img src="content/images/usaid-logo.svg" alt="USAID Logo" style={{maxWidth: "25%"}}/>
          <img src="content/images/irex-logo.svg" alt="Irex Logo" style={{maxWidth: "15%"}}/>           
        </div>

        <h2 className='partners-title'><Translate contentKey="home.partners" /></h2>

        <div className="partners-logos partners-home">
          <img src="content/images/dai-logo.svg" alt="DAI Logo"/>
          <img src="content/images/va-tech-logo.svg" alt="VA tech Logo"/>
          <img src="content/images/dg-logo.svg" alt="DG Logo"/>
          <img src="content/images/bwfp-logo.png" alt="Business Women Forum Palestine Logo"/>
          <img src="content/images/bethlehem-chamber-of-commerce.png" alt="Bethlehem Chamger of Commerce and Industry Logo"/>
          <img src="content/images/partner-logo.png" alt="Partner Logo"/>
        </div>

        <div className='partnersText'>
          <p> <Translate contentKey='home.partnersText' /> </p>
        </div>
      </Col>
      <Col md="6" className="col-right">
        <h2> Log in </h2>

        <LoginSection loginError={loginError} loginLoading={loginLoading}/>

        {account?.login ? (
          <div>
            <Alert color="success">
              <Translate contentKey="home.logged.message" interpolate={{ username: account.login }}>
                You are logged in as user {account.login}.
              </Translate>
            </Alert>
          </div>
        ) : (
          <div>
          </div>
        )}

      </Col>
    </Row>

    </Row>
  );
};

export default Home;
