import { ReducersMapObject } from '@reduxjs/toolkit';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import administration from 'app/modules/administration/administration.reducer';
import userManagement from 'app/modules/administration/user-management/user-management.reducer';
import activate from 'app/modules/account/activate/activate.reducer';
import password from 'app/modules/account/password/password.reducer';
import settings from 'app/modules/account/settings/settings.reducer';
import passwordReset from 'app/modules/account/password-reset/password-reset.reducer';
import entitiesReducers from 'app/entities/reducers';
import applicationProfile from './application-profile';
import authentication from './authentication';
import locale from './locale';
import radarChartsSlice from 'app/modules/charts/radar-chart-reducer';
import barChartSlice from "app/modules/charts/bar-chart-reducer";
import filterSlice from "app/shared/filter/filter-component-reducer";
import tableScoringMatrixReducer from 'app/modules/visualizations/tables/table-scoring-matrix-reducer';
import tableReviewTemplateReducer from 'app/modules/visualizations/tables/table-review-template-reducer';
import tableBandingCriteriaSlice from 'app/modules/visualizations/tables/table-banding-criteria-reducer';
import assessmentReportReducer from 'app/modules/visualizations/reports/assessment-report-reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const rootReducer: ReducersMapObject = {
  authentication,
  locale,
  applicationProfile,
  administration,
  userManagement,
  activate,
  passwordReset,
  password,
  settings,
  loadingBar,
  radarChartsSlice,
  barChartSlice,
  filterSlice,
  tableScoringMatrixReducer,
  tableReviewTemplateReducer,
  tableBandingCriteriaSlice,
  assessmentReportReducer,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  ...entitiesReducers,
};

export default rootReducer;
