import axios from 'axios';
import { createAsyncThunk, isFulfilled } from '@reduxjs/toolkit';
import { ASC } from 'app/shared/util/pagination.constants';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ITrainingProgram, defaultValue } from 'app/shared/model/trainingProgram.model';

const initialState: EntityState<ITrainingProgram> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
};

const apiUrl = 'api/training-program-economic-sectors';

// Actions

export const getEntities = createAsyncThunk('trainingProgram/fetch_entity_list', async ({ sort }: IQueryParams) => {
  const requestUrl = `${apiUrl}?${sort ? `sort=${sort}&` : ''}cacheBuster=${new Date().getTime()}`;
  return axios.get<ITrainingProgram[]>(requestUrl);
});

export const getEntity = createAsyncThunk('trainingProgram/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ITrainingProgram>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);



// slice

export const TrainingProgramSlice = createEntitySlice({
  name: 'trainingProgram',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data.sort((a, b) => {
            if (!action.meta?.arg?.sort) {
              return 1;
            }
            const order = action.meta.arg.sort.split(',')[1];
            const predicate = action.meta.arg.sort.split(',')[0];
            return order === ASC ? (a[predicate] < b[predicate] ? -1 : 1) : b[predicate] < a[predicate] ? -1 : 1;
          }),
        };
      });
  },
});

export const { reset } = TrainingProgramSlice.actions;

// Reducer
export default TrainingProgramSlice.reducer;
