import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, FormGroup, Label, Input, Card, CardBody, CardTitle, CardSubtitle, CardText, CardHeader, Alert, CardImg } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faTimes, faSync, faHandPointer, faTrash } from '@fortawesome/free-solid-svg-icons';

import { extractIdsAsObj } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getUsers } from 'app/modules/administration/user-management/user-management.reducer';
import { getEntity, updateEntity, createEntity, reset } from './institution.reducer';
import { getEntities as getRegions } from 'app/entities/other-reducers/region-reducer';
import { getEntities as getGovernorates } from 'app/entities/other-reducers/governorate-reducer';
import { getEntities as getAvailableFacilities } from 'app/entities/other-reducers/availableFacilities-reducer';
import { getEntities as getAccreditationBodies} from 'app/entities/other-reducers/accreditationBodies-reducer';
import { getEntities as getProgramLevels } from 'app/entities/other-reducers/programsLevel-reducer';
import { getEntities as getTrainingPrograms } from 'app/entities/other-reducers/trainingProgram-reducer';

import { toast } from 'react-toastify';
import DualListbox from 'app/shared/components/DualListbox';
import { ListboxItem, transformUserListToListboxItems } from 'app/shared/util/ListboxItem';
import FieldFormWithValidation from 'app/shared/components/FieldFormWithValidation';
import { set } from 'lodash';
import LoadingSpinner from 'app/shared/components/LoadingSpinner';

export const InstitutionUpdate = ({ isIrexAdmin = false }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [acronym, setAcronym] = useState('');
  const [address, setAddress] = useState('');
  const [otherLocations, setOtherLocations] = useState('');
  const [focalPointName, setFocalPointName] = useState('');
  const [email, setEmail] = useState('');
  const [mobilePhone, setMobilePhone] = useState('');
  const [telephone, setTelephone] = useState('');
  const [entityUsers, setEntityUsers] = useState([]);
  const [yearEstablishment, setYearEstablishment] = useState('');
  const [totalSurfaceArea, setTotalSurfaceArea] = useState('');
  const [numberPrograms, setNumberPrograms] = useState('');
  const [numberFullTimeStudents, setNumberFullTimeStudents] = useState('');
  const [numberPartTimeStudents, setNumberPartTimeStudents] = useState('');
  const [comment, setComment] = useState('');

  const [selectedRegionOption, setSelectedRegionOption] = useState(1);
  const [selectedGovernorateOption, setSelectedGovernorateOption] = useState(1);
  const [selectedAvailableFacilitiesOptions, setSelectedAvailableFacilitiesOptions] = useState([]);
  const [selectedAccreditationBodyOption, setSelectedAccreditationBodyOption] = useState(1);
  const [selectedProgramLevelOption, setSelectedProgramLevelOption] = useState(1);
  const [selectedTrainingProgramOptions, setSelectedTrainingProgramOptions] = useState([]);
  const [selectedAgeGroupOptions, setSelectedAgeGroupOptions] = useState([]);
  const [selectedAgeGroupOptionsTxt, setSelectedAgeGroupOptionsTxt] = useState("");

  const [regionChanged, setRegionChanged] = useState(false);
  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>({});

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const users = useAppSelector(state => state.userManagement.users);
  const institutionEntity = useAppSelector(state => state.institution.entity);
  const loading = useAppSelector(state => state.institution.loading);
  const updating = useAppSelector(state => state.institution.updating);
  const updateSuccess = useAppSelector(state => state.institution.updateSuccess);
  const regions = useAppSelector(state => state.region.entities);
  const governorates = useAppSelector(state => state.governorate.entities);
  const availableFacilities = useAppSelector(state => state.availableFacilities.entities);
  const accreditationBodies = useAppSelector(state => state.accreditationBody.entities);
  const programLevels = useAppSelector(state => state.programLevel.entities);
  const trainingPrograms = useAppSelector(state => state.trainingProgram.entities);
  const ageGroups = ['10-19', '20-29', '30-39', '40-49', '50-59', '60+'];

  const handleClose = () => {
    navigate('/usersHome/institution');
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    if (isIrexAdmin) { dispatch(getUsers({})); }
    dispatch(getRegions({}));
    dispatch(getAvailableFacilities({}));
    dispatch(getAccreditationBodies({}));
    dispatch(getProgramLevels({}));
    dispatch(getTrainingPrograms({}));
  }, []);

  useEffect(() => {
    if (institutionEntity && loading === false) {
      setName(institutionEntity.name);
      setAcronym(institutionEntity.acronym);
      setAddress(institutionEntity.address ? institutionEntity.address : '');
      setOtherLocations(institutionEntity.otherLocations ? institutionEntity.otherLocations : '');
      setFocalPointName(institutionEntity.focalPointName ? institutionEntity.focalPointName : '');
      setEmail(institutionEntity.email ? institutionEntity.email : '');
      setMobilePhone(institutionEntity.mobilePhone ? institutionEntity.mobilePhone : '');
      setTelephone(institutionEntity.telephone ? institutionEntity.telephone : '');
      setYearEstablishment(institutionEntity.yearEstablishment ? institutionEntity.yearEstablishment.toString() : '');
      setTotalSurfaceArea(institutionEntity.totalSurfaceArea ? institutionEntity.totalSurfaceArea.toString() : '');
      setNumberPrograms(institutionEntity.numberPrograms ? institutionEntity.numberPrograms.toString() : '');
      setNumberFullTimeStudents(institutionEntity.numberFullTimeStudents ? institutionEntity.numberFullTimeStudents.toString() : '');
      setNumberPartTimeStudents(institutionEntity.numberPartTimeStudents ? institutionEntity.numberPartTimeStudents.toString() : '');
      setComment(institutionEntity.comment ? institutionEntity.comment : '');

      setSelectedRegionOption(institutionEntity.region?.id);
      if (institutionEntity.governorate) {
        setSelectedGovernorateOption(institutionEntity.governorate.id);
      }
      if(institutionEntity.availableFacilities) {
        setSelectedAvailableFacilitiesOptions(institutionEntity.availableFacilities.map(f => f.id));
      }
      setSelectedAccreditationBodyOption(institutionEntity.accreditationBody?.id);
      setSelectedProgramLevelOption(institutionEntity.programLevel?.id);
      if (institutionEntity.trainingProgramEconomicSectors) {
        setSelectedTrainingProgramOptions(institutionEntity.trainingProgramEconomicSectors.map(p => p.id));
      }
      setSelectedAgeGroupOptions(ungroupAgeGroups(institutionEntity.ageGroups));
      setSelectedAgeGroupOptionsTxt(institutionEntity.ageGroups);

      setEntityUsers(institutionEntity.users);
    }
  }, [institutionEntity]);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  useEffect(() => {
    if (isNew) {
      const firstRegion = regions[0];
      if (firstRegion) {
        setSelectedRegionOption(firstRegion.id);
      }
    }
  }, [regions]);

  useEffect(() => {
    if (governorates.length > 0) {
      if (isNew || (!isNew && regionChanged)) {
        const firstGovernorate = governorates[0];
        setSelectedGovernorateOption(firstGovernorate.id);
      }
    } else {
      setSelectedGovernorateOption(null);
    }
  }, [governorates]);

  useEffect(() => {
    if (isNew) {
      const firstAccBody = accreditationBodies[0];
      if (firstAccBody) {
        setSelectedAccreditationBodyOption(firstAccBody.id);
      }
    }
  }, [accreditationBodies]);

  useEffect(() => {
    if (isNew) {
      const firstProgramLevel = programLevels[0];
      if (firstProgramLevel) {
        setSelectedProgramLevelOption(firstProgramLevel.id);
      }
    }
  }, [programLevels]);

  useEffect(() => {
    if (selectedRegionOption) {
      dispatch(getGovernorates(selectedRegionOption));
    }
  }, [selectedRegionOption]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (!isFormValid()) {
      toast.error(translate('irexForsahApp.institution.validations.haveErrors'));
      return;
    }

    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...institutionEntity,
      ...values,
      name,
      acronym,
      address,
      otherLocations,
      focalPointName,
      email,
      mobilePhone,
      telephone,
      yearEstablishment,
      totalSurfaceArea,
      numberPrograms,
      numberFullTimeStudents,
      numberPartTimeStudents,
      comment,
      users: extractIdsAsObj(entityUsers),
      region: {id: selectedRegionOption},
      governorate: {id: selectedGovernorateOption},
      availableFacilities: mapIdFromList(selectedAvailableFacilitiesOptions),
      accreditationBody: {id: selectedAccreditationBodyOption},
      programLevel: {id: selectedProgramLevelOption},
      trainingProgramEconomicSectors: mapIdFromList(selectedTrainingProgramOptions),
      ageGroups: selectedAgeGroupOptionsTxt
    };

    if (isNew) {
      dispatch(createEntity({ entity, logoFile }));
    } else {
      dispatch(updateEntity({ entity, logoFile }));
    }
  };

  const mapIdFromList = (values) => {
    let result = [];
    if (values) {
      result = values.map((i: any) => ({ id: i }));
    }
    return result;
  }

  const ungroupAgeGroups = (aGroups) => {
    if(!aGroups || aGroups === "") return "";
    return aGroups.split(';');
  }

  const defaultValues = () =>
    isNew ? {} : {
          ...institutionEntity,
          users: institutionEntity?.users?.map(e => e.id.toString())
  };

  const handleRegionChange = (e) => {
    setSelectedRegionOption(parseInt(e.target.value, 10));
    setRegionChanged(true);
  };

  const handleGovernorateChange = (e) => {
    setSelectedGovernorateOption(parseInt(e.target.value, 10));
  }

  const handleAvailableFacilitiesChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (selectedAvailableFacilitiesOptions.includes(value)) {
      setSelectedAvailableFacilitiesOptions(selectedAvailableFacilitiesOptions.filter(i => i !== value));
    } else {
      setSelectedAvailableFacilitiesOptions([...selectedAvailableFacilitiesOptions, value]);
    }
  }

  const handleAccreditationBodyChange = (e) => {
    setSelectedAccreditationBodyOption(parseInt(e.target.value, 10));
  }

  const handleProgramLevelChange = (e) => {
    setSelectedProgramLevelOption(parseInt(e.target.value, 10));
  }

  const handleTrainingProgramChange = (e) => {
    const value = parseInt(e.target.value, 10);
    if (selectedTrainingProgramOptions.includes(value)) {
      setSelectedTrainingProgramOptions(selectedTrainingProgramOptions.filter(i => i !== value));
    } else {
      setSelectedTrainingProgramOptions([...selectedTrainingProgramOptions, value]);
    }
  }

  const handleAgeGroupChange = (e) => {
    const value = e.target.value;
    let currentAgeGroupOptions = selectedAgeGroupOptions;
    if (selectedAgeGroupOptions.includes(value)) {
      currentAgeGroupOptions = selectedAgeGroupOptions.filter(i => i !== value);
    } else {
      currentAgeGroupOptions = [...selectedAgeGroupOptions, value];
    }
    setSelectedAgeGroupOptions(currentAgeGroupOptions);
    setSelectedAgeGroupOptionsTxt(currentAgeGroupOptions.join(';'));
  }


  const handleFieldErrorChange = (fieldName: string, hasError: boolean) => {
    setFieldErrors(prev => ({
      ...prev,
      [fieldName]: hasError
    }));
  };

  const isFormValid = () => {
    return Object.values(fieldErrors).every(error => !error);
  };

  const handleUserSelectionChange = (newSelectedItems: ListboxItem[]) => {
    setEntityUsers(newSelectedItems);
  };

  const MAX_FILE_SIZE = 1.5 * 1024 * 1024; // 1.5MB
  const ALLOWED_MIME_TYPES = ["image/jpeg", "image/png", "image/gif"];

  const [logoFile, setLogoFile] = useState<File | null>(null);
  const [errorLogo, setErrorLogo] = useState('');
  const [filePreview, setFilePreview] = useState("");
  const [changeLogo, setChangeLogo] = useState(false);

  const handleLogoChange = event => {
    const file = event.target.files[0];
    if (file.size > MAX_FILE_SIZE) {
      const errorKey = 'irexForsahApp.institution.validations.file.exceedsSize';
      setErrorLogo(errorKey);
      return;
    }

    if (!ALLOWED_MIME_TYPES.includes(file.type)) {
      const errorKey = 'irexForsahApp.institution.validations.file.invalidType';
      setErrorLogo(errorKey);
      return false;
    }

    setErrorLogo('');
    setLogoFile(file);
    setFilePreview(URL.createObjectURL(file));
  };

  const handleCancelChangeLogo = () => {
    setChangeLogo(false);
    handleClearLogoSelected();
  }

  const handleClearLogoSelected = () => {
    setLogoFile(null);
    setFilePreview("");
    setErrorLogo('');
  }

  return (
    <div key="div-institution-update">
      <Row className="justify-content-center">
        <Col md="8">
          <h2 className='mt-15' id="irexForsahApp.institution.home.createOrEditLabel" data-cy="InstitutionCreateUpdateHeading">
            <Translate contentKey="irexForsahApp.institution.home.createOrEditLabel">Create or edit a Institution</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <LoadingSpinner />
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity} key="form-institution-update">
              
              <Row className='mt-30'>
                <Col md="8">
                  {!isNew ? (
                    <FieldFormWithValidation
                      label={translate('global.field.id')}
                      id="institution-id"
                      name="id"
                      type="text"
                      value={id}                     
                      validate={{
                        required: { value: true, message: translate('entity.validation.required') },
                      }}
                    />
                  ) : null}

                  <FieldFormWithValidation
                    label={translate('irexForsahApp.institution.name')}
                    id="institution-name"
                    name="name"
                    data-cy="name"
                    type="text"
                    value={name}
                    onChange={setName}
                    onError={handleFieldErrorChange}
                    validate={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  />

                  <FieldFormWithValidation
                    label={translate('irexForsahApp.institution.acronym')}
                    id="institution-acronym"
                    name="acronym"
                    data-cy="acronym"
                    type="text"
                    value={acronym}
                    onChange={setAcronym}
                    onError={handleFieldErrorChange}
                    validate={{
                      required: { value: true, message: translate('entity.validation.required') },
                    }}
                  />
                </Col>

                <Col md="4">
                  <Card className='b-radius-1'>
                    <CardHeader>
                      {
                        changeLogo ? (
                            <label><Translate contentKey="irexForsahApp.institution.changeLogo">ChangeLogo</Translate></label>
                        ) : (
                          <>
                          {institutionEntity.logo ? (
                              <Translate contentKey="irexForsahApp.institution.logo">Logo</Translate>
                          ) : (
                              <label><Translate contentKey="irexForsahApp.institution.addLogo">addLogo</Translate></label>
                          )}
                          </>
                        )
                      }
                    </CardHeader>

                    <div className='image-container'>
                      {
                        (changeLogo || isNew || (!isNew && !institutionEntity.logo)) ? (
                          <>
                            {filePreview && filePreview !== "" ? (
                              <img src={filePreview} alt={translate('irexForsahApp.institution.logoPreview')} />
                              ) : (
                              <img src='/content/images/NoImage.webp' alt={translate('irexForsahApp.institution.noImage')}/>
                              )
                            }
                          </>
                        ) : (
                          <>
                            {institutionEntity.logo ? (
                                <img src={`data:image/png;base64,${institutionEntity.logo}`} alt={translate('irexForsahApp.institution.logo')}/>
                            ) : (
                                <img src='/content/images/NoImage.webp' alt={translate('irexForsahApp.institution.noImage')}/>
                            )}
                          </>
                        )
                      }
                    </div>

                    <CardBody>
                      {
                        changeLogo || isNew || (!isNew && !institutionEntity.logo) ? (
                          <>
                            <div className="justify-content-between modal-footer">
                              <Input type="file" name="logo" id="institution-logo" style={{visibility:"hidden", padding: "0px", height: "0px"}}
                                  onChange={handleLogoChange} accept="image/jpeg, image/png, image/gif"  />
                              <label htmlFor="institution-logo" className={`btn btn-primary ${!(changeLogo && !isNew) && !filePreview ? 'width-100' : ''}`} >
                                <FontAwesomeIcon icon={faHandPointer} /> &nbsp;
                                <Translate contentKey="irexForsahApp.institution.chooseLogo">chooseLogo</Translate>
                              </label>

                              {changeLogo && !isNew &&
                                <Button onClick={handleCancelChangeLogo} color="secondary">
                                  <FontAwesomeIcon icon={faTimes} /> &nbsp;
                                  <Translate contentKey="irexForsahApp.institution.cancel">cancel</Translate>
                                </Button>
                              }

                              { !institutionEntity.logo && filePreview &&
                                <Button onClick={handleClearLogoSelected} color="secondary">
                                  <FontAwesomeIcon icon={faTrash} /> &nbsp;
                                  <Translate contentKey="irexForsahApp.institution.clear">clear</Translate>
                                </Button>
                              }
                            </div>
                          </>
                        ) : (
                          <Button onClick={() => setChangeLogo(true)} color="primary" className='mt-15' block>
                            <FontAwesomeIcon icon={faSync} /> &nbsp;
                            <Translate contentKey="irexForsahApp.institution.change">change</Translate>
                          </Button>
                        )
                      }

                    </CardBody>
                  </Card>

                  { errorLogo !== '' && (
                    <Alert color="danger">
                      <Translate contentKey={`${errorLogo}`}>Error</Translate>
                    </Alert>
                  )}

                </Col>
              </Row>


              <Card className='b-radius-1 mt-15'>
                <CardHeader>
                  <label className="col-3 col-form-label f-weight-500">
                    <Translate contentKey="irexForsahApp.institution.locationTitle">Locations</Translate>
                  </label>
                </CardHeader>
                <CardBody>
                  <FieldFormWithValidation
                      label={translate('irexForsahApp.institution.address')}
                      id="institution-address"
                      name="address"
                      data-cy="address"
                      type="text"
                      value={address}
                      onChange={setAddress}
                    />

                    <div className="row mb-3 align-items-center">
                      <label className="col-3 col-form-label f-weight-500" htmlFor="region">
                        <Translate contentKey="irexForsahApp.institution.region">Region</Translate>
                      </label>

                      {regions
                        ? regions.map(otherEntity => (
                            <div className="col" key={otherEntity.id}>
                              <label>
                                <input
                                  type="radio"
                                  value={otherEntity.id}
                                  checked={selectedRegionOption === otherEntity.id}
                                  onChange={handleRegionChange}
                                />
                                <span className='f-size-085'>{' '}{otherEntity.name}</span>
                              </label>
                            </div>
                          ))
                        : null}
                    </div>

                    { governorates && governorates.length > 0 &&
                      <div className="row mb-3">
                        <label className="col-3 col-form-label f-weight-500" htmlFor="governorate">
                          <Translate contentKey="irexForsahApp.institution.governorate">Governorate</Translate>
                        </label>


                          <div className="row-institution-container">
                            {governorates.map(otherEntity => (
                                <div className="col-institution-4-items" key={otherEntity.id}>
                                  <label>
                                  <input
                                    type="radio"
                                    value={otherEntity.id}
                                    checked={selectedGovernorateOption === otherEntity.id}
                                    onChange={handleGovernorateChange}
                                  />
                                  <span className='f-size-085'>{' '}{otherEntity.name}</span>
                                </label>
                                </div>
                              ))}
                          </div>

                      </div>
                    }

                    <div className='mt-30'></div>

                    <FieldFormWithValidation
                      label={translate('irexForsahApp.institution.otherLocations')}
                      id="institution-otherLocations"
                      name="otherLocations"
                      data-cy="otherLocations"
                      type="text"
                      value={otherLocations}
                      onChange={setOtherLocations}
                    />
                </CardBody>
              </Card>

              <Card className='b-radius-1 mt-15'>
                <CardHeader>
                  <label className="col-3 col-form-label f-weight-500">
                    <Translate contentKey="irexForsahApp.institution.contactInfoTitle">Contact Information</Translate>
                  </label>
                </CardHeader>
                <CardBody>
                  <Row>                    
                    <Col md="6">
                      <FieldFormWithValidation
                        label={translate('irexForsahApp.institution.email')}
                        id="institution-email"
                        name="email"
                        data-cy="email"
                        type="email"
                        value={email}
                        onChange={setEmail}
                        onError={handleFieldErrorChange}
                        validate={{
                          emailPattern: { value: email, message: translate('irexForsahApp.institution.validations.email') },
                        }}
                      />
                    </Col>

                    <Col md="6">
                      <FieldFormWithValidation
                        label={translate('irexForsahApp.institution.focalPointName')}
                        id="institution-focalPointName"
                        name="focalPointName"
                        data-cy="focalPointName"
                        type="text"
                        value={focalPointName}
                        onChange={setFocalPointName}
                        labelWidth={4}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md="6">
                      <FieldFormWithValidation
                        label={translate('irexForsahApp.institution.telephone')}
                        id="institution-telephone"
                        name="telephone"
                        type="text"
                        value={telephone}
                        onChange={setTelephone}
                        onError={handleFieldErrorChange}
                        validate={{
                          pattern: { value: new RegExp('^0[0-9]{8}$'), message: translate('irexForsahApp.institution.validations.telephone') }
                        }}
                      />
                    </Col>

                    <Col md="6">
                        <FieldFormWithValidation
                          label={translate('irexForsahApp.institution.mobilePhone')}
                          id="institution-mobilePhone"
                          name="mobilePhone"
                          data-cy="mobilePhone"
                          type="text"
                          value={mobilePhone}
                          onChange={setMobilePhone}
                          onError={handleFieldErrorChange}
                          validate={{
                            pattern: { value: new RegExp('^05[0-9]{8}$'), message: translate('irexForsahApp.institution.validations.mobilephone') }
                          }}
                          labelWidth={4}
                        />
                    </Col>

                  </Row>
                </CardBody>
              </Card>

              <Row className='mt-15'>
                <Col md="6">
                  <FieldFormWithValidation
                    label={translate('irexForsahApp.institution.yearOfEstablishment')}
                    id="institution-yearEstablishment"
                    name="yearEstablishment"
                    type="number"
                    value={yearEstablishment}
                    onChange={setYearEstablishment}
                    onError={handleFieldErrorChange}
                    validate={{
                      min: { value: 1900, message: translate('irexForsahApp.institution.validations.year') },
                      max: { value: new Date().getFullYear(), message: translate('irexForsahApp.institution.validations.year') },
                      pattern: { value: new RegExp('^[0-9]{4}$'), message: translate('irexForsahApp.institution.validations.year') }
                    }}
                    labelWidth={5}
                  />
                </Col>

                <Col md="6">
                  <FieldFormWithValidation
                    label={translate('irexForsahApp.institution.totalSurfaceArea')}
                    id="institution-totalSurfaceArea"
                    name="totalSurfaceArea"
                    type="number"
                    value={totalSurfaceArea}
                    onChange={setTotalSurfaceArea}
                    onError={handleFieldErrorChange}
                    validate={{
                      pattern: { value: new RegExp('^[0-9]+([0-9])?$'), message: translate('irexForsahApp.institution.validations.totalSurfaceArea') }
                    }}
                    labelWidth={5}
                  />
                </Col>
              </Row>


              <Card className='b-radius-1 mt-15'>
                <CardHeader>
                  <label className="col-3 col-form-label f-weight-500">
                    <Translate contentKey="irexForsahApp.institution.availableFacilities">Available Facilities</Translate>
                  </label>
                </CardHeader>
                <CardBody>
                  <div className="row-institution-container">
                    {availableFacilities
                      ? availableFacilities.map(otherEntity => (
                          <div className="col-institution-4-items" key={otherEntity.id}>
                            <label>
                              <input
                                type="checkbox"
                                value={otherEntity.id}
                                checked={selectedAvailableFacilitiesOptions.includes(otherEntity.id)}
                                onChange={handleAvailableFacilitiesChange}
                              />
                              <span className="f-size-085">{' '}{otherEntity.name}</span>
                            </label>
                          </div>
                        ))
                      : null}
                  </div>
                </CardBody>
              </Card>


              <Card className='b-radius-1 mt-15'>
                <CardHeader>
                  <label className="col-3 col-form-label f-weight-500" htmlFor="accreditationBody">
                    <Translate contentKey="irexForsahApp.institution.accreditationBody">Accreditation Body</Translate>
                  </label>
                </CardHeader>
                <CardBody>
                  <Row>
                    {accreditationBodies
                      ? accreditationBodies.map(otherEntity => (
                          <div className="col" key={otherEntity.id}>
                            <label>
                              <input
                                type="radio"
                                value={otherEntity.id}
                                checked={selectedAccreditationBodyOption === otherEntity.id}
                                onChange={handleAccreditationBodyChange}
                              />
                              <span className='f-size-085'>{' '}{otherEntity.name}</span>
                            </label>
                          </div>
                        ))
                      : null}
                  </Row>
                </CardBody>
              </Card>


              <Card className='b-radius-1 mt-15'>
                <CardHeader>
                  <label className="col-3 col-form-label f-weight-500">
                  <Translate contentKey="irexForsahApp.institution.programLevel">Program Level</Translate>
                  </label>
                </CardHeader>
                <CardBody>
                  <Row>
                    {programLevels
                      ? programLevels.map(otherEntity => (
                        <div className="col" key={otherEntity.id}>
                            <label key={otherEntity.id}>
                              <input
                                type="radio"
                                value={otherEntity.id}
                                checked={selectedProgramLevelOption === otherEntity.id}
                                onChange={handleProgramLevelChange}
                              />
                              <span className='f-size-085'>{' '}{otherEntity.name}</span>
                            </label>
                          </div>
                        ))
                      : null}
                  </Row>
                </CardBody>
              </Card>


              <Card className='b-radius-1 mt-15'>
                <CardHeader>
                  <label className="col-3 col-form-label f-weight-500">
                  <Translate contentKey="irexForsahApp.institution.trainingPrograms">Training Programs</Translate>
                  </label>
                </CardHeader>
                <CardBody>
                  <Row>
                    {trainingPrograms
                      ? trainingPrograms.map(otherEntity => (
                          <div className="col" key={otherEntity.id}>
                            <label>
                              <input
                                type="checkbox"
                                value={otherEntity.id}
                                checked={selectedTrainingProgramOptions.includes(otherEntity.id)}
                                onChange={handleTrainingProgramChange}
                              />
                              <span className='f-size-085'>{' '}{otherEntity.name}</span>
                            </label>
                          </div>
                        ))
                      : null}
                  </Row>
                </CardBody>
              </Card>


              <Row className='mt-15'>
                <Col>
                  <FieldFormWithValidation
                    label={translate('irexForsahApp.institution.numberPrograms')}
                    id="institution-numberPrograms"
                    name="numberPrograms"
                    type="number"
                    value={numberPrograms}
                    onChange={setNumberPrograms}
                    onError={handleFieldErrorChange}
                    validate={{
                      min: { value: 0, message: translate('irexForsahApp.institution.validations.min-0') }
                    }}
                    labelWidth={8}
                  />
                </Col>

                <Col>
                  <FieldFormWithValidation
                    label={translate('irexForsahApp.institution.numberFullTimeStudents')}
                    id="institution-numberFullTimeStudents"
                    name="numberFullTimeStudents"
                    type="number"
                    value={numberFullTimeStudents}
                    onChange={setNumberFullTimeStudents}
                    onError={handleFieldErrorChange}
                    validate={{
                      min: { value: 0, message: translate('irexForsahApp.institution.validations.min-0') }
                    }}
                    labelWidth={8}
                  />
                </Col>

                <Col>
                  <FieldFormWithValidation
                    label={translate('irexForsahApp.institution.numberPartTimeStudents')}
                    id="institution-numberPartTimeStudents"
                    name="numberPartTimeStudents"
                    data-cy="numberPartTimeStudents"
                    type="number"
                    value={numberPartTimeStudents}
                    onChange={setNumberPartTimeStudents}
                    onError={handleFieldErrorChange}
                    validate={{
                      min: { value: 0, message: translate('irexForsahApp.institution.validations.min-0') }
                    }}
                    labelWidth={8}
                  />
                </Col>
              </Row>


              <Card className='b-radius-1 mt-15'>
                <CardHeader>
                  <label className="col-3 col-form-label f-weight-500">
                    <Translate contentKey="irexForsahApp.institution.ageGroupOfStudents">Age group of students trained</Translate>
                  </label>
                </CardHeader>
                <CardBody>
                  <Row>
                    {ageGroups.map(ageGroup => (
                      <div className="col" key={ageGroup}>
                        <label>
                          <input
                            type="checkbox"
                            value={ageGroup}
                            checked={selectedAgeGroupOptions.includes(ageGroup)}
                            onChange={handleAgeGroupChange}
                          />
                          <span className='f-size-085'>{' '}{ageGroup}</span>
                        </label>
                      </div>
                    ))}
                  </Row>
                </CardBody>
              </Card>
              

              <div className="mb-3 mt-15">
                <label htmlFor="institution-comment" id="commentLabel" className="form-label f-weight-500">
                  <Translate contentKey="irexForsahApp.institution.comment">Any other relevant background information</Translate>
                </label>
                <textarea
                  name="comment"
                  id="institution-comment"
                  className="form-control"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)} />
              </div>


              {
                isIrexAdmin && entityUsers &&
                <DualListbox
                  items={transformUserListToListboxItems(users)}
                  selectedItems={transformUserListToListboxItems(entityUsers)}
                  label={translate('irexForsahApp.institution.user')}
                  id="institution-user"
                  name="users"
                  onSelectionChange={handleUserSelectionChange}
                />
              }

              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/usersHome/institution" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>

            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default InstitutionUpdate;
