import axios from 'axios';
import { createAsyncThunk, isFulfilled, isPending } from '@reduxjs/toolkit';
import { ASC } from 'app/shared/util/pagination.constants';
import { cleanEntity } from 'app/shared/util/entity-utils';
import { IQueryParams, createEntitySlice, EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ISurvey, defaultValue } from 'app/shared/model/survey.model';
import { ISurveyDeleteableResponse } from 'app/shared/model/survey-deleteable-response.model';

interface ExtendedEntityState<T> extends EntityState<T> {
  types?: ReadonlyArray<any>;
  isDeleteableResponse?: ISurveyDeleteableResponse;
}

const initialState: ExtendedEntityState<ISurvey> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: defaultValue,
  updating: false,
  updateSuccess: false,
  types: []
};

const apiUrl = 'api/surveys';

// Actions

export const getEntities = createAsyncThunk('survey/fetch_entity_list', async () => {
  const requestUrl = `${apiUrl}`;
  return axios.get<ISurvey[]>(requestUrl);
});

export const getAllTypes = createAsyncThunk(
  'survey/fetch_all_types',
  async () => {
    const requestUrl = `${apiUrl}/all-types`;
    return axios.get<any>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getEntity = createAsyncThunk(
  'survey/fetch_entity',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/${id}`;
    return axios.get<ISurvey>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const getEntitiesByInstitution = createAsyncThunk(
  'survey/fetch_entity_list_by_institution', 
  async (institutionId: string) => {
    const requestUrl = `${apiUrl}/all-by-institution-id/${institutionId}`;
    return axios.get<ISurvey[]>(requestUrl);
});

export const getEntitiesByInstitutionAndType = createAsyncThunk(
  'survey/fetch_entity_list_by_institution_and_type', 
  async ({institutionId, type} : {institutionId: string; type: string}) => {
    const requestUrl = `${apiUrl}/all-by-institution-id-and-type/${institutionId}/${type}`;
    return axios.get<ISurvey[]>(requestUrl);
});

export const createEntity = createAsyncThunk(
  'survey/create_entity',
  async (entity: ISurvey, thunkAPI) => {
    const result = await axios.post<ISurvey>(apiUrl, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const updateEntity = createAsyncThunk(
  'survey/update_entity',
  async (entity: ISurvey, thunkAPI) => {
    const result = await axios.put<ISurvey>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const partialUpdateEntity = createAsyncThunk(
  'survey/partial_update_entity',
  async (entity: ISurvey, thunkAPI) => {
    const result = await axios.patch<ISurvey>(`${apiUrl}/${entity.id}`, cleanEntity(entity));
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const checkIsDeleatable = createAsyncThunk(
  'survey/check_is_deletable',
  async (id: string | number) => {
    const requestUrl = `${apiUrl}/is-deleteable/${id}`;
    return axios.get<ISurveyDeleteableResponse>(requestUrl);
  },
  { serializeError: serializeAxiosError },
);

export const deleteEntity = createAsyncThunk(
  'survey/delete_entity',
  async (id: string | number, thunkAPI) => {
    const requestUrl = `${apiUrl}/${id}`;
    const result = await axios.delete<ISurvey>(requestUrl);
    thunkAPI.dispatch(getEntities());
    return result;
  },
  { serializeError: serializeAxiosError },
);

// slice

export const SurveySlice = createEntitySlice({
  name: 'survey',
  initialState,
  extraReducers(builder) {
    builder
      .addCase(getEntity.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(deleteEntity.fulfilled, state => {
        state.updating = false;
        state.updateSuccess = true;
        state.entity = {};
      })
      .addMatcher(isFulfilled(getEntities), (state, action) => {
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          entities: data,
        };
      })
      .addMatcher(isFulfilled(getEntitiesByInstitution, getEntitiesByInstitutionAndType), (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addMatcher(isFulfilled(createEntity, updateEntity, partialUpdateEntity), (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addMatcher(isPending(getEntities, getEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.loading = true;
      })
      .addMatcher(isPending(createEntity, updateEntity, partialUpdateEntity, deleteEntity), state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addMatcher(isFulfilled(getAllTypes), (state, action) => {
        state.loading = false;
        state.types = action.payload.data;
      }).addMatcher(isPending(checkIsDeleatable), state => {
        state.isDeleteableResponse = null;
      })
      .addMatcher(isFulfilled(checkIsDeleatable), (state, action) => {
        state.isDeleteableResponse = action.payload.data;
      });
    },
  });

export const { reset } = SurveySlice.actions;

// Reducer
export default SurveySlice.reducer;
