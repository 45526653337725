import React from "react";
import ScoringMatrix from "./tables/scoring-matix";
import { Translate } from "react-jhipster";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import ReviewTemplate from "./tables/review-template";
import BandingCriteria from "./tables/banding-criteria";
import AssessmentReport from "./reports/assessment-report";
import { useAppSelector } from "app/config/store";
import { hasAnyAuthority } from "app/shared/auth/private-route";
import { AUTHORITIES } from "app/config/constants";

export const Tables = () => {

  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.IREX_ADMIN, AUTHORITIES.TVET_ADMIN]));
  const isValidator = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.VALIDATOR]));
  const account = useAppSelector(state => state.authentication.account);
  const profile = useAppSelector(state => state.authentication.account.authorities);

  return (
    <div className="validation-wrapper">

      <Tabs>
        <TabList>
          <Tab><Translate contentKey="irexForsahApp.tables.scoringMatrix.title"/></Tab>
          <Tab><Translate contentKey="irexForsahApp.tables.bandingCriteria.title"/></Tab>
          <Tab><Translate contentKey="irexForsahApp.tables.reviewTemplate.title"/></Tab>
          {
            (isAdmin || isValidator) && (<Tab><Translate contentKey="irexForsahApp.tables.institutionReport.title"/></Tab>)
          }          
        </TabList>

        <TabPanel>
          <ScoringMatrix />
        </TabPanel>

        <TabPanel>
          <BandingCriteria />
        </TabPanel>

        <TabPanel>
          <ReviewTemplate />
        </TabPanel>

        {
          (isAdmin || isValidator) && (
            <TabPanel>
              <AssessmentReport />
            </TabPanel>
          )
        }
        
      </Tabs>

    </div>

  );
};

export default Tables;
