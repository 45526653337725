import React from 'react';
import { Translate } from 'react-jhipster';

import { NavItem, NavLink, NavbarBrand } from 'reactstrap';
import { NavLink as Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const BrandIcon = props => (
  <div {...props} className="brand-icon">
    <img src="content/images/usaid-logo.svg" alt="USAID Logo" style={{width: 150}}/>
    <img src="content/images/irex-logo.svg" alt="Irex Logo" style={{width: 90, marginLeft: 15}}/>   
  </div>
);

export const Brand = () => (
  <NavbarBrand tag={Link} to="/usersHome" className="brand-logo">
    <BrandIcon />
    <span className="brand-title">
      <Translate contentKey="global.title">Forsah</Translate>
    </span>
  </NavbarBrand>
);

export const Home = () => (
  <NavItem>
    <NavLink tag={Link} to="/usersHome" className="d-flex align-items-center">
      <span>
        <Translate contentKey="global.menu.home">Home</Translate>
      </span>
    </NavLink>
  </NavItem>
);

export const UsersHome = () => (
  <NavItem>
    <NavLink tag={Link} to="/usersHome" className="d-flex align-items-center">
      <span>
        <Translate contentKey="global.menu.usersHome">UsersHome</Translate>
      </span>
    </NavLink>
  </NavItem>
);
