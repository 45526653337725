import { IUser } from 'app/shared/model/user.model';
import { ISurvey } from 'app/shared/model/survey.model';

export interface IInstitution {
  id?: number;
  name?: string;
  acronym?: string;
  comment?: string | null;
  users?: IUser[] | null;
  surveys?: ISurvey[] | null;
}

export const defaultValue: Readonly<IInstitution> = {};
