import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { EntityState } from 'app/shared/reducers/reducer.utils';
import { ITableReviewTemplate } from 'app/shared/model/tables_visualization/table-review-template.model';


const initialState: EntityState<ITableReviewTemplate> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: {},
  updating: false,
  updateSuccess: false
};

const apiUrl = 'api/data-visualization/table';

export const getReviewTemplate = createAsyncThunk(
  'charts/fetch_review_template',
  async () => {
    return axios.get<ITableReviewTemplate[]>(`${apiUrl}/review-template`);
  }
);

export const tableReviewTemplateSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(getReviewTemplate.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload.data;
      })
      .addCase(getReviewTemplate.pending, state => {
        state.loading = true;
      })
      .addCase(getReviewTemplate.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
  initialState,
  name: 'tableDataVisualization-reviewTemplate',
  reducers: {
    reset() {
      return initialState;
    },
  },
});

export default tableReviewTemplateSlice.reducer;
