import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { EntityState, serializeAxiosError } from 'app/shared/reducers/reducer.utils';
import { ISyncConfiguration } from 'app/shared/model/sync-configuration.model';


const initialState: EntityState<ISyncConfiguration> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: {},
  updating: false,
  updateSuccess: false
};

const apiUrl = 'api/sync-configuration';

export const getCurrentStatus = createAsyncThunk(
  'syncConfiguration/fetch_current_status',
  async () => {
    return axios.get<ISyncConfiguration>(`${apiUrl}/status`);
  }
);

export const updateServiceStatus = createAsyncThunk(
  'syncConfiguration/update_service_status',
  async ({serviceName, status} : {serviceName: string, status: boolean}, thunkAPI) => {
    const result = await axios.patch(`${apiUrl}/set-status/${serviceName}`, status, {
      headers: {
        'Content-Type': 'application/json'
      }
    });
    thunkAPI.dispatch(getCurrentStatus());
    return result;
  },
  { serializeError: serializeAxiosError },
);

export const syncConfigurationSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(getCurrentStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.entity = action.payload.data;
      })
      .addCase(getCurrentStatus.pending, state => {
        state.loading = true;
      })
      .addCase(getCurrentStatus.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      
      .addCase(updateServiceStatus.fulfilled, (state, action) => {
        state.updating = false;
        state.loading = false;
        state.updateSuccess = true;
        state.entity = action.payload.data;
      })
      .addCase(updateServiceStatus.pending, state => {
        state.errorMessage = null;
        state.updateSuccess = false;
        state.updating = true;
      })
      .addCase(updateServiceStatus.rejected, (state, action) => {
        state.loading = false;
        state.updateSuccess = false;
        state.errorMessage = action.error.message;
      });
  },
  initialState,
  name: 'syncConfiguration',
  reducers: {
    reset() {
      return initialState;
    },
  },
});

export default syncConfigurationSlice.reducer;
