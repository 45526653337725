import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IFilter} from 'app/shared/model/forsah-filter.model';
import { ITableScoringMatrixRow } from 'app/shared/model/tables_visualization/table-scoring-matrix-row.model';
import { EntityState } from 'app/shared/reducers/reducer.utils';
import { ITableScoringMatrix } from 'app/shared/model/tables_visualization/table-scoring-matrix.model';


const initialState: EntityState<ITableScoringMatrix> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: {},
  updating: false,
  updateSuccess: false
};

const apiUrl = 'api/data-visualization/table';

export const getScoringMatrix = createAsyncThunk(
  'charts/fetch_scoring_matrix',
  async (filter: IFilter) => {
    return axios.post<ITableScoringMatrix[]>(`${apiUrl}/scoring-matrix`, filter);
  }
);

export const tableScoringMatrixSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(getScoringMatrix.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload ? action.payload.data : [];
        state.entity = action.payload ? action.payload.data[0] : {};
        state.errorMessage = '';
      })
      .addCase(getScoringMatrix.pending, state => {
        state.loading = true;
      })
      .addCase(getScoringMatrix.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = (action.error.message && action.error.message !== '') ? action.error.message : 'error';
      });
  },
  initialState,
  name: 'tableDataVisualization',
  reducers: {
    reset() {
      return initialState;
    },
  },
});

export default tableScoringMatrixSlice.reducer;
