import React, {useState, useEffect, useRef} from 'react';
import ReactECharts from 'echarts-for-react';
import {getBarData} from "app/modules/charts/bar-chart-reducer";
import {useAppDispatch, useAppSelector} from "app/config/store";
import Filter from "app/shared/filter/filter-component";
import colors from 'app/shared/util/colors-utils';
import {Spinner} from 'reactstrap';

const barchart = () => {
  const [option, setOption] = useState(null);
  const echartsRef = useRef(null);
  const dispatch = useAppDispatch();

  const barChart = useAppSelector((state) => state.barChartSlice.barChartData)
  const institutions = useAppSelector((state) => state.filterSlice.institutions);
  const years = useAppSelector((state) => state.filterSlice.years);
  const loading = useAppSelector((state) => state.barChartSlice.loading);
  const selectedInstitutions = useAppSelector((state) => state.filterSlice.selectedInstitutions);
  const selectedYears = useAppSelector((state) => state.filterSlice.selectedYears);

  const [barFilterState, setBarFilterState] = useState({
    selectedInstitutions,
    selectedYears,
    institutions,
    years
  });

  useEffect(() => {
    handleBarFilterChange(barFilterState);
  }, [barFilterState]);

  useEffect(() => {
    setBarFilterState(prevState => ({
      ...barFilterState,
      selectedYears,
      selectedInstitutions,
      institutions,
    }));
  }, [selectedInstitutions, selectedYears, institutions, years]);

  const extractDataValues = (series: any[]): any[] => {
    const groupedData: any = {};

    series.forEach(item => {
      if (Array.isArray(item.data)) {
        item.data.forEach((dataPoint: any) => {
          if (Array.isArray(dataPoint.value)) {
            dataPoint.value.forEach(value => {
              if (value !== null && value !== undefined && !isNaN(value)) {
                if (!groupedData[item.name]) {
                  groupedData[item.name] = [];
                }
                groupedData[item.name].push(Number(value));
              }
            });
          }
        });
      }
    });

    return Object.keys(groupedData).map(key => {
      return {
        name: key,
        type: 'bar',
        data: groupedData[key]
      };
    });
  };

  const institutionColors = institutions.reduce((acc, institution, index) => {
    acc[institution.name] = colors[index % colors.length];
    return acc;
  }, {});



  const handleBarFilterChange = (filter: {
    selectedInstitutions: number[]; selectedYears: number[]
  }) => {
    if (filter.selectedInstitutions && filter.selectedYears
      && filter.selectedInstitutions.length > 0 && filter.selectedYears.length > 0) {
      dispatch(getBarData(filter));
    }
  }

  useEffect(() => {
      if (barChart) {
        let xData = []
        if (barChart.xAxis.data.length > 0 && Array.isArray(barChart.xAxis.data)) {
          xData = barChart.xAxis.data.map(data => {
            const splitData = data.split(":");
            return splitData.length > 1 ? splitData[1].trim() : data;
          });
        }
        const seriesDataValues = extractDataValues(barChart.series);
        const initialOption = {
          title: {
            text: ''
          },
          tooltip: {
            type: 'value'
          },
          xAxis: {
            data: xData,
            axisLabel: {
              interval: 0,
              formatter (value: string) {
                return value + ' ';
              }
            },
            axisTick: {show: true},
          },
          yAxis: {},
          legend: {
            data: seriesDataValues.map(series => series.name)
          },
          toolbox: {
            show: true,
            orient: 'vertical',
            left: 'right',
            top: 'center',
            borderWidth:2,
            borderColor: "#19494b",
            backgroundColor: "#19494b34",
            borderCap: 'round',
            borderJoin: 'round',
            iconStyle: {
              color: "#fafafa",
              borderColor: "#19494b"
            },
            feature: {
              mark: {show: true},
              dataView: {show: true, readOnly: false},
              saveAsImage: {show: true}
            }
          },
          series: seriesDataValues.map(series => ({
            ...series,
            itemStyle: {
              color: institutionColors[series.name]
            },
            emphasis: {
              focus: 'series'
            },
            label: {
              show: true,
              position: 'inside',
              rotate: 90,
              verticalAlign: 'middle',
              formatter (params: any) {
                if (params.value > 10) {
                  return params.seriesName
                } else {
                  return ''
                }
              }
            }
          }))
        };
        setOption(initialOption);
      }
    },[barChart]
  )
  ;

  useEffect(() => {
    if (echartsRef.current) {
      const instance = echartsRef.current.getEchartsInstance();
      instance.on('click', function (params) {
        const drillDownData = barChart.drillDowns
        const drillDown = drillDownData.find(drill => drill.series[0].name === params.seriesName);
        if (drillDown) {
          setOption(prevOption => ({
            ...prevOption,
            title: '',
            xAxis: {
              data: drillDown.xAxis.data,
              axisLabel: {
                interval: 0,
                rotate: 45,
                formatter (value: string) {
                  return value.length > 10 ? value.slice(0, 10) + '...' : value;
                }
              }
            },
            series: [
              {
                name: drillDown.series[0].name,
                type: 'bar',
                data: drillDown.series[0].data[0].value,
                itemStyle: {
                  color: params.color
                }
              }
            ]
          }));
        }
      });
    }
  }, [echartsRef.current, option]);

  return option ? (
    <div>
      <div>
        <Filter
          filterState={barFilterState}
          handleFilterChange={handleBarFilterChange}
          maxInstitutions={6}
          useDefaults={true}
        />
        <br/>
      </div>
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '600px', width: '100%'}}>
        {loading ? (
          <Spinner style={{width: '3rem', height: '3rem'}}/>
        ) : (
          <ReactECharts
            ref={echartsRef}
            option={option}
            notMerge={true}
            style={{height: '500px', width: '80%'}}
            key={Math.random()}
          />
        )}
      </div>
    </div>
  ) : null;
}
export default barchart;
