import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IFilter} from 'app/shared/model/forsah-filter.model';
import { EntityState } from 'app/shared/reducers/reducer.utils';
import { IInstitutionHasAllSectionsApproved } from 'app/shared/model/institution-has-all-sections-approved.model';
import { ICheckInstitutionHasAllSectionsApproved } from 'app/shared/model/check-inst-has-all-forms-approved.model';


const initialState: EntityState<IInstitutionHasAllSectionsApproved> = {
  loading: false,
  errorMessage: null,
  entities: [],
  entity: {},
  updating: false,
  updateSuccess: false
};

const apiUrl = 'api/institutions/check-all-forms-approved-by-year';

export const checkAllInsitutionFormsAreApproved = createAsyncThunk(
  'assesment-report/checkAllInsitutionFormsAreApproved',
  async (params: ICheckInstitutionHasAllSectionsApproved) => {
    return axios.post<IInstitutionHasAllSectionsApproved[]>(`${apiUrl}`, params);
  }
);

export const assessmentReportSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(checkAllInsitutionFormsAreApproved.fulfilled, (state, action) => {
        state.loading = false;
        state.entities = action.payload ? action.payload.data : [];
        state.errorMessage = '';
      })
      .addCase(checkAllInsitutionFormsAreApproved.pending, state => {
        state.loading = true;
      })
      .addCase(checkAllInsitutionFormsAreApproved.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = (action.error.message && action.error.message !== '') ? action.error.message : 'error';
      });
  },
  initialState,
  name: 'assessmentReport',
  reducers: {
    reset() {
      return initialState;
    },
  },
});

export default assessmentReportSlice.reducer;
