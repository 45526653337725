export interface IUser {
  id?: any;
  login?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  activated?: boolean;
  langKey?: string;
  authorities?: any[];
  createdBy?: string;
  createdDate?: Date | null;
  lastModifiedBy?: string;
  lastModifiedDate?: Date | null;
  password?: string;
  linkedInstitutions?: any[];
}

export interface IUserHome {
  loading: boolean;
  errorMessage: string | null;
  surveys: [];
  tempEditLink: string;
  tempViewLink: string;
  loadingLink: boolean;
  linkSuccess: boolean;
  isAvailableToBeAnswered: boolean;
}

export const defaultValue: Readonly<IUser> = {
  id: '',
  login: '',
  firstName: '',
  lastName: '',
  email: '',
  activated: true,
  langKey: 'en',
  authorities: [],
  createdBy: '',
  createdDate: null,
  lastModifiedBy: '',
  lastModifiedDate: null,
  password: '',
  linkedInstitutions: [],
};

export const initialState: IUserHome = {
  loading: false,
  errorMessage: null,
  surveys: [],
  tempEditLink: '',
  tempViewLink: '',
  loadingLink: false,
  linkSuccess: false,
  isAvailableToBeAnswered: null
};
