import React, {useState, useEffect} from 'react';
 import {useAppDispatch, useAppSelector} from "app/config/store"
import PrivateRoute, {hasAnyAuthority} from 'app/shared/auth/private-route';
import {Translate, translate} from 'react-jhipster';
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import {NavLink, useNavigate, useLocation, Routes, Route} from 'react-router-dom';
import {Container, Row, Col, Alert, Nav, NavItem, Collapse} from 'reactstrap';
import {AUTHORITIES} from "app/config/constants";

import Validation from "app/modules/usersHome/validation";
import DataCollection from "app/modules/usersHome/data-collection";
import Welcome from "app/modules/usersHome/welcome";
import UserManagement from "app/modules/administration/user-management/index";
import InstitutionRoutes from "app/entities/institution/index";
import SurveysRoutes from "app/entities/survey/index";
import Password from "app/modules/account/password/password";
import Settings from "app/modules/account/settings/settings";
import Charts from 'app/modules/visualizations/charts';
import Tables from 'app/modules/visualizations/tables';
import SyncConfiguration from '../syncConfiguration';
import { set } from 'lodash';

export const UsersHome = () => {
  const [currentRoute, setCurrentRoute] = useState('/');
  const pageLocation = useLocation();
  const showModalLogin = useAppSelector(state => state.authentication.showModalLogin);
  const [setShowModal] = useState(showModalLogin);
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const loginError = useAppSelector(state => state.authentication.loginError);
  const account = useAppSelector(state => state.authentication.account);

  const [isOpenVisualization, setIsOpenVisualization] = useState(false);

  const toggleVisualization = () => setIsOpenVisualization(!isOpenVisualization);

  const navigate = useNavigate();

  useEffect(() => {
    if (pageLocation.pathname.indexOf('visualizations') > 0) setIsOpenVisualization(true);
  }, []);
  
  
  const handleClose = () => {
    setShowModal(false);
    navigate('/');
  };

  const {from} = pageLocation.state || {from: {pathname: '/', search: pageLocation.search}};
  const isSuperAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN]));
  const isAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.ADMIN, AUTHORITIES.IREX_ADMIN]));
  const isTVETAdmin = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.TVET_ADMIN]));
  const isUser = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.USER]));
  const isValidator = useAppSelector(state => hasAnyAuthority(state.authentication.account.authorities, [AUTHORITIES.VALIDATOR]));

  const handleLogout = (event) => {
    event.preventDefault();
    navigate('/logout');
  }

  return (
    <div className="container-fluid user-home">
      <Container fluid={true}>
        <Row>
          {/* Sidebar */}
          <Col md="2" className="sidebar">
            <div className="menu-label"><Translate contentKey="global.menu.platformmenu"/></div>
            <NavItem className={(pageLocation.pathname.indexOf('welcome') > 0 || pageLocation.pathname === '/usersHome') ? 'active' : ''}>
              <NavLink to="welcome">
                <span className="icon home-icon"/>
                <img src="/content/images/icons/home-icon.svg" alt="home icon"/>
                <Translate contentKey="global.menu.home"/>
              </NavLink>
            </NavItem>

            {(!isUser && !isValidator && !isTVETAdmin) &&
              <NavItem className={pageLocation.pathname.indexOf('data-collection') > 0 ? 'active' : ''}>
              <NavLink to="data-collection">
                <span className="icon dc-icon"/>
                <img src="/content/images/icons/data-collection-icon.svg" alt="data collection icon"/>
                <Translate contentKey="global.menu.datacollection"/>
              </NavLink>
            </NavItem>
            }

            {(isSuperAdmin || isValidator ) &&
              <NavItem className={pageLocation.pathname.indexOf('validation') > 0 ? 'active' : ''}>
                <NavLink to="validation">
                <span className="icon dv-icon"/>
                <img src="/content/images/icons/data-validation-icon.svg" alt="data validation icon"/>
                <Translate contentKey="global.menu.datavalidation"/>
              </NavLink>
            </NavItem>
            }

            {(!isUser) &&
            <NavItem>
              <NavLink to="#" onClick={toggleVisualization} >
                <img src="/content/images/icons/data-viz-icon.svg" alt="data visualization icon"/>
                <Translate contentKey="global.menu.datavisualization"/>
              </NavLink>
              <Collapse isOpen={isOpenVisualization}>
                <Nav vertical>
                  <NavItem className={pageLocation.pathname.indexOf('visualizations/charts') > 0 ? 'active' : ''}>
                    <NavLink to="visualizations/charts">
                      <Translate contentKey="global.menu.entities.charts"/>
                    </NavLink>
                  </NavItem>
                  <NavItem className={pageLocation.pathname.indexOf('visualizations/tables') > 0 ? 'active' : ''}>
                    <NavLink to="visualizations/tables">
                      <Translate contentKey="global.menu.entities.tables"/>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Collapse>
            </NavItem>
            }

            {(isAdmin || isTVETAdmin) &&
              <NavItem className={pageLocation.pathname.indexOf('user-management') > 0 ? 'active' : ''}>
                <NavLink to="user-management">
                  <span className="icon dc-icon"/>
                  <img src="/content/images/icons/user-management-icon.svg" alt="user icon"/>
                  <Translate contentKey="global.menu.usermanagement"/>
                </NavLink>
              </NavItem>
            }

            {(isAdmin || isTVETAdmin) &&
              <NavItem className={pageLocation.pathname.indexOf('institution') > 0 ? 'active' : ''}>
                <NavLink to="institution">
                  <span className="icon dc-icon"/>
                  <img src="/content/images/icons/institution-icon.svg" alt="institution icon"/>
                  <Translate contentKey="global.menu.institutionmanagement"/>
                </NavLink>
              </NavItem>
            }

            {(isAdmin || isTVETAdmin) &&
              <NavItem className={pageLocation.pathname.indexOf('survey') > 0 && pageLocation.pathname.indexOf('survey-to-do') === -1 ? 'active' : ''}>
                <NavLink to="survey">
                  <span className="icon dc-icon"/>
                  <img src="/content/images/icons/form-management-icon.svg" alt="form management icon"/>
                  <Translate contentKey="global.menu.formmanagement"/>
                </NavLink>
              </NavItem>
            }

            <NavItem className={pageLocation.pathname.indexOf('account') > 0 ? 'active' : ''}>
              <NavLink to="account">
                <img src="/content/images/icons/settings-icon.svg" alt="settings icon"/>
                <Translate contentKey="global.menu.account.settings"/>
              </NavLink>
            </NavItem>

            {isSuperAdmin  &&
              <NavItem className={pageLocation.pathname.indexOf('syncConfiguration') > 0 ? 'active' : ''}>
                <NavLink to="syncConfiguration">
                <span className="icon dv-icon"/>
                <img src="/content/images/icons/sync-icon.svg" alt="data validation icon"/>
                <Translate contentKey="global.menu.syncConfiguration"/>
              </NavLink>
            </NavItem>
            }

            <NavItem>
              <NavLink to="#" onClick={event => handleLogout(event)}>
                <span className="icon dc-icon"/>
                <Translate contentKey="global.menu.account.logout">Password</Translate>
              </NavLink>
            </NavItem>
          </Col>

          <Col md="10" className="user-content">
            {isAuthenticated && account && (
              <Routes>
                <Route path="/" element={<Welcome/>}/>
                <Route path="/welcome" element={<Welcome/>}/>

                <Route path="/visualizations/charts" element={
                    <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.IREX_ADMIN, AUTHORITIES.TVET_ADMIN,
                      AUTHORITIES.IREX_USER, AUTHORITIES.TVET_USER, AUTHORITIES.VALIDATOR]}>
                      <Charts />
                    </PrivateRoute>
                  }
                />

                <Route path="/visualizations/tables" element={
                    <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.IREX_ADMIN, AUTHORITIES.TVET_ADMIN,
                      AUTHORITIES.IREX_USER, AUTHORITIES.TVET_USER, AUTHORITIES.VALIDATOR]}>
                      <Tables />
                    </PrivateRoute>
                  }
                />

                <Route path="/validation" element={
                    <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.VALIDATOR]}>
                      <Validation />
                    </PrivateRoute>
                  }
                />

                <Route path="/data-collection" element={
                    <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.IREX_ADMIN, AUTHORITIES.TVET_ADMIN,
                      AUTHORITIES.IREX_USER, AUTHORITIES.TVET_USER]}>
                      <DataCollection />
                    </PrivateRoute>
                  }
                />

                <Route path="/user-management/*" element={
                    <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.IREX_ADMIN, AUTHORITIES.TVET_ADMIN]}>
                      <UserManagement />
                    </PrivateRoute>
                  }
                />

                <Route path="/institution/*" element={
                    <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.IREX_ADMIN, AUTHORITIES.TVET_ADMIN]}>
                      <InstitutionRoutes />
                    </PrivateRoute>
                  }
                />

                <Route path="/survey/*" element={
                    <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN, AUTHORITIES.IREX_ADMIN, AUTHORITIES.TVET_ADMIN]}>
                      <SurveysRoutes />
                    </PrivateRoute>
                  }
                />
                <Route path="/account" element={<Settings/>}/>
                <Route path="/password" element={<Password/>}/>
                <Route path="/syncConfiguration" element={
                    <PrivateRoute hasAnyAuthorities={[AUTHORITIES.ADMIN]}>
                      <SyncConfiguration />
                    </PrivateRoute>
                  }
                />
              </Routes>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}
export default UsersHome;
