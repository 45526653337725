import axios from 'axios';
import {serializeAxiosError, UserHomeState} from "app/shared/reducers/reducer.utils";
import {IUserHome} from "app/shared/model/user.model";
import {createAsyncThunk, createSlice, isFulfilled, isPending, isRejected} from '@reduxjs/toolkit';

const initialState: UserHomeState<IUserHome> = {
  loading: false,
  errorMessage: null,
  surveys: [],
  tempEditLink: '',
  tempViewLink: '',
  loadingLink: false,
  linkSuccess: false,
  isAvailableToBeAnswered: null
};

const apiUrl = 'api/surveys/usersSurveys';
const apiRepliesUrl = 'api/replies';
const apiSurveysToDosUrl = 'api/survey-to-dos';

export const checkIsAvailableToBeAnswered = createAsyncThunk(
  'usersHome/check_todo_is_available_to_be_answered',
  async (surveyToDoId: number) => {
    const requestUrl = `${apiSurveysToDosUrl}/check-is-available-to-be-answered/${surveyToDoId}`;
    return axios.get<any>(requestUrl);
  },
  {serializeError: serializeAxiosError},
);


export const getUserSurveys = createAsyncThunk(
  'usersHome/fetch_user_surveys',
  async (login: string) => {
    const requestUrl = `${apiUrl}/${login}`;
    return axios.get<IUserHome>(requestUrl);
  },
  {serializeError: serializeAxiosError},
);

export const getTempEditLink = createAsyncThunk<string, {surveyKoboId: string, replyKoboId: number}>(
  'usersHome/fetchTempEditLink',
  async ({surveyKoboId, replyKoboId}) => {
    const requestUrl = `${apiRepliesUrl}/temp-edit-link/${replyKoboId}/${surveyKoboId}`;
    const response = await axios.get<string>(requestUrl);
    return response.data;
  },
  {serializeError: serializeAxiosError},
);

export const getTempViewLink = createAsyncThunk<string, {surveyKoboId: string, replyKoboId: number}>(
  'usersHome/fetchTempViewLink',
  async ({surveyKoboId, replyKoboId}) => {
    const requestUrl = `${apiRepliesUrl}/temp-view-link/${replyKoboId}/${surveyKoboId}`;
    const response = await axios.get<string>(requestUrl);
    return response.data;
  },
  {serializeError: serializeAxiosError},
);

export const userHomeSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(getUserSurveys.fulfilled, (state, action) => {
        state.loading = false;
        state.surveys = []; // clear the array
        state.surveys.push(action.payload.data);
      })
      .addCase(getUserSurveys.pending, state => {
        state.loading = true;
      })
      .addCase(getUserSurveys.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      })
      
      // getTempViewLink
      .addCase(getTempEditLink.fulfilled, (state, action) => {
        state.loadingLink = false;
        state.linkSuccess = true;
        state.tempEditLink = action.payload;
      })
      .addCase(getTempEditLink.pending, state => {
        state.tempEditLink = '';
        state.linkSuccess = false;
        state.loadingLink = true;
      })
      .addCase(getTempEditLink.rejected, (state, action) => {
        state.tempEditLink = '';
        state.loadingLink = false;
        state.linkSuccess = false;
        state.errorMessage = action.error.message;
      })

      // getTempViewLink
      .addCase(getTempViewLink.fulfilled, (state, action) => {
        state.loadingLink = false;
        state.linkSuccess = true;
        state.tempViewLink = action.payload;
      })
      .addCase(getTempViewLink.pending, state => {
        state.tempViewLink = '';
        state.linkSuccess = false;
        state.loadingLink = true;
      })
      .addCase(getTempViewLink.rejected, (state, action) => {
        state.tempViewLink = '';
        state.loadingLink = false;
        state.linkSuccess = false;
        state.errorMessage = action.error.message;
      })
      
      // Check ToDo is available to be answered
      .addCase(checkIsAvailableToBeAnswered.fulfilled, (state, action) => {        
        const { data } = action.payload;

        return {
          ...state,
          loading: false,
          isAvailableToBeAnswered: !data.isError,
          errorMessage: data.isError ? data.data : '',
        };
      })
      .addCase(checkIsAvailableToBeAnswered.pending, state => {
        state.isAvailableToBeAnswered = null;
        state.errorMessage = '';
        state.loading = true;
      })
      .addCase(checkIsAvailableToBeAnswered.rejected, (state, action) => {
        state.isAvailableToBeAnswered = false;
        state.errorMessage = action.error.message;
        state.loading = false;
      })
      ;
  },
  initialState,
  name: 'userHome',
  reducers: {
    reset() {
      return initialState;
    },
  },
});

export default userHomeSlice.reducer;
