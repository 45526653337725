import { IQuestion } from 'app/shared/model/question.model';

export interface IQuestionOption {
  id?: number;
  name?: string;
  value?: string;
  question?: IQuestion | null;
}

export const defaultValue: Readonly<IQuestionOption> = {};
