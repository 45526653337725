import React from 'react';
import {Translate, translate} from 'react-jhipster';
import {Row, Col} from 'reactstrap';
import {useAppSelector} from "app/config/store";
import Home from '../home/home';

const Welcome = () => {
  const account = useAppSelector(state => state.authentication.account);
  return (

    <Row>
      <Row className="banner">
        <Col md="5" className="col">
          <h1 className="px-3"><Translate contentKey="home.pagetitle" /></h1>
        </Col>
        <Col md="7" className="col banner-img">
        </Col>
      </Row>
      <Row className="home-content px-5 py-5">
          <h2><Translate contentKey="home.introtitle" /></h2>
          <p><Translate contentKey="home.intro" /></p>

          <div className="partners-logos main-partners">
            <img src="content/images/usaid-logo.svg" alt="USAID Logo" style={{maxWidth: "15%"}}/>
            <img src="content/images/irex-logo.svg" alt="Irex Logo" style={{maxWidth: "9%"}}/>           
          </div>

          <h2 className='partners-title'><Translate contentKey="home.partners" /></h2>

          <div className="partners-logos partners-welcome">
            <img src="content/images/dai-logo.svg" alt="DAI Logo"/>
            <img src="content/images/va-tech-logo.svg" alt="VA tech Logo"/>
            <img src="content/images/dg-logo.svg" alt="DG Logo"/>
            <img src="content/images/bwfp-logo.png" alt="Business Women Forum Palestine Logo"/>
            <img src="content/images/bethlehem-chamber-of-commerce.png" alt="Bethlehem Chamger of Commerce and Industry Logo"/>
            <img src="content/images/partner-logo.png" alt="Partner Logo"/>
          </div>

          <div className='partnersText'>
            <p> <Translate contentKey='home.partnersText' /> </p>
          </div>
        {/* <Col md="6" className="col-right">
          {account?.login ? (
            <div>
              <Row className="banner">
                <h1><Translate contentKey="home.title"/></h1>
                <p><span>
                  <Translate contentKey="main.welcome" interpolate={{login: account.login}}>Welcome !
                    Below are the survey forms assigned to you.
                    Forms marked as &apos;pending&apos; are awaiting your input. Remember, when you complete a form, its status will change to
                    &apos;answered&apos;. When you fill out the &quot;Step 1&quot; form of a section, the information submitted will be automatically retrieved
                    and populated into the corresponding &quot;Step 2&quot; form of the same section.
                    This means that you don&apos;t need to re-enter the same information when progressing to the next step
                  </Translate>
                </span>
                </p>
              </Row>
            </div>
          ) : (
            <div>
            </div>
          )}

        </Col> */}
      </Row>

    </Row>

  );
}
export default Welcome;
