import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText, FormGroup, Input, Label } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm, isEmail } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { locales, languages } from 'app/config/translation';
import { getUser, getRoles, updateUser, createUser, reset, resetPassword } from './user-management.reducer';
import { getEntitiesToListBox as getInstitutions, getEntitiesByUser as getInstitutionsByUser} from 'app/entities/institution/institution.reducer';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { AUTHORITIES } from 'app/config/constants';
import DualListbox from 'app/shared/components/DualListbox';
import { ListboxItem, transformAuthoritiesListToListboxItems, transformToListboxItems } from 'app/shared/util/ListboxItem';
import { extractIds } from 'app/shared/util/entity-utils';
import FieldFormWithValidation from 'app/shared/components/FieldFormWithValidation';
import { toast } from 'react-toastify';

export const UserManagementUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [isTvetAdmin, setIsTvetAdmin] = useState(false);
  const [institutionsSelected, setInstitutionsSelected] = useState([])
  const [institutionsCompleted, setInstitutionsCompleted] = useState(false);
  const [rolesSelected, setRolesSelected] = useState([])


  const { login } = useParams<'login'>();
  const isNew = login === undefined;

  const [userName, setUserName] = useState(login);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [activated, setActivated] = useState(true);
  const [langKey, setLangKey] = useState('en');

  const [fieldErrors, setFieldErrors] = useState<{ [key: string]: boolean }>({});

  const account = useAppSelector(state => state.authentication.account);
  const institutions = useAppSelector(state => state.institution.entities);
  const institutionsByUser = useAppSelector(state => state.institution.institutionsByUser);
  const institutionsByUserLoading = useAppSelector(state => state.institution.institutionsByUserLoading);

  const isInvalid = false;
  const user = useAppSelector(state => state.userManagement.user);
  const loading = useAppSelector(state => state.userManagement.loading);
  const updating = useAppSelector(state => state.userManagement.updating);
  const authorities = useAppSelector(state => state.userManagement.authorities);


  useEffect(() => {
    if (isNew) {
      dispatch(reset());
      setInstitutionsSelected([]);
      setRolesSelected([]);
    } else {
      dispatch(getUser(login));
      dispatch(getInstitutionsByUser(login));
    }
    dispatch(getRoles());

    return () => {
      dispatch(reset());
    };
  }, [login]);

  useEffect(() => {
    if (user && user.authorities) {
      setRolesSelected(transformAuthoritiesListToListboxItems(user.authorities));
      setUserName(user.login);
      setFirstName(user.firstName);
      setLastName(user.lastName);
      setEmail(user.email);
      setActivated(user.activated);
      setLangKey(user.langKey);
    }
  }, [user]);


  useEffect(() => {
    if (account && account.login) {
      account.authorities.forEach(authority => {
        if (authority === AUTHORITIES.TVET_ADMIN) {
          setIsTvetAdmin(true);
        }
      });
      dispatch(getInstitutions(account.login));
    }
  }, [account]);

  useEffect(() => {
    setInstitutionsCompleted(false);
    if (isNew) {
      setInstitutionsSelected([]);
      setInstitutionsCompleted(institutions.length > 0);
    } else {
      setInstitutionsSelected([]);
      if (institutions.length > 0 && institutionsByUserLoading === false) {
        setInstitutionsSelected(transformToListboxItems(institutionsByUser));
        setInstitutionsCompleted(true);
      } else setInstitutionsCompleted(false);
    }

  }, [institutions, institutionsByUser]);

  const handleClose = () => {
    navigate('/usersHome/user-management');
  };

  const saveUser = values => {
    if (!isFormValid()) {
      toast.error(translate('irexForsahApp.institution.validations.haveErrors'));
      return;
    }

    const entity = {
      ...user,
      ...values,
      login: userName,
      firstName,
      lastName,
      email,
      activated,
      langKey
    }

    if (isTvetAdmin) {
      entity.authorities = [AUTHORITIES.TVET_USER];
    } else {
      entity.authorities = extractIds(rolesSelected);
      entity.linkedInstitutions = extractIds(institutionsSelected);
    }

    if (entity.authorities.length === 0) {
      toast.error(translate('userManagement.profiles.required'));
      return;
    }
    

    if (isNew) {
      dispatch(createUser(entity));
    } else {
      dispatch(updateUser(entity));
    }
    handleClose();
  };

  const resetUserPassword = (pEmail: string) => {
    if (isNew === false) {
      dispatch(resetPassword(pEmail));
      handleClose();
    }
  }
  const handleInstitutionSelectionChange = (newSelectedItems: ListboxItem[]) => {
    setInstitutionsSelected(newSelectedItems);
  };

  const handleRolSelectionChange = (newSelectedItems: ListboxItem[]) => {
    setRolesSelected(newSelectedItems);
  };



  const handleFieldErrorChange = (fieldName: string, hasError: boolean) => {
    setFieldErrors(prev => ({
      ...prev,
      [fieldName]: hasError
    }));
  };

  const isFormValid = () => {
    return Object.values(fieldErrors).every(error => !error);
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="10">
          <h1>
            <Translate contentKey="userManagement.home.createOrEditLabel">Create or edit a User</Translate>
          </h1>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="10">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm onSubmit={saveUser} defaultValues={user}>
            <Row className='mt-30'>
              {user.id ? (
                <Col>                
                  <FieldFormWithValidation
                      label={translate('global.field.id')}
                        id="id"
                        name="id"
                        type="text"
                        value={user.id}                     
                        validate={{
                          required: { value: true, message: translate('entity.validation.required') },
                        }}
                        isReadOnly={true}
                      />                    
                </Col>
              ) : null}

              <Col>
                <FieldFormWithValidation
                    type="text"
                    id="login"
                    name="login"
                    label={translate('userManagement.login')}
                    validate={{
                      required: {
                        value: true,
                        message: translate('register.messages.validate.login.required'),
                      },
                      pattern: {
                        value: /^[a-zA-Z0-9!$&*+=?^_`{|}~.-]+@[a-zA-Z0-9-]+(?:\\.[a-zA-Z0-9-]+)*$|^[_.@A-Za-z0-9-]+$/,
                        message: translate('register.messages.validate.login.pattern'),
                      },
                      minLength: {
                        value: 1,
                        message: translate('register.messages.validate.login.minlength'),
                      },
                      maxLength: {
                        value: 50,
                        message: translate('register.messages.validate.login.maxlength'),
                      },
                    }}
                    value={userName}
                    onChange={setUserName}
                    onError={handleFieldErrorChange}                    
                  />
              </Col>


              <Col>
                <FieldFormWithValidation
                  type="text"
                  id="firstName"
                  name="firstName"
                  label={translate('userManagement.firstName')}
                  validate={{
                    required: {
                      value: true,
                      message: translate('register.messages.validate.firstName.required'),
                    },
                    maxLength: {
                      value: 50,
                      message: translate('entity.validation.maxlength', { max: 50 }),
                    },
                  }}
                  value={firstName}
                  onChange={setFirstName}
                  onError={handleFieldErrorChange} 
                />
              
              </Col>


              <Col>
                <FieldFormWithValidation
                  type="text"
                  id="lastName"
                  name="lastName"
                  label={translate('userManagement.lastName')}
                  validate={{
                    required: {
                      value: true,
                      message: translate('register.messages.validate.lastName.required'),
                    },
                    maxLength: {
                      value: 50,
                      message: translate('entity.validation.maxlength', { max: 50 }),
                    },
                  }}
                  value={lastName}
                  onChange={setLastName}
                  onError={handleFieldErrorChange} 
                />
                
              </Col>
            </Row>

            <Row>
              <div className={`sm-6 ${user.id ? 'offset-md-6' : 'offset-md-4'}`}>
                <FormText>
                  <Translate contentKey="userManagement.maxNamesChars">maxNamesChars</Translate>
                </FormText>
              </div>
            </Row>

            <div className='mt-30'></div>

            {
              isTvetAdmin === false && authorities && authorities.length > 0 &&
              <DualListbox
                items={transformAuthoritiesListToListboxItems(authorities)}
                selectedItems={rolesSelected}
                label={translate('userManagement.profiles')}
                id="user-authorities"
                name="authorities"
                onSelectionChange={handleRolSelectionChange}
              />
            }

            
            <Row className='mb-3'>
              <Col>
                <FieldFormWithValidation
                  id="email"
                  name="email"
                  label={translate('global.form.email.label')}
                  type="email"
                  validate={{
                    required: {
                      value: true,
                      message: translate('global.messages.validate.email.required'),
                    },
                    minLength: {
                      value: 5,
                      message: translate('global.messages.validate.email.minlength'),
                    },
                    maxLength: {
                      value: 254,
                      message: translate('global.messages.validate.email.maxlength'),
                    },
                    emailPattern: { value: email, message: translate('global.messages.validate.email.invalid') }
                  }}
                  value={email}
                  onChange={setEmail}
                  onError={handleFieldErrorChange}
                />
              </Col>

              <Col md="2">
                <FormGroup switch style={{marginTop: 12}}>
                    <Input
                      type="switch"
                      checked={activated}
                      onClick={() => { setActivated(!activated); }}
                      style={{width: '4rem', height: '2rem'}}
                    />
                    <Label check style={{marginTop: 5, marginLeft: 10}}>{translate('userManagement.activated')}</Label>
                  </FormGroup>
              </Col>

              <Col md="3">
                <ValidatedField type="select" name="langKey" label={translate('userManagement.langKey')}
                  value={langKey} onChange={(e) => setLangKey(e.target.value)}>
                  {locales.map(locale => (
                    <option value={locale} key={locale}>
                      {languages[locale].name}
                    </option>
                  ))}
                </ValidatedField>
              </Col>
            </Row>    

            {
              isTvetAdmin === false && institutionsCompleted &&
              <DualListbox
                items={transformToListboxItems(institutions)}
                selectedItems={institutionsSelected}
                label={translate('userManagement.institutions')}
                id="user-institution"
                name="institution"
                onSelectionChange={handleInstitutionSelectionChange}
              />
            }

            <Row>
              <Col>
                <Button tag={Link} to="/usersHome/user-management" replace color="info">
                  <FontAwesomeIcon icon="arrow-left" />
                  &nbsp;
                  <span className="d-none d-md-inline">
                    <Translate contentKey="entity.action.back">Back</Translate>
                  </span>
                </Button>
                &nbsp;

                <Button color="primary" type="submit" disabled={isInvalid || updating}>
                  <FontAwesomeIcon icon="save" />
                  &nbsp;
                  <Translate contentKey="entity.action.save">Save</Translate>
                </Button>
              </Col>
              {
                isNew === false &&
                <Col className="d-flex justify-content-end">
                  <Button onClick={() => resetUserPassword(user.email)} replace color="secondary">
                    <FontAwesomeIcon icon="sync" />
                    &nbsp;
                    <span className="d-none d-md-inline">
                      <Translate contentKey="userManagement.resetPassword">Reset Password</Translate>
                    </span>
                  </Button>
                </Col>
              }

            </Row>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default UserManagementUpdate;
