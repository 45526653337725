import React from 'react';
import { Translate, translate, ValidatedField } from 'react-jhipster';
import { Button, Alert, Row, Col, Form } from 'reactstrap';
import { Link } from 'react-router-dom';
import { type FieldError, useForm } from 'react-hook-form';

import '../../../content/css/content.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface LoginForm {
  loginError: boolean;
  handleLogin: (username: string, password: string, rememberMe: boolean) => void;
  handleClose: () => void;
}

class ILoginFormProps {
  loginError: boolean;
  loginLoading: boolean;
  handleLogin: (username: string, password: string, rememberMe: boolean) => void;
}

const LoginForm = (props: ILoginFormProps) => {
  const {loginError, loginLoading} = props;
  const login = ({ username, password, rememberMe }) => {
    props.handleLogin(username, password, rememberMe);
  };

  const {
    handleSubmit,
    register,
    formState: { errors, touchedFields },
  } = useForm({ mode: 'onTouched' });


  const handleLoginSubmit = e => {
    handleSubmit(login)(e);
  };

  return (
    <Row id="login-form-wrapper" autoFocus={false}>
      <Form onSubmit={handleLoginSubmit}>
        <div id="login-title" data-cy="loginTitle">
        </div>
        <div className="login-form-content">
          <Row>
            <Col md="12" className="no-padding">
              {
                loginLoading &&
                <Row className="justify-content-center">
                  <Col md="1">
                    <FontAwesomeIcon className="justify-content-center" icon="sync" spin={true} size="4x"/>
                  </Col>
                </Row>
              }

              {
                loginError && loginLoading === false ? (
                <Alert color="danger" data-cy="loginError">
                  <Translate contentKey="login.messages.error.authentication">
                    <strong>Failed to sign in!</strong> Please check your credentials and try again.
                  </Translate>
                </Alert>
                ) : null
              }
            </Col>
            <Col md="12" className="no-padding">
              <ValidatedField
                name="username"
                label={translate('global.form.username.label')}
                placeholder={translate('global.form.username.placeholder')}
                required
                autoFocus
                data-cy="username"
                validate={{ required: 'Username cannot be empty!' }}
                register={register}
                error={errors.username as FieldError}
                isTouched={touchedFields.username}
              />
              <ValidatedField
                name="password"
                type="password"
                label={translate('login.form.password')}
                placeholder={translate('login.form.password.placeholder')}
                required
                data-cy="password"
                validate={{ required: 'Password cannot be empty!' }}
                register={register}
                error={errors.password as FieldError}
                isTouched={touchedFields.password}
              />
              <ValidatedField
                name="rememberMe"
                type="checkbox"
                check
                label={translate('login.form.rememberme')}
                value={true}
                register={register}
              />
            </Col>
          </Row>
        </div>
        <div className="form-footer">
          <Button color="primary" type="submit" data-cy="submit">
            <Translate contentKey="login.form.button">Log In to the Dashboard</Translate>
          </Button>
        </div>
        <Row className="form-bottom-bar">
          <Col md="12" className="form-links right">
            <Link to="/account/reset/request" data-cy="forgetYourPasswordSelector">
              <Translate contentKey="login.password.forgot">Reset password</Translate>
            </Link>
          </Col>

        </Row>
      </Form>
      </Row>
  );
};

export default LoginForm;
