
export interface IFileAttachement {
  filename?: string;
  url?: string;
}

export interface IReviewDetailAttachment {
  questionLabel?: string;
  fiiles?: IFileAttachement[];
}

export interface IReviewDetail {
  todoId?: number;
  todoStatus?: string;
  todoSurveyId?: number;
  todoReplyId?: number;
  todoComment?: string;
  
  surveyName?: string;
  surveyKoboId?: string;
  surveyType?: string;
  
  replyKoboId?: number;
  replySubmmitter?: string;
  replyDate?: string;
  replyReviewComment?: string;

  dataCollection_SurveyKoboId?: string;
  dataCollection_SurveyName?: string;
  dataCollection_ReplyKoboId?: number;

  attachments?: IReviewDetailAttachment[];
}

export interface IDataValidation {
  institutionName?: string;
  institutionAddress?: string;
  institutionRegion?: string;
  institutionGovernorate?: string;
  institutionContactName?: string;
  institutionEmail?: string;
  institutionMobilePhone?: string;
  institutionPhone?: string;

  details?: IReviewDetail[] | null;
}   

export const defaultValue: Readonly<IDataValidation> = {};
