import dayjs from 'dayjs';
import { ISurvey } from 'app/shared/model/survey.model';

export interface ISurveyVersion {
  id?: number;
  version?: string;
  versionDate?: dayjs.Dayjs | null;
  survey?: ISurvey;
}

export const defaultValue: Readonly<ISurveyVersion> = {};
