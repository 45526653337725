import React, {useEffect, useState} from 'react';
import ReactEcharts from 'echarts-for-react';
import Filter from "app/shared/filter/filter-component";
import {getRadarData} from "./radar-chart-reducer";
import {Spinner} from 'reactstrap';
import {useAppDispatch, useAppSelector} from "app/config/store";
import colors from "app/shared/util/colors-utils";

const RadarChart: React.FC = () => {
  const dispatch = useAppDispatch();
  const title = useAppSelector((state) => state.radarChartsSlice.radarData.title);
  const legend = useAppSelector((state) => state.radarChartsSlice.radarData.legend);
  const indicators = useAppSelector((state) => state.radarChartsSlice.radarData.indicators);
  const series = useAppSelector((state) => state.radarChartsSlice.radarData.series);
  const loading = useAppSelector((state) => state.radarChartsSlice.loading);
  const institutions = useAppSelector((state) => state.filterSlice.institutions);
  const years = useAppSelector((state) => state.filterSlice.years);
  const selectedInstitutions = useAppSelector((state) => state.filterSlice.selectedInstitutions);
  const selectedYears = useAppSelector((state) => state.filterSlice.selectedYears);
  const [error, setError] = useState(null);


  const [radarFilterState, setRadarFilterState] = useState({
    selectedInstitutions,
    selectedYears,
    institutions,
    years
  });

  useEffect(() => {
    setRadarFilterState(prevState => ({
      ...prevState,
      institutions,
      years,
      selectedInstitutions,
      selectedYears
    }));
  }, [selectedInstitutions, selectedYears, institutions, years]);

  useEffect(() => {
    if (radarFilterState.selectedInstitutions && radarFilterState.selectedInstitutions.length > 0 && radarFilterState.selectedYears.length > 0) {
      handleRadarFilterChange({
        selectedInstitutions: radarFilterState.selectedInstitutions,
        selectedYears: radarFilterState.selectedYears
      });
    }
  }, [radarFilterState]);


  const handleRadarFilterChange = (radarFilter: {
    selectedInstitutions: number[]; selectedYears: number[]
  }) => {
    if (radarFilter.selectedInstitutions && radarFilter.selectedYears
      && radarFilter.selectedInstitutions.length < 1 || radarFilter.selectedYears.length < 1) {
      setError("Institutions and Years are required.");
    } else if (radarFilter.selectedInstitutions.length > 1 && radarFilter.selectedYears.length > 1) {
        setError("Only one Institution can be used for year comparison");
    } else {
      dispatch(getRadarData(radarFilter));
    }

  }


  const option = {
    title: {
      text: title ? title : '',
    },
    tooltip: {
      trigger: 'item'
    },
    legend: {
      data: legend && legend.data ? legend.data : [],
    },
    toolbox: {
      orient: 'vertical',
      left: 'right',
      top: 'center',
      borderWidth:2,
      borderColor: "#19494b",
      backgroundColor: "#19494b34",
      borderCap: 'round',
      borderJoin: 'round',
      iconStyle: {
        color: "#fafafa",
        borderColor: "#19494b"
      },
      feature: {
        mark: {show: true},
        dataView: {show: true, readOnly: false},
        saveAsImage: {show: true}
      }
    },
    radar: {
      indicator: indicators,
    },
    series: series ? series.map((seriesItem: any, index: number) => ({
      ...seriesItem,
      itemStyle: {
        color: colors[index % colors.length]
      }
    })) : []
  };

  return (
    <div>
      <div>
        <Filter handleFilterChange={handleRadarFilterChange}
                filterState={radarFilterState}
                maxInstitutions={6}
                useDefaults={true}
        />
      </div>
      {error &&  <div>{error}</div>}
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '600px', width: '100%'}}>
        {loading ? (
          <Spinner style={{width: '3rem', height: '3rem'}}/>
        ) : (
          <ReactEcharts option={option} style={{height: '500px', width: '80%'}}/>
        )}
      </div>
    </div>
  );
}

export default RadarChart;
