import React, { useEffect, useMemo, useState } from "react";
import { MRT_Row, MaterialReactTable, useMaterialReactTable, type MRT_ColumnDef } from 'material-react-table'
import { ITableScoringMatrixRow } from "app/shared/model/tables_visualization/table-scoring-matrix-row.model";
import {getScoringMatrix} from "./table-scoring-matrix-reducer";
import {Translate, translate} from 'react-jhipster';
import {useAppDispatch, useAppSelector} from "app/config/store";
import LoadingSpinner from "app/shared/components/LoadingSpinner";
import { Box } from "@mui/material";
import { Alert, Button } from "reactstrap";
import Filter from "app/shared/filter/filter-component";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { COLOR_PALLETE } from "app/config/constants";
import { fontsObject, getBase64Image, getPdfContentWithCoverPage, loadSVG } from "app/modules/visualizations/pdfResources";

import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


export const ScoringMatrix = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.tableScoringMatrixReducer.loading);
  const scoringMatrixData = useAppSelector((state) => state.tableScoringMatrixReducer.entity);

  const filterState = useAppSelector((state) => state.filterSlice);
  const institutions = useAppSelector((state) => state.filterSlice.institutions);
  const years = useAppSelector((state) => state.filterSlice.years);

  const [tableSMFilterState, setTableSMFilterState] = useState({
    selectedInstitutions: [],
    selectedYears: [],
    institutions,
    years
  });

  const [scoringMatrix, setScoringMatrix] = useState([]);
  const [institutionSelected, setInstitutionSelected] = useState(null);
  const [yearSelected, setYearSelected] = useState('');

  const [logoUsaid, setLogoUsai] = useState<string | null>(null);
  const [logoIrex, setLogoIrex] = useState<string | null>(null);
  const [logoDai, setLogoDai] = useState<string | null>(null);
  const [logoVATech, setLogoVATech] = useState<string | null>(null);
  const [logoDG, setLogoDG] = useState<string | null>(null);
  const [logoPartner4, setLogoPartner4] = useState<string | null>(null);
  const [logoPartner5, setLogoPartner5] = useState<string | null>(null);
  const [logoPartner6, setLogoPartner6] = useState<string | null>(null);

  pdfMake.fonts = fontsObject;

  const columns = useMemo(
    () => [
      {
        header: `${translate('irexForsahApp.tables.columns.section')}`,
        accessorKey: 'section',
      },
      {
        header: `${translate('irexForsahApp.tables.columns.subsection')}`,
        accessorKey: 'subSection',
      },
      {
        header: `${translate('irexForsahApp.tables.columns.criteria')}`,
        accessorKey: 'criteria',
      },
      {
        header: `${translate('irexForsahApp.tables.columns.objective')}`,
        accessorKey: 'objective',
      },
      {
        header: `${translate('irexForsahApp.tables.scoringMatrix.columns.score')}`,
        accessorKey: 'score',
      },
      {
        header: `${translate('irexForsahApp.tables.scoringMatrix.columns.capacityBuilding')}`,
        accessorKey: 'capacityBuilding',
      },
      {
        header: `${translate('irexForsahApp.tables.scoringMatrix.columns.facilitatorComments')}`,
        accessorKey: 'facilitatorComments',
      }
    ],[]
  );

  useEffect(() => {
    loadPngLogos();
    loadSvgLogos();
  }, []);

  useEffect(() => {
    const selectedInstitutions = filterState.selectedInstitutions;
    const selectedYears = filterState.selectedYears;
    if (selectedInstitutions && selectedInstitutions.length > 0 && selectedYears && selectedYears.length > 0) {
      dispatch(getScoringMatrix({selectedInstitutions, selectedYears}));
    }
  }, [dispatch, filterState.selectedInstitutions, filterState.selectedYears]);

  useEffect(() => {
    setTableSMFilterState(prevState => ({
      ...prevState,
      institutions,
      years
    }));
  }, [institutions, years]);

  useEffect(() => {
    if (!loading && scoringMatrixData && scoringMatrixData.rows) {
      const rowsAux = scoringMatrixData.rows.map((row, index) => {
        return {
            ...row,
            section: row.section.split(':')[1].trim()
          }
      });
      setScoringMatrix(rowsAux);
    } else if (scoringMatrixData === undefined) {
      setScoringMatrix([]);
    }
  }, [loading, scoringMatrixData, columns]);

  const loadSvgLogos = async () => {
    const usaidLogo = await loadSVG('content/images/usaid-logo.svg');
    setLogoUsai(usaidLogo);

    const irexLogo = await loadSVG('content/images/irex-logo.svg');
    setLogoIrex(irexLogo);

    const daiLogo = await loadSVG('content/images/dai-logo.svg');
    setLogoDai(daiLogo);

    const vaTechLogo = await loadSVG('content/images/va-tech-logo.svg');
    setLogoVATech(vaTechLogo);

    const dgLogo = await loadSVG('content/images/dg-logo.svg');
    setLogoDG(dgLogo);
  };

  const loadPngLogos = async () => {
    try {
        const base64_p4 = await getBase64Image('content/images/bwfp-logo.png');
        setLogoPartner4(`data:image/png;base64,${base64_p4}`);

        const base64_p5 = await getBase64Image('content/images/bethlehem-chamber-of-commerce.png');
        setLogoPartner5(`data:image/png;base64,${base64_p5}`);

        const base64_p6 = await getBase64Image('content/images/partner-logo.png');
        setLogoPartner6(`data:image/png;base64,${base64_p6}`);
    } catch (error) {
        console.error('Error loading image', error);
    }
  };

  const table = useMaterialReactTable({
    columns,
    data: scoringMatrix ? scoringMatrix : [],
    enableRowSelection: false,
    enableGrouping: true,
    enableStickyHeader: true,
    initialState: {
      grouping: ['section', 'subSection'],
      pagination: { pageIndex: 0, pageSize: 20 },
    },
    muiTableContainerProps: { sx: { maxHeight: '600px' } },
    renderTopToolbarCustomActions: ({ table: tbl }) => (
      <Box sx={{ display: 'flex', gap: '16px', padding: '8px', flexWrap: 'wrap', }} >
        <Button
          disabled={tbl.getPrePaginationRowModel().rows.length === 0}
          // export all rows, including from the next page, (still respects filtering and sorting)
          onClick={printAllRows}
        >
          <FontAwesomeIcon icon={faDownload} />{' '}
          <Translate contentKey="irexForsahApp.tables.buttons.exportAllRows" />
        </Button>

        <Button
          disabled={tbl.getRowModel().rows.length === 0}
          // export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
          onClick={() => printSelectedRows(tbl.getRowModel().rows)}
        >
          <FontAwesomeIcon icon={faDownload} />{' '}
          <Translate contentKey="irexForsahApp.tables.buttons.exportPageRows" />
        </Button>
        {/* <Button
          disabled={ !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected() }
          //only export selected rows
          onClick={() => printSelectedRows(table.getSelectedRowModel().rows)}
          startIcon={<FileDownloadIcon />}
        >
          <Translate contentKey="irexForsahApp.tables.buttons.exportSelectedRows" />
        </Button> */}
      </Box>
    )
  });


  const printAllRows = () => {
    const rows = scoringMatrixData.rows;
    
    const tableData: any[] = [];
    rows.forEach((row) => {
        tableData.push({
            section: row.section,
            subSection: row.subSection,
            criteria: row.criteria,
            objective: row.objective,
            score: row.score,
            capacityBuilding: row.capacityBuilding,
            facilitatorComments: row.facilitatorComments
        });
    });

    printPDFGroupedRows(tableData);
  }

  const printSelectedRows = (rows: MRT_Row<ITableScoringMatrixRow>[]) => {
    const tableData: any[] = [];
    rows.forEach((row) => {
        tableData.push({
            section: row.original.section,
            subSection: row.original.subSection,
            criteria: row.original.criteria,
            objective: row.original.objective,
            score: row.original.score,
            capacityBuilding: row.original.capacityBuilding,
            facilitatorComments: row.original.facilitatorComments
        });
    });

    printPDFGroupedRows(tableData);
  }


  const printPDFGroupedRows = (tableData: any[]) => {

    const groupedData: any[] = [];
    let currentSection = '';
    let currentSubSection = '';
    const today = new Date();
    const footerDate = `${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}-${today.getFullYear()}`; // MM-dd-yyyy

    tableData.forEach((item) => {
        if (item.section !== currentSection) {
            currentSection = item.section;
            currentSubSection = item.subSection;
            groupedData.push([
                { 
                    text: currentSection, 
                    rowSpan: tableData.filter(d => d.section === currentSection).length,
                    style: 'group1'
                }, 
                { 
                    text: currentSubSection, 
                    rowSpan: tableData.filter(d => d.section === currentSection && d.subSection === currentSubSection).length,
                    style: 'group2'
                }, 
                { text: item.criteria || '', style: 'smallText' },
                { text: item.objective || '', style: 'smallText' },
                { text: item.score || '', style: 'smallText', alignment: 'center' },
                { text: item.capacityBuilding || '', style: 'smallText' },
                { text: item.facilitatorComments || '', style: 'smallText' }
            ]);
        } else if (item.subSection !== currentSubSection) {
            currentSubSection = item.subSection;
            groupedData.push([
                '', 
                { 
                    text: item.subSection, 
                    rowSpan: tableData.filter(d => d.section === currentSection && d.subSection === currentSubSection).length,
                    style: 'group2'
                }, 
                { text: item.criteria || '', style: 'smallText' },
                { text: item.objective || '', style: 'smallText' },
                { text: item.score || '', style: 'smallText', alignment: 'center' },
                { text: item.capacityBuilding || '', style: 'smallText' },
                { text: item.facilitatorComments || '', style: 'smallText' }
            ]);
        } else {
            groupedData.push([
                '', 
                '', 
                { text: item.criteria || '', style: 'smallText' },
                { text: item.objective || '', style: 'smallText' },
                { text: item.score || '', style: 'smallText', alignment: 'center' },
                { text: item.capacityBuilding || '', style: 'smallText' },
                { text: item.facilitatorComments || '', style: 'smallText' }
            ]);
        }
    });

    const tableHeaders = columns.map((c) => ({
        text: c.header,
        style: 'header'
    }));

    const pdfContent = getPdfContentWithCoverPage('Scoring Matrix', logoUsaid, logoIrex, logoDai, logoVATech, logoDG, logoPartner4, logoPartner5, logoPartner6);
    pdfContent.push({
      // layout: 'lightHorizontalLines',
      table: {
        headerRows: 1,
        widths: [70, '*', '*', '*', 43, '*', '*'],
        body: [
          tableHeaders,
          ...groupedData
        ]
      }
    });

    const docDefinition = {
      pageOrientation: 'landscape',
      pageMargins: 40,
      header(currentPage) {
        let headerResult = {};

        if (currentPage === 1) {
          return headerResult;
        }

        if (institutionSelected.logo) {
          headerResult = {
            columns: [
              { text: `Scoring Matrix`, alignment: 'left', margin: [40, 13, 0, 0], style: 'footer'},
              { text: institutionSelected.name + ' | ' + yearSelected, alignment: 'center', margin: [0, 13, 0, 0], style: 'footer'},
              {
                image: 'data:image/jpeg;base64,' + institutionSelected.logo,
                fit: [25, 25],
                alignment: 'right',
                margin: [0, 10, 20, 0]
              }
            ]
          }
        } else {
          headerResult = {
            columns: [
              { text: `Scoring Matrix`, alignment: 'left', margin: [40, 13, 0, 0], style: 'footer'},
              { text: institutionSelected.name + ' | ' + yearSelected, alignment: 'right', margin: [0, 13, 40, 0], style: 'footer'}
            ]
          }
        }

        return headerResult;        
      },       
      footer(currentPage, pageCount) {
        if (currentPage === 1) {
          return {};
        }

        return {
          columns: [
            { text: footerDate, alignment: 'left', margin: [40, 13, 0, 0], style: 'footer'},
            { text: currentPage.toString() + ' / ' + pageCount, alignment: 'right', margin: [0, 13, 40, 0], style: 'footer'}
          ]
        };
      },
      content: pdfContent,
      defaultStyle: {
        font: 'GillSans'
      },
      styles: {
        institutionName: {
          fontSize: 27,
          bold: true,
          color: COLOR_PALLETE.USAID_BLUE
        },
        coverPageSub: {
          fontSize: 25,
          bold: true,
          color: COLOR_PALLETE.USAID_RED,
          alignment: 'center'
        },
        coverPageSub2: {
          fontSize: 18,
          color: COLOR_PALLETE.RICH_BLACK,
          alignment: 'center'
        },
        coverDisclaimer: {
          fontSize: 10,
          italic: true,
          color: COLOR_PALLETE.DARK_GRAY,
          alignment: 'center',
          margin: [0, 15, 0, 0]
        },           
        header: {
          fontSize: 15,
          bold: true,
          color: '#fafafa',
          fillColor: COLOR_PALLETE.USAID_BLUE,
          margin: [0, 10, 0, 0]
        },
        group1: {
            fontSize: 13,
            bold: true,
            italics: true,
            color: COLOR_PALLETE.USAID_RED
        },
        group2: {
            bold: true,
            italics: true,
            color: COLOR_PALLETE.USAID_RED
        },
        smallText: {
            fontSize: 10
        },
        footer: {
          fontSize: 7,
          bold: true,
          italics: true,
          color: COLOR_PALLETE.MEDIUM_GRAY
        },
        redBold: {
          bold: true,
          color: COLOR_PALLETE.USAID_RED
        }
      }
    };

    const formattedDate = today.toISOString().split('T')[0]; // YYYY-MM-DD
    pdfMake.createPdf(docDefinition).download(`Scoring Matrix-${formattedDate} - ${institutionSelected.name} - ${yearSelected}.pdf`);
  }





  const handleFilterChange = (filter: { selectedInstitutions: number[]; selectedYears: number[] }) => {
    if (filter.selectedInstitutions.length > 0 && filter.selectedYears.length > 0) {
      dispatch(getScoringMatrix(filter));
      setTableSMFilterState({
        ...filter,
        institutions: tableSMFilterState.institutions,
        years: tableSMFilterState.years
      });
      const institution = tableSMFilterState.institutions.find(i => i.id === filter.selectedInstitutions[0]);
      setInstitutionSelected((institution ? institution : null));
      setYearSelected(filter.selectedYears[0].toString());
    }
  }


  return (

    <div className="validation-wrapper">
      <div style={{marginBottom: '20px'}}>
        <Filter handleFilterChange={handleFilterChange} filterState={tableSMFilterState}
          maxInstitutions={1} useDefaults={false}/>
      </div>
      <div className="spacer20"/>

      <div style={{height: '100%'}}>
        { loading ? ( <LoadingSpinner /> ) : ( 
          <>
            { institutionSelected && institutionSelected.name && yearSelected && (
              <Alert color='info'>
                <div className="f-size-08">              
                  <Translate contentKey="irexForsahApp.tables.scoringMatrix.dataSelected" 
                    interpolate={{institutionSelected: institutionSelected.name, yearSelected}} />
                </div>
              </Alert>
              )
            }            

            <MaterialReactTable table={table} /> 
          </>
          
        ) }
      </div>
    </div>
  );
};

export default ScoringMatrix;
