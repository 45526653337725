import axios from 'axios';
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {IBarChartsModel} from "app/shared/model/charts.model";
import {IFilter} from 'app/shared/model/forsah-filter.model';


const initialState: IBarChartsModel = {
  loading: false,
  errorMessage: null,
  barChartData: {
    xAxis: {
      data: []
    },
    series: []
  },
}
const barUrl = 'api/charts/barchart';

export const getBarData = createAsyncThunk(
  'charts/fetch_bar_data',
  async (filter: IFilter) => {
    return axios.post<IBarChartsModel>(barUrl, filter);
  }
);

export const barSlice = createSlice({
  extraReducers(builder) {
    builder
      .addCase(getBarData.fulfilled, (state, action) => {
        state.loading = false;
        state.barChartData = [];
        state.barChartData = action.payload.data;
      })
      .addCase(getBarData.pending, state => {
        state.loading = true;
      })
      .addCase(getBarData.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.error.message;
      });
  },
  initialState,
  name: 'barChart',
  reducers: {
    reset() {
      return initialState;
    },
  },
});

export default barSlice.reducer;
