import { IUser } from "../model/user.model";
import { getRoleDisplayName } from "./display-constants";

export interface ListboxItem {
    id: number | string;
    name: string;
}

export function transformToListboxItems(input: any[]): ListboxItem[] {
    const listboxItems: ListboxItem[] = [];
    
    for (const item of input) {
      if (item.id !== undefined && item.name !== undefined) {
        listboxItems.push({ id: item.id, name: item.name });
      }
    }
    
    return listboxItems;
}

export function transformUserListToListboxItems(input: IUser[]): ListboxItem[] {
    const listboxItems: ListboxItem[] = [];
    if (input !== null || input !== undefined) {
      for (const item of input) {
        if (item.id !== undefined) {
          listboxItems.push({ id: item.id, name: item.firstName + ' ' + item.lastName});
        }
      }
    }   
    
    return listboxItems;
}

export function transformAuthoritiesListToListboxItems(input: any[]): ListboxItem[] {
  const listboxItems: ListboxItem[] = [];
  
  for (const item of input) {
    if (item !== undefined) {
      listboxItems.push({ id: item, name: getRoleDisplayName(item) });
    }
  }
  
  return listboxItems;
}