import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, UncontrolledTooltip, FormGroup, Input, Label } from 'reactstrap';
import { Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { extractIdsAsObj, mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { getEntitiesToListBox as getInstitutions } from 'app/entities/institution/institution.reducer';
import { getEntity, updateEntity, getAllTypes } from './survey.reducer';
import DualListbox from 'app/shared/components/DualListbox';
import { ListboxItem, transformToListboxItems } from 'app/shared/util/ListboxItem';
import LoadingSpinner from 'app/shared/components/LoadingSpinner';
import FieldFormWithValidation from 'app/shared/components/FieldFormWithValidation';
import { getSurveyTypeDisplay } from 'app/shared/util/display-constants';

export const SurveyUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [entityInstitutions, setEntityInstitutions] = useState([]);
  const [type, setType] = useState('');
  const [activated, setActivated] = useState(false);

  const { id } = useParams<'id'>();

  const institutions = useAppSelector(state => state.institution.entities);
  const surveyEntity = useAppSelector(state => state.survey.entity);
  const surveyTypes = useAppSelector(state => state.survey.types);
  const loading = useAppSelector(state => state.survey.loading);
  const updating = useAppSelector(state => state.survey.updating);
  const updateSuccess = useAppSelector(state => state.survey.updateSuccess);
  const account = useAppSelector(state => state.authentication.account);

  const handleClose = () => {
    navigate('/usersHome/survey');
  };

  useEffect(() => {
    dispatch(getEntity(id));
    dispatch(getInstitutions(account.login));
    dispatch(getAllTypes());
  }, []);

  useEffect(() => {
    setEntityInstitutions(surveyEntity.institutions);
    setType(surveyEntity.type);
    setActivated(surveyEntity.activated);
  }, [surveyEntity]);


  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  // eslint-disable-next-line complexity
  const saveEntity = values => {
    if (values.id !== undefined && typeof values.id !== 'number') {
      values.id = Number(values.id);
    }

    const entity = {
      ...surveyEntity,
      ...values,
      type,
      activated,
      institutions: extractIdsAsObj(entityInstitutions),
    };

    dispatch(updateEntity(entity));
  };

  const defaultValues = () => ({
      ...surveyEntity,
      institutions: surveyEntity?.institutions?.map(e => e.id.toString()),
  });

  
  const handleInstitutionSelectionChange = (newSelectedItems: ListboxItem[]) => {
    setEntityInstitutions(newSelectedItems);
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="irexForsahApp.survey.home.createOrEditLabel" data-cy="SurveyCreateUpdateHeading">
            <Translate contentKey="irexForsahApp.survey.home.createOrEditLabel">Create or edit a Survey</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <LoadingSpinner />
          ) : (            
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>

              <Row className='mt-15'>
                <Col md="8">
                  <FieldFormWithValidation
                      label={translate('irexForsahApp.survey.name')}
                      id="survey-name"
                      name="name"
                      type="text"
                      value={surveyEntity.name}
                      labelWidth={2}
                      isReadOnly
                    />
                </Col>
                <Col md="4">
                  <FieldFormWithValidation
                      label={translate('irexForsahApp.survey.section')}
                      id="survey-sectionNumber"
                      name="sectionNumber"
                      type="text"
                      value={surveyEntity.sectionNumber}
                      labelWidth={4}
                      isReadOnly
                    />
                </Col>
              </Row>              

              <Row>
                <Col md="5">
                  <FieldFormWithValidation
                    label={translate('irexForsahApp.survey.idCode')}
                    id="survey-idCode"
                    name="idCode"
                    type="text"
                    value={surveyEntity.idCode}
                    labelWidth={3}
                    isReadOnly
                  />
                </Col>

                <Col>
                  <FieldFormWithValidation
                    label={translate('irexForsahApp.survey.publicLink')}
                    id="survey-publicLink"
                    name="publicLink"
                    type="text"
                    value={surveyEntity.publicLink}
                    labelWidth={3}
                    isReadOnly
                  />
                </Col>
              </Row>

              {/* <UncontrolledTooltip target="idCodeLabel">
                <Translate contentKey="irexForsahApp.survey.help.idCode" />
              </UncontrolledTooltip> */}

              

              <Row>
                <Col md="8">
                  <ValidatedField label={translate('irexForsahApp.survey.type')}
                    id="survey-type" name="type" data-cy="type" type="select"
                    value={type} onChange={(event) => setType(event.target.value)}>
                      {surveyTypes
                      ? surveyTypes.map(typeItem => (
                          <option value={typeItem} key={typeItem}>
                            {getSurveyTypeDisplay(typeItem)}
                          </option>
                        ))
                      : null}
                  </ValidatedField>
                </Col>

                <Col md="4">
                  <FormGroup switch style={{marginTop: 30}}>
                    <Input
                      type="switch"
                      checked={activated}
                      onClick={() => { setActivated(!activated); }}
                      style={{width: '4rem', height: '2rem'}}
                    />
                    <Label check style={{marginTop: 5, marginLeft: 10}}>{translate('irexForsahApp.survey.activated')}</Label>
                  </FormGroup>
                </Col>
              </Row>
              

              {
                entityInstitutions &&
                <DualListbox 
                  name="institutions" 
                  id="survey-institution"
                  label={translate('irexForsahApp.survey.institution')}
                  items={transformToListboxItems(institutions)}
                  selectedItems={transformToListboxItems(entityInstitutions)}
                  onSelectionChange={handleInstitutionSelectionChange}
                />
              }
              

              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/usersHome/survey" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default SurveyUpdate;
